.reduced-mort-fourth-section {
  & > .item-row-container {
    grid-column: span 2;

    @media (width < 1000px) {
      grid-column: span 1;
    }
  }

  & > .cta-button {
    height: fit-content;
    align-self: center;
    justify-self: center;
  }

  @container (width < 1000px) {
    & > .item-row-container {
      & > .item-row-list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px 20px;

        & > * {
          &:nth-child(1) {
            grid-area: 1;
          }

          &:nth-child(2) {
            grid-area: 3;
            grid-column: span 2;
          }

          &:nth-child(4) {
            grid-area: 2;
          }

          &:nth-child(5) {
            grid-area: 1;
          }
        }
      }
    }
  }
}
