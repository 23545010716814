@import "../../css/all";

.ButtonWindowChoose {
  background-color : $darkBlue;
  border           : calculateRem(2) solid $darkBlue;
  border-radius    : 5px;
  color            : white;
  cursor           : pointer;
  display          : flex;
  font-weight      : bold;
  height           : calculateRem(70);
  justify-content  : center;
  margin-top       : calculateRem(20);
  text-align       : center;
  width            : 620px;

  @include respond-to("handhelds") {
	font-weight : normal;
	width       : 85% !important;
	}

  &--open {
	height : calculateRem(280);
	@include respond-to("handhelds") {
	  height : calculateRem(260);
	  }
	}

  .subOption {
	align-items      : center;
	background-color : white;
	border           : calculateRem(2) solid white;
	border-radius    : 5px;
	color            : $darkBlue;
	cursor           : pointer;
	display          : flex;
	font-weight      : bold;
	height           : calculateRem(50);
	justify-content  : center;
	margin-top       : calculateRem(13);
	text-align       : center;
	width            : 520px;

	@include respond-to("handhelds") {
	  margin-top : calculateRem(8);
	  width      : calculateRem(250);
	  }
	}

  .smallText {
	font-weight : normal;
	margin-top  : calculateRem(-7);
	}

  .singleTextContent {
	align-items     : center;
	display         : flex;
	justify-content : center;
	}

  .textContent {
	margin-top : calculateRem(-25);
	}

  .closeButton {
	height   : 40px;
	left     : -480px;
	position : relative;
	top      : 10px;
	width    : 40px;
	@include respond-to("handhelds") {
	  left : -220px;
	  }
	}
  }
