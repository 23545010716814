@import "../../../css/all";

.MortQNA {
  align-items    : flex-start;
  display        : flex;
  flex-direction : column;
  gap            : 40px;
  min-height     : 140vh;
  padding-block  : 80px;
  padding-inline : 8vw;
  @include respond-to("handhelds") {
	padding-block  : 40px;
	padding-inline : 6vw;
	}

  & > .title-container {
	align-items    : flex-start;
	color          : #004680;
	display        : flex;
	flex-direction : column;
	@include respond-to("handhelds") {
	  align-items : center;
	  width       : 100%;
	  }

	& > h1 {
	  font-size   : clamp(30px, 2.9vw, 55px);
	  font-weight : 800;
	  }

	& > h2 {
	  font-size   : clamp(20px, 1.9vw, 35px);
	  font-weight : 600;
	  }
	}

  & > .questions-container {
	display : flex;
	gap     : min(90px, 4.7vw);
	@include respond-to("handhelds") {
	  flex-direction : column;
	  gap            : 0;
	  }

	& > .image-container {
	  flex-basis : 40%;
	  @include respond-to("handhelds") {
		flex-basis          : auto;
		padding-block-start : 40px;
		}

	  & > img {
		width : 100%;
		}
	  }

	& > .questions-list-container {
	  display        : flex;
	  flex-direction : column;
	  gap            : 0 90px;

	  & > .SingleQuestion {
		border-bottom  : 2px solid #90B0D0;
		color          : #464646;
		display        : flex;
		flex-direction : column;
		max-width      : 420px;

		&:hover {
		  cursor : pointer;
		  }

		& > .question-container {
		  & > .title-container {
			align-items     : center;
			display         : flex;
			gap             : 10px;
			justify-content : space-between;
			padding-block   : 10px;

			& > h3 {
			  font-size   : clamp(18px, 1.3vw, 24px);
			  font-weight : 600;
			  }
			}

		  & > .content-container {
			font-size   : clamp(18px, 1.2vw, 23px);
			max-height  : 0;
			opacity     : 0;
			overflow    : hidden;
			transition  : max-height 0.2s ease,
			padding-block 0.2s ease;
			white-space : pre-wrap;
            display: flex;
			flex-direction: column;
			gap: 10px;
			&.selected {
			  max-height        : 800px;
			  opacity           : 1;
			  padding-block-end : 12px;
			  transition        : max-height 0.4s ease, opacity 0.3s ease;
			  cursor: revert;
			  }
			  & > .complex-paragraph {
				display: flex;
				flex-direction: column;
				& > h4 {
					font-size   : clamp(16px, 1.2vw, 21px);
					font-weight : 600;
                	color       : #004680;
					&:has( + ul) {
						color     : black;
						padding-bottom: 4px;
					}
					}
				& > p {
					font-size   : clamp(18px, 1.2vw, 23px);
        			white-space : pre-wrap;
					
				}
				&:has(h4) {
					& > p {
						font-size : clamp(16px, 1.1vw, 20px);
					}
				 }
				& > ul {
					font-size      : clamp(16px, 1.1vw, 20px);
					padding        : 0;
					margin         : 0;
					list-style     : inside;
					display        : flex;
					flex-direction : column;
					gap            : 4px;
				}
				& > .disclaimer {
					font-size : clamp(16px, 1.1vw, 20px);
					font-weight: 600;
				}
			  }
			}
		  }
		}
	  }
	}
  }
