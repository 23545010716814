@keyframes slideFromRight {
  0% {
	transform : translateX(100%);
	}
  100% {
	transform : translateX(0);
	}
  }

@keyframes slideFromLeft {
  0% {
	transform : translateX(-100%);
	}
  100% {
	transform : translateX(0);
	}
  }

@keyframes SlideFromTop {
  0% {
	transform : translateY(-100%);
	}
  100% {
	transform : translateY(0);
	}
  }


