.premium-mort-second-section {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 140px;

    @media (width < 1000px) {
        grid-template-columns: 1fr;
        gap: 40px 0;
    }

    &>h3 {
        grid-column: span 3;

        @media (width < 1000px) {
            grid-column: span 1;
        }
    }

    &>.item-list-container {
        &>.item-list-list-container {
            gap: 60px;
        }
    }

    &>.airplane-animation-and-cta {
        position: relative;
        grid-column: span 3;
        margin-block-start: 120px;

        @media (width < 1000px) {
            grid-column: span 1;
            margin-block-start: 0;
        }

        &>.animation-container {
            z-index: 1;
            pointer-events: none;
            position: absolute;
            top: 0;

        }

        &>.cta-container {
            &>.animate-appearance {
                opacity: 0;
                animation: changeOpacity 0.5s;
                animation-delay: 1s;
                animation-fill-mode: forwards;
            }

        }
    }

}