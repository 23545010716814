.intro-container {
  --title-font-size: 36px;
  --paragraph-font-size: 18px;
  width: 900px;
  border: none;
  border-radius: 10px;
  padding-block: 28px;
  padding-inline: 20px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  background-color: var(--white);

  @media (width < 1000px) {
    gap: unset;
  }

  h2 {
    font-size: 30px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 5px;

    @media (width < 1000px) {
      font-size: 20px;
    }
  }

  & > .search-button {
    margin-block-start: 20px;
    width: 35%;

    @media (width < 1000px) {
      width: 100%;
    }
  }

  & > .intro-parts-container {
    width: 100%;

    & > .intro-part {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn 0.5s ease-in-out;

      p {
        font-size: 16px;
        white-space: pre-wrap;
        text-align: center;

        @media (width < 1000px) {
          font-size: 14px;
        }
      }

      & > .animation-container {
        display: flex;
        justify-content: center;
        width: 50%;
      }
    }
  }

  @media (width < 1000px) {
    width: 100%;
    height: min(580px, 70dvh);
    padding-block: 20px;
    padding-inline: 20px;
    --title-font-size: 30px;
    --paragraph-font-size: 16px;
    h2 {
      text-wrap: balance;
      text-align: center;
    }
    & > .intro-parts-container {
      & > .intro-part {
        display: flex;

        p {
          white-space: normal;
          text-align: center;
        }
        & > .animation-container {
          &:not(.second-part-animation, .third-part-animation) {
            display: flex;
            justify-content: center;
            & * {
              width: 30vw;
              max-width: 200px;
              max-height: 15vh;
            }
          }
        }
      }
    }
  }
}
