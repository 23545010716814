@import "../../../../../../../css/all.scss";

.FunctionalSection {
  align-items    : center;
  display        : flex;
  flex-direction : column;
  gap            : 30px;
  width          : 100%;
  @include respond-to("medium-handhelds") {
	animation          : slideFromLeftToCenter;
	animation-duration : 1s;
	flex-direction     : column-reverse;
	gap                : 16px;
	}

  & > .VideoIframeButton {
	align-items : center;
	display     : flex;
	gap         : 12px;

	&:hover {
	  cursor : pointer;

	  & > p {
		animation : slide 2s infinite;
		}

	  & > .play-button-container {
		box-shadow : 0 0 10px 1px #9696967C;
		}
	  }

	& > p {
	  color           : #ED1C24;
	  font-size       : 19px;
	  font-weight     : 600;
	  text-decoration : underline;
	  @include respond-to("medium-handhelds") {
		font-size : 15px;
		}
	  }

	& > .play-button-container {
	  align-items     : center;
	  border          : 4px solid #ED1C24;
	  border-radius   : 50%;
	  box-shadow      : 0 0 6px #00000029;
	  display         : flex;
	  height          : 36px;
	  justify-content : center;
	  transition      : box-shadow 0.2s ease-in-out;
	  width           : 36px;

	  & > .image-container {
		display   : flex;
		transform : translateX(10%);

		& > img {
		  height : 13px;
		  width  : 12px;
		  }
		}

	  @include respond-to("medium-handhelds") {
		border : 1px solid #ED1C24;
		height : 12px;
		width  : 12px;
		& > .image-container {
		  align-items : center;
		  display     : flex;

		  & > img {
			height : auto;
			width  : auto;
			}
		  }
		}
	  }
	}

  & > .bottom-content-container {
	align-items    : center;
	display        : flex;
	flex-direction : column;
	gap            : 12px;

	& > object {
	  width : min(340, 80%);
	  width : 80%;
	  @media screen and (max-width : 480px) {
		max-height : 45px;
		}
	  }

	& > .ButtonsSection {
	  display               : grid;
	  gap                   : 16px;
	  grid-template-columns : repeat(3, 1fr);

	  & > a {
		border-radius   : 8px;
		color           : #FFFFFF;
		display         : flex;
		font-size       : clamp(15px, 1.2vw, 22px);
		justify-content : center;
		max-width       : 200px;
		padding-block   : 16px;
		padding-inline  : min(28, 1.35vw);
		transition      : background-color 0.1s ease-in-out;
		@include respond-to("medium-handhelds") {
		  padding-inline : 14px;
		  }

		&:nth-child(1) {
		  background-color : #0098FF;

		  &:hover {
			background-color : #007FD4;
			}
		  }

		&:nth-child(2) {
		  background-color : #F9554E;

		  &:hover {
			background-color : #CF443F;
			}
		  }

		&:nth-child(3) {
		  background-color : #00509E;

		  &:hover {
			background-color : #0F4983;
			}
		  }
		}
	  }
	}
  }

@keyframes slide {
  0% {
	transform : translateX(0);
	}
  30% {
	transform : translateX(-2px);
	}
  50% {
	transform : translateX(0);
	}
  80% {
	transform : translateX(-2px);
	}
  100% {
	transform : translateX(0);
	}
  }

@keyframes slideFromLeftToCenter {
  0% {
	transform : translateX(-100%);
	}
  100% {
	transform : translateX(0);
	}
  }
