.third-part-container {
  justify-content: space-between;
  padding-block-start: 30px;
  & > .third-part-text {
    & > span {
      font-weight: 700;
    }
  }
  & > .third-part-animation {
    & > * {
      width: 40%;
      @media (width < 1000px) {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}
