.drawer-footer-container {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c6c6c;
  flex: 1;
  & > * {
    & > p {
      font-size: 16px;
    }

    & > .links-container {
      display: flex;
      justify-content: space-between;
      & > * {
        color: #6c6c6c;
        font-size: 16px;
      }
    }
  }

  @media (width < 1000px) {
    & > * {
      & > p {
        font-size: 14px;
      }

      & > .links-container {
        display: flex;
        justify-content: space-between;
        & > * {
          color: #6c6c6c;
          font-size: 14px;
        }
      }
    }
  }
}
