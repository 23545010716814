.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    & > * {
      max-width: 200px;
      @media (width < 1000px) {
        max-width: calc(100% - 80px);
      }
    }
  }
}
