.item-list-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &>h3 {
        padding-bottom: 12px;
    }

    &>.item-list-list-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @media (width < 1000px) {
            gap: 50px;
            height: auto;
        }
    }
}