@import "../../../../../css/all";

.MainSection {
  align-items       : center;
  display           : flex;
  gap               : 60px;
  padding-block-end : 10vh;
  padding-inline    : 8vw;

  & > * {
	flex : 1;
	}

  &.mort-page {
	align-items : flex-start;
	@include respond-to("handhelds") {
	  align-items : center;

	  }
	}

  @media screen and (max-width : 900px) {
	flex-direction : column;

	}
  @include respond-to("handhelds") {
	gap            : 0;
	padding-inline : 4vw;
	text-align     : center;
	}

  & > .right-side {
	display        : flex;
	flex-direction : column;
	gap            : 46px;

	&.in-view {
	  animation : slideFromRight 1s;
	  }

	@include respond-to("handhelds") {
	  gap : 12px;
	  }

	& > .title-container {
	  display        : flex;
	  flex-direction : column;
	  gap            : 22px;
	  @include respond-to("handhelds") {
		gap : 8px;
		}

	  & > h1 {
		color       : #004680;
		font-size   : clamp(35px, 3.3vw, 64px);
		font-weight : 700;
		line-height : 100%;

		& > span {
		  align-items : flex-end;
		  display     : flex;
		  min-width   : max-content;

		  & > img {
			width : min(300px, 15vw);
			@include respond-to("handhelds") {
			  width : 160px;
			  }
			}

		  @include respond-to("handhelds") {
			justify-content : center;
			}
		  }
		}

	  & > h2 {
		color     : #284780;
		font-size : clamp(20px, 2.1vw, 43px);
		@include respond-to("handhelds") {
		  font-weight : 600;
		  }
		}
	  }

	& > .content-container {
	  display        : flex;
	  flex-direction : column;
	  gap            : 18px;

	  & > p {
		color     : #284780;
		font-size : clamp(18px, 1.3vw, 26px);

		&:last-child {
		  display   : flex;
		  min-width : max-content;
		  @include respond-to("handhelds") {
			justify-content : center;
			}

		  & > img {
			width : clamp(86px, 6.5vw, 140px);
			}
		  }
		}
	  }

	& > .buttons-container {
	  display            : flex;
	  gap                : 28px;
	  margin-block-start : 60px;

	  & > * {
		font-size      : clamp(16px, 1.1vw, 22px);
		font-weight    : 400;
		padding-block  : 28px;
		padding-inline : 33px;
		}

	  @include respond-to("handhelds") {
		flex-direction     : column;
		gap                : 12px;
		margin-block-start : 0;
		padding-inline     : 2vw;
		}
	  }
	}

  & > .left-side {
	&.in-view {
	  animation : slideFromLeft 1s;
	  }

	& > img {
	  width : 100%;
	  }
	}
  }
