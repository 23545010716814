@import "../../../../config/config.scss";

.MortgageLandingPageContainer {
    white-space: pre-wrap;
    padding-inline: 8vw;
    padding-block-start: 134px;
    color: #3D5A80;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;
    position: relative;

    &>* {
        &:not(.MortgageContactFormContainer) {
            width: 100%;
        }
    }

    @media screen and (max-width: 1400px) {
        padding-block-start: 20px;
        padding-inline: 18px;
        gap: 20px;

        &>* {
            width: 100%;
        }
    }
}