@import "../../../variables.scss";

.input-container-mort-form {
    position: relative;

    

    &:has(input[type="checkbox"]) {
        grid-column: span 2;
    }

    &>.input-and-label {
        display: flex;
        flex-direction: column;

        background-color: $secondary-color;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: $header-weight-h2;
        
        &:hover, &:focus-within {
            cursor: text;
        }
        
        &>label {
            color: $mortgage-color;
            pointer-events: none;

        }

        &>input {
            border: none;
            font-size: $paragraph-font;
            color: $base-color;
        }
    }

    &>.error-message {
        color: red;
        position: absolute;
        left: 10px;
        font-weight: $header-weight-h2;
        padding-inline-start: 20px;

    }
}