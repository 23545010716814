$break-small   : calculateRem(480);
$break-medium  : calculateRem(1080);
$black-squeeze : #F4F8FB;
$screen-phone  : calculateRem(480);

@function calculateRem($size) {
  @return $size / 16 + rem;
  }

html {
  font-size : unset !important;
  }

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance : none;
  margin             : 0;
  }

@mixin placeholder($color) {
  ::-webkit-input-placeholder {
	color : $color !important;
	}
  ::-moz-placeholder {
	color : $color !important;
	}
  :-ms-input-placeholder {
	color : $color !important;
	}
  :-moz-placeholder {
	color : $color !important;
	}
  }

@mixin respond-to($media) {
  @if $media == "iphone5" {
	@media all and (max-width : 320px) {
	  @content;
	  }
	}
  @if $media == "handhelds" {
	@media all and (max-width : $break-small) {
	  @content;
	  }
	} @else if $media == "medium-handhelds" {
	@media all and (max-width : 1000px) {
	  @content;
	  }
	} @else if $media == "medium-screens" {
	@media all and (min-width : $break-small) and (max-width : 1580px) {
	  @content;
	  }
	} @else if $media == "wide-screens" {
	@media all and (min-width : $break-medium) {
	  @content;
	  }
	}
  }

@mixin customScroll($bgColor, $thumbColor) {
  &::-webkit-scrollbar {
	width  : calculateRem(8);
	height : calculateRem(8);
	}

  &::-webkit-scrollbar-track {
	background : $bgColor;
	}

  &::-webkit-scrollbar-thumb {
	background : $thumbColor;
	}

  &::-webkit-scrollbar-thumb:hover {
	background : darken($thumbColor, 3%);
	}
  }

.desktopOnly {
  display : inherit !important;
  @include respond-to("handhelds") {
	display : none !important;
	}
  }

.mobileOnly {
  display : none !important;
  @include respond-to("handhelds") {
	display : inherit !important;
	}
  }

.smallScreenOnly {
  display : none !important;
  @include respond-to("medium-handhelds") {
	display : inherit !important;
	}
  }

.mediumScreenOnly {
  display : none !important;
  @include respond-to("wide-screens") {
	display : inherit !important;
	}
  }

.largeScreenOnly {
  display : inherit !important;
  @include respond-to("medium-handhelds") {
	display : none !important;
	}
  }

.highZ {
  z-index : 999;
  }

.logoLine {
  display         : flex;
  flex-flow       : row;
  height          : 20px;
  justify-content : center;
  width           : 100%;

  img {
	margin-right : calculateRem(5);
	width        : calculateRem(85);
	}
  }
