.property-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & > h4 {
    color: #1d3557;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    direction: rtl;
    align-self: center;

    @media (width < 1000px) {
      font-size: 16px;
    }
  }

  & > .property-list-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;

    & > .single-property {
      background-color: #d9d9d9;
      font-size: 14px;
      font-weight: 600;
      padding-inline: 12px;
      padding-block: 6px;
      border-radius: 10px;
      color: var(--white);
      transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        background-color: var(--light-blue);
        color: var(--white);
      }
    }
  }
}
