@import "../variables.scss";

h1 {
    font-size: $header-font-h1;
    font-weight: $header-weight-h1;
    min-width: max-content;
    @media (width < 1000px) {
        min-width: 0;
    }
}

.heading-container {
    display: flex;
    align-items: flex-end;
    gap: min(10px, 2cqi);
    padding-bottom: 20px;

    & > .icon-container {
        width: fit-content;

        & > * {
           width: max(6.1cqi, 40px);

        }
    }
    @media (width < 500px) {
        align-items: center;
    }
}