.complete-mort-third-section {
    gap: 50px;

    &>* {
        grid-column: span 2;
    }

    &>.item-list-container {
        &>.item-list-list-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 40px min(200px, 12cqi);

            @media (width < 1200px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (width < 1000px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &>.animation-cta-container {
        grid-column: span 2;
        display: flex;
        justify-content: center;
        padding-block-start: 60px;

    
        &>.cta-animation-click-container {
            width: 1200px;

            &>.animation-container {
                right: min(410px, 30vw);

                @media (width < 1000px) {
                    right: -20px;
                }
                
            }

            &>.cta-container {
                width: 100%;
                align-items: center;
                &>* {
                    min-width: max-content;

                }

            }
        }
    }


}