.resend-code {
  border: none;
  font-size: 20px;
  background-color: transparent;
  text-decoration: underline;
  color: #1799fc;
  &:focus {
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }

  &:disabled {
    color: grey;
    opacity: 0.5;
  }
}
