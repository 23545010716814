.policy-modal[open] {
  --font-size-xl: clamp(20px, 2.7vw, 50px);
  --font-size-md: clamp(16px, 1.1vw, 20px);
  --gap-md: clamp(10px, 1.1vw, 20px);

  border: none;
  border-radius: 20px;
  background-color: #f2f2f2;
  width: 60%;
  max-width: 730px;
  height: 60%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: clamp(20px, 5vw, 100px);
  padding-block: clamp(10px, 2vw, 40px);
  gap: var(--gap-md);

  & > h2 {
    color: #1d3557;
    font-size: var(--font-size-xl);
    font-weight: 700;
  }

  & > h3 {
    color: #1799fc;
    font-size: var(--font-size-md);
    font-weight: 500;
  }

  @media (width < 1000px) {
    width: calc(100% - 40px);
  }

  & > * {
    &:not(.close-button, h2, h3) {
      border: none;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.policy-modal[open]::backdrop {
  background-color: #000000;
  opacity: 0.5;
  backdrop-filter: blur(10px);
}
