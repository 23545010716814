.rent-ai-card-container {
  --button-hover: #0389ef;
  grid-column: span 3;
  background: var(--card-background);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: 44px;
  position: relative;
 
  & > * {
    flex: 1;
  }

  & > .rent-ai-card-content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
    padding-block-start: 20px;
    padding-block-end: 30px;
   
    & > .textual-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (width < 1000px) {
        align-items: center;
        text-align: center;
      }
      & > .rent-ai-card-title-container {
        & > h3 {
          font-size: var(--font-size-xl);
          font-weight: 600;
        }
      }
      & > .rent-ai-card-secondary-title-container {
        color: var(--font-secondary-color);
        & > h4 {
          font-size: var(--font-size-lg);
          font-weight: 600;
        }
      }
      & > .rent-ai-card-paragraph-container {
        & > p {
          font-size: var(--font-size-md);
          white-space: pre-wrap;
          & > span {
            font-weight: 600;
          }
        }
      }
    }

    & > .rent-ai-card-buttons {
      display: flex;
      align-items: center;
      gap: 30px;
      @media (width < 1000px) {
        flex-direction: column;
        gap: 10px;
      }
      
      & > .rent-ai-card-video-cta {
        display: flex;
        justify-content: center;
        gap: 10px;
        background-color: transparent;
        border: none;
        &:hover {
          cursor: pointer;
        }
        & > * {
          color: var(--font-secondary-color);
          font-size: 20px;
          font-weight: 600;
        }
        @media (width < 1000px) {
          position: absolute;
          bottom: 20px;
          right: 10px;
          width: calc(100% - 20px);
        }
      }
      & > .rent-ai-card-button-cta {
        display: flex;
        justify-content: center;
        color: #fff;
        background-color: #1799fc;
        font-size: var(--font-size-lg);
        font-weight: 600;
        width: fit-content;
        padding-inline: 60px;
        padding-block: 10px;
        border-radius: 10px;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: var(--button-hover);
        }
        @media (width < 1000px) {
          position: absolute;
          bottom: 70px;
          right: 10px;
          width: calc(100% - 20px);
        }
      }
    }
  }

  & > .rent-ai-card-spread {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    width: 50%;
    & > * {
      width: 100%;
      max-width: 500px;
    }
    @media (width < 1000px) {
      width: 100%;
    }
  }
  @media (width < 1000px) {
    padding-inline-start: 0;
    padding-block-end: 80px;
    display: flex;
    flex-direction: column;
  }
}
