@import "../../../../../css/all";

.WeAreForYouSection {
  background : linear-gradient(to bottom, #004680, #002340);
  color      : white;
  margin-top : 50px;
  width      : 100%;

  .container {
	display       : flex;
	padding-right : 125px;

	@include respond-to("medium-handhelds") {
	  display   : flex;
	  flex-flow : column;
	  padding-right : 0;
	  }

	.textContent {
	  padding : 105px 0;
	  max-width: 900px;

	  @include respond-to("medium-handhelds") {
		width         : 100%;
		padding-top   : 30px;
		padding-left  : 28px;
		padding-right : 28px;
		}

	  h2 {
		font-size   : calculateRem(40);
		font-weight : bold;
		@include respond-to("medium-handhelds") {
		  font-size : calculateRem(25);
		  }
		}

	  p {
		font-size : calculateRem(20);
		@include respond-to("medium-handhelds") {
		  font-size : calculateRem(16);
		  }
		}
	  }

	.logos {
	  display         : flex;
	  justify-content : center;
	  flex            : 1 1 100%;

	  @include respond-to("medium-handhelds") {
		justify-content : space-around;
		margin-bottom   : 50px;
		}

	  img {
		width : 308px;
		@include respond-to("medium-handhelds") {
		  width : 244px;
		  }
		}
	  }
	}

  }