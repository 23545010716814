.aia-second-section {
    align-items: center;

    & > .airplane-animation-and-cta {
        position: relative;
        grid-column: span 2;
        @media (width < 1000px) {
            grid-column: span 1;
        }
        & > .animation-container {
            z-index: 1;
            pointer-events: none;
            position: absolute;
            top: 0;
        }

        & > .cta-container {
            & > .animate-appearance {
                opacity: 0;
                animation: changeOpacity 0.5s;
                animation-delay: 1s;
                animation-fill-mode: forwards; 
            }
        }
    }

    @media (width < 1000px) {
        & > .animation-and-text {
            grid-area: 1;
        }
    }
}