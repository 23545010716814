.additional-links-list {
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  transform-origin: top;

  & > a {
    font-size: 16px;
    text-decoration: none;
    color: #1d3557;
    font-weight: 400 !important;
  }

  &.is-open {
    max-height: 500px;
    gap: 10px;
    padding-top: 10px;
    opacity: 1;
    transition: max-height 0.2s ease, opacity 0.2s ease, padding-block 0.2s ease;
  }
}
