.modal-container {
  --background: #f2f2f2;
  --background-secondary: #2999fc;

  --box-shadow: 0 5px 11px #0000002e;
  --padding-block: 30px;
  --padding-inline: min(50px, 7vw);

  --color: #1d3557;
  --color-secondary: #2999fc;

  --font-size-md: ;
}

.modal-container[open] {
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 10px;
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  background-color: var(--background);
  color: var(--color);
  text-align: center;
  direction: rtl;
  animation: modal-appear 0.3s ease-in-out;

  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (width < 600px) {
    width: 100%;
  }
}

.modal-container::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: none;
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
