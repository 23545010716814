@import "../../../../css/all.scss";

.icon-container {
  align-self: center;
  width: 20px;
  height: 20px;
}

.HeaderButton {
  align-items: center;
  color: white;
  display: flex;
  gap: 7.5px;
  text-decoration: none;
  transition: 0.2s ease;
  background: linear-gradient(180deg, #1799fc 0%, #0d80d8 100%);
  border-radius: 10px;
  justify-content: center;
  padding-inline: 10px;
  padding-block: 7px;

  & > .image-container {
    & > img {
      aspect-ratio: 1/1;
      max-width: 50px;
      width: 100%;
    }
  }

  & > p {
    font-size: clamp(16px, 1.1vw, 20px);
  }

  &:hover {
    cursor: pointer;
    text-shadow: 0 0 1px gray;
  }
}
