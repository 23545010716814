@import "../../../../../../../css/all";

.Slide {
  display          : flex;
  flex-flow        : column;
  background-color : white;
  width            : 620px;
  margin-top       : 20px;
  text-align       : center;
  border-radius    : 20px;
  padding          : 20px;
  height           : 480px;

  &--withShadow {
	box-shadow    : 0px 3px 15px #00000029;
	border-radius : 20px;
	}

  @include respond-to("medium-handhelds") {
	width  : 358px;
	margin : 20px auto 50px;
	height : 400px;
	}

  .dataInput {
	height : 300px;
	@include respond-to("medium-handhelds") {
	  margin-top : 0;
	  height     : 200px;
	  }
	}

  h3 {
	font-size   : calculateRem(26);
	font-weight : bold;
	@include respond-to("medium-handhelds") {
	  font-size : calculateRem(18);
	  }
	}

  h4 {
	font-size   : calculateRem(20);
	font-weight : 600;
	margin-top  : 10px;
	@include respond-to("medium-handhelds") {
	  font-size  : calculateRem(16);
	  margin-top : 0;
	  }

	}

  }