.first-section-container {
    &>.product-pages-image-container {
        justify-content: center;

        &>img {
            max-width: 490px;
        }
    }
    &>.right-side-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        transform: translateX(100%);

        &.in-view {
            animation: slideFromRight 1s;
            animation-fill-mode: forwards;
        }

        &>.animation-and-cta {
            display: flex;
            align-items: center;
            position: relative;

            &>.hide-animation {
                position: relative;
                right: 20px;
                height: 60px;
                width: 120px;
                background-color: #F2F2F2;
                overflow: hidden;

                &>.animation-container {
                    position: absolute;
                    top: -102px;
                    right: -100px;

                    &>* {
                        width: 250px;
                    }
                }
            }
        }
    }

    @media (width < 1000px) {
        gap: 50px;

        &>* {
            width: 100%;

        }
    }
}
