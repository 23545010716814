@import "../../../../../../config/config.scss";

.middle-section-container {
    display: flex;
    align-items: center;
    gap: 170px;

    &>* {
        flex: 1;
    }

    @media screen and (max-width: 1400px) {
        flex-direction: column;
        gap: 20px;
    }

    &>.right-part-container {
        display: flex;
        flex-direction: column;
        gap: 100px;
        @media screen and (max-width: 1400px) {
            gap: 20px;
        }
        &>.middle-section-paragraph {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &>.title-container {
                display: flex;
                align-items: center;
                gap: 6px;
                border-radius: 16px;
                box-shadow: 0 3px 6px #00000040;
                width: 174px;
                padding-inline-start: 8px;
                padding-block: 4px;
                @media screen and (max-width: 1400px) {
                    width: fit-content;
                    padding-inline: 8px;
                }
                &>h3 {
                    font-size: clamp(17px, 1vw, 20px);
                    font-weight: 700;
                    min-width: max-content;
                }

            }

            &>p {
                font-size: clamp(17px, 1vw, 20px);

            }
        }
    }

    &>.left-part-container {
        background: url("#{$s3ImagesLink}/marketing/mort/landing-page/middle-part-image.png");
        background-position: center;
        background-size: 764px;
        background-repeat: no-repeat;
        min-width: 764px;
        height: 421px;
        position: relative;
        @media screen and (max-width: 1400px) {
            min-width: 0;
            height: auto;
            background: none;
            display: grid;
            grid-template-columns: 1fr;
            gap: 16px;
            & > img {
                width: 100%;
                grid-area: 2;
            }
        }
        &>.middle-section-card {
            width: 380px;
            position: absolute;
            right: 0;
            bottom: 0;
            padding-inline-start: 16px;
            padding-inline-end: 56px;
            padding-block-start: 8px;
            padding-block-end: 22px;
            box-shadow: 0 3px 20px #00000029;
            border-radius: 9px;

            &:first-child {
                right: auto;
                bottom: auto;
                left: 0;
                top: 0;
            }
            @media screen and (max-width: 1400px) {
                position: relative;
                padding-inline: 16px;
                width: auto;
            }
            &>.top-part-container {
                border-bottom: 2px solid #3D5A80;

                &>h3 {
                    font-size: 38px;
                    font-weight: 700;

                    &>span {
                        font-weight: 500;
                    }
                }

                &>.secondary-title-container {
                    padding-block-end: 6px;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    &>h4 {
                        font-size: 16px;
                    }
                }
            }

            &>.bottom-part-container {
                padding-block-start: 4px;

                &>p {
                    color: #0098FF;
                    min-width: max-content;
                    font-weight: 700;

                    &>span {
                        font-weight: 500;
                    }

                    &:first-child {
                        color: #3D5A80;
                        font-weight: 500;
                    }

                    font-size: 16px;
                }
            }

        }
    }
}