@import "../../../css/all";

.MortInfoNew {
  display             : flex;
  flex-direction      : column;
  padding-block-start : 134px;

  & > * {
	scroll-margin-block-start : 80px;
	}

  @include respond-to("handhelds") {
	padding-block-start : 30px;
	}
  }
