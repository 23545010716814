.closed-display-container {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding-block: 12px;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }

    &>.text-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        font-size: var(--font-size-md);
        color: var(--light-blue-text);

        &>span {
            font-weight: 700;
        }
    }

    &>.icon-container {
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;

    }

}