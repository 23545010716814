@import "../../../css/all";

.Contact {
  background      : url("#{$s3ImagesLink}/marketing/contact-man.svg") no-repeat right 88%,
  url("#{$s3ImagesLink}/marketing/contact-floor.svg") repeat-x bottom #FFFFFF;
  display         : flex;
  height          : 100%;
  justify-content : center;
  padding-bottom  : calculateRem(300);
  text-align      : center;
  width           : 100%;
  @include respond-to("medium-handhelds") {
	background      : url("#{$s3ImagesLink}/marketing/contact-man.svg") no-repeat center 88%,
	url("#{$s3ImagesLink}/marketing/contact-floor.svg") repeat-x bottom #FFFFFF;
	background-size : 500px;
	}


  * {
	font-size : calculateRem(20);
	}

  p {
	font-weight : bold;
	}

  &__section {
	height : 100%;
	width  : 100%;

	a {
	  color           : $peacock-blue !important;
	  text-decoration : underline;
	  }
	}

  .contactBox {
	display    : flex;

	margin-top : 20px;

	a {
	  margin : 0;
	  }

	.contactDetails {
	  display   : flex;
	  flex-flow : column;
	  }
	}

	.publicInquiries {
		margin-top: 20px;
		padding: 10px;
		background-color: #F2F2F2;
		border-radius: 10px;
		box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.10);
		text-align: right;
		width: fit-content;
		position: absolute;

		@include respond-to("handhelds") {
			position : unset;
			margin-inline: 10px;
		}  

		@include respond-to("medium-handhelds") {
			position : unset;
			margin-bottom: 20px;
		}

		.publicInquiriesTitle {
			font-size: 20px;
			font-weight: bold;
			color: black;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			margin-bottom: 5px;
		}

		p {
			color: black;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}

  &__form {
	margin : calculateRem(20) auto;
	width  : 40vw;
	@include respond-to("medium-handhelds") {
	  width : 95vw;
	  }
	@include placeholder($darkBlue);

	&__address {
	  display         : flex;
	  justify-content : right;
	  margin-top      : calculateRem(20);
	  text-decoration : none !important;

	  img {
		@include respond-to("handhelds") {
		  margin-top : calculateRem(-15);
		  }
		}

	  p {
		padding-right : calculateRem(5);
		text-align    : right;
		}
	  }

	input,
	textarea {
	  border        : solid calculateRem(1) #DBDBDB;
	  border-radius : calculateRem(5);
	  color         : $darkBlue;
	  display       : block;
	  margin        : calculateRem(4) auto;
	  outline       : none;
	  padding       : calculateRem(12) 0;
	  resize        : none;
	  text-align    : center;
	  transition    : 0.2s;
	  width         : 100%;

	  &:focus {
		border-color : #FF9FA3;
		box-shadow   : inset 0 0 0 calculateRem(2) #FF9FA3;
		font-weight  : 600;
		}
	  }

	button {
	  background-color : $pinkish-red;
	  border           : 0;
	  border-radius    : calculateRem(5);
	  color            : #FFFFFF;
	  cursor           : pointer;
	  font-weight      : 600;
	  margin-top       : calculateRem(8);
	  outline          : none;
	  padding          : calculateRem(12) 0;
	  transition       : 0.2s;
	  width            : 100%;

	  &:disabled {
		cursor  : default;
		opacity : 0.5 !important;
		}

	  &:focus {
		box-shadow : 0 calculateRem(5) calculateRem(5) 0 rgba(0, 0, 0, 0.5);
		}

	  &:hover {
		background-color : darken($pinkish-red, 5%);
		}
	  }
	}

  &__success {
	align-items     : center;
	display         : flex;
	flex-direction  : column;
	justify-content : center;
	width           : 80%;
	@include respond-to("handhelds") {
	  align-items     : center;
	  display         : flex;
	  height          : calc(100vh - 200px);
	  justify-content : center;
	  }

	& > * {
	  margin : calculateRem(8) 0;
	  }
	}
  }
