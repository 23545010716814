.loans-info-container {
  background-color: #f2f2f2;
  box-shadow: 0 -4px 10px #0000001a;
  border-radius: 20px 20px 0 0;
  padding-block-start: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  direction: ltr;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2; /* Set the color of the thumb */
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  @media (width < 1000px) {
    padding-inline: 20px;
    box-shadow: none;

  }

  & > .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    direction: rtl;

    & > img {
      position: absolute;
      pointer-events: none;
      left: -20px;
      bottom: 0;

      @media (width < 1000px) {
        display: none;
      }
    }
    & > .loans-and-new-loan-container {
      display: flex;
      gap: 50px;
      position: relative;

      & > .loans-container {
        display: grid;
        gap: 50px;
        min-height: 100%;

        @media (width < 1000px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
        }
      }
    }

    & > .disclaimer {
      font-size: var(--font-size-sm4);
      padding-block: 20px;
    }
  }
}
