@import "../variables.scss";

.section-container {
    padding-inline: $section-padding-inline;
    padding-block: $section-padding-block;

    color: $base-color;

    display: flex;
    align-items: center;
    gap: 140px;
    overflow: hidden;
    & > * {
        flex: 1;
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        max-height: none;
        gap: 0;
    }
}