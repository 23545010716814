@import "../../css/all";

.UIWck__select {
  position        : relative;
  display         : flex;
  justify-content : center;
  flex-flow  : column;
  float           : left;
  width           : 100%;
  margin          : calculateRem(4) 0;
  @include placeholder($weCheckBlue);

  &__label {
	position        : relative;
	display         : flex;
	align-items     : center;
	width           : 100%;
	height          : calculateRem(50);
	background      : url('#{$s3ImagesLink}/dashboard/shared/arrowDownPeacockBlue.svg') no-repeat calculateRem(10) #EBF1F7;
	background-size : calculateRem(14);
	cursor          : pointer;
	transition      : 0.2s;
	border-radius   : calculateRem(4);
	padding         : 0 calculateRem(14);
	user-select     : none;
	color           : $weCheckBlue;

	&--error {
	  background-color : rgba(237, 28, 36, 0.11);
	  color            : $pinkish-red;
	  border-bottom    : calculateRem(2) solid $pinkish-red;

	  &:hover {
		background      : url('#{$s3ImagesLink}/dashboard/shared/arrowDownPeacockBlue.svg') no-repeat calculateRem(10) rgba(237, 28, 36, 0.21) !important;
		background-size : calculateRem(14) !important;
		}
	  }

	&__placeholder {
	  position       : absolute;
	  padding-top    : calculateRem(14);
	  top            : 0;
	  transition     : .2s;
	  font-weight    : normal;
	  pointer-events : none;

	  &--focused {
		@extend .UIWck__select__label__placeholder;
		padding-top : calculateRem(3);
		font-size   : calculateRem(14);
		}
	  }

	&__display {
	  padding-top : calculateRem(14);
	  }

	&--open {
	  @extend .UIWck__select__label;
	  box-shadow    : 0 calculateRem(3) calculateRem(5) 0 rgba(0, 0, 0, 0.1);
	  border-radius : calculateRem(4) calculateRem(4) 0 0;
	  }

	&:hover {
	  background      : url('#{$s3ImagesLink}/dashboard/shared/arrowDownPeacockBlue.svg') no-repeat calculateRem(10) darken(#EBF1F7, 5%);
	  background-size : calculateRem(14);
	  }
	}

  .dropdown--open {
	opacity        : 1;
	pointer-events : auto;
	animation      : active 0.2s forwards;
	}

  &__dropdown {
	position         : fixed;
	background-color : $pattensBlue;
	box-shadow       : 0 calculateRem(3) calculateRem(5) 0 rgba(0, 0, 0, 0.1);
	list-style-type  : none;
	@include respond-to('handhelds') {
	  top              : calculateRem(330)!important;
	  }

	transition       : 0.3s;
	max-height       : calculateRem(160);
	overflow-y       : scroll;
	width            : 100%;
	padding          : 0;
	margin           : 0;
	opacity          : 0;
	z-index          : 5;
	pointer-events   : none;

	&__dropDownPlaceholder {
	  pointer-events : none;
	  }

	@include customScroll($pattensBlue, darken($pattensBlue, 18%));

	li {
	  position    : relative;
	  display     : flex;
	  align-items : center;
	  height      : calculateRem(40);
	  outline     : none;
	  user-select : none;
	  padding     : 0 calculateRem(14);
	  cursor      : pointer;
	  overflow    : hidden;
	  transition  : 0.2s;

	  &:active {
		background-color : $weCheckBlue;
		color            : #FFFFFF;
		}

	  @include respond-to('handhelds') {
		&:before {
		  content          : '';
		  position         : absolute;
		  top              : calculateRem(-10);
		  right            : 30%;
		  left             : 30%;
		  background-color : $weCheckBlue;
		  height           : calculateRem(80);
		  z-index          : -1;
		  border-radius    : calculateRem(30);
		  opacity          : 0;
		  transition       : 0.8s;
		  }
		&:focus, &:active {
		  background-color : #EBF1F7;
		  color            : #FFFFFF;

		  &:before {
			opacity : 1;
			right   : calculateRem(-200);
			left    : calculateRem(-200);
			}
		  }
		}
	  }

	.selected {
	  background-color : $weCheckBlue !important;
	  color            : #FFFFFF;
	  transition       : 0.8s;
	  }

	.hover {
	  background-color : $light-blue-grey;
	  color            : #FFFFFF;
	  }
	}
  }

@keyframes active {
  0% {
	overflow-y : hidden;
	}
  100% {
	overflow-y : scroll;
	}
  }
