.doublecheck-first-section-container {
    &>.animation-container {
        display: flex;
        justify-content: center;
        &>* {
            width: 100%;
            max-width: 560px;
        }
    }

    &>* {
        width: 100%;
    }

    &>.right-side-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        transform: translateX(100%);

        &.in-viewport {
            animation: slideFromRight 1s;
            animation-fill-mode: forwards;
        }

        &>.animation-and-cta {
            display: flex;
            align-items: center;
            position: relative;

            &>.hide-animation {
                position: absolute;
                right: 270px;
                height: 60px;
                width: 120px;
                background-color: #F2F2F2;
                overflow: hidden;

                &>.animation-container {
                    position: absolute;
                    top: -102px;
                    right: -100px;

                    &>* {
                        width: 250px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        & > .animation-container {
            display: flex;
            justify-content: center;
            max-height: 340px;
            & > * {
                max-height: 340px;
            }
            
        }
    }
}