.personal-area-header-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  text-align: center;
  gap: var(--gap-md);
  padding-block-start: 40px;

  @media (width < 1000px) {
    padding-block-start: 0;
    padding-block-end: 10px;
  }

  & > h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-size-xxl);
    font-weight: 500;
    transition: font-size 0.2s ease-in-out;

    & > span:not(.total-loans) {
      font-size: var(--font-size-lg2);
      font-weight: 600;
      transition: font-size 0.2s ease-in-out;
    }

    & > .total-loans {
      display: flex;
      align-items: center;

      & > .shekel-and-star-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > .shekel-container {
          font-size: var(--font-size-lg2);
          line-height: 0.8;
          transition: font-size 0.2s ease-in-out, line-height 0.2s ease-in-out;
        }
      }
    }
  }

  & > h4 {
    font-size: var(--font-size-sm2);
  }

  &:has(.no-content-container) {
    gap: 50px;
    @media (width < 1000px) {
      gap: 20px;
    }
  }

  & > .no-content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > h1 {
      font-size: 29px;
      font-weight: 600;
    }

    & > h4 {
      font-size: 18px;
      white-space: pre-wrap;
    }
  }

  & > .animation-container {
    width: 100%;
    min-height: max-content;
    height: max-content;
    display: flex;
    justify-content: center;

    & > object {
      transition: max-width 0.2s ease-in-out;
      max-width: 200px;
      min-height: max-content;
      height: max-content;
    }

    @media (width < 1000px) {
      grid-area: 1;
    }
  }
}
