@import "../../../../../css/all.scss";

.TestimonialsStrip {
  padding-top : 20px;
  width      : 100%;
  @include respond-to("handhelds") {
	margin-top : 0 !important;
	}

  /* Firefox */
  * {
	scrollbar-color : #00509E #E6E6E6;
	scrollbar-width : thin;
	}

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
	width : 10px;
	}

  *::-webkit-scrollbar-track {
	background : #E6E6E6;
	}

  *::-webkit-scrollbar-thumb {
	background-color : #00509E;
	border           : 2px solid #E6E6E6;
	border-radius    : 10px;
	}

  h1 {
	color       : $weCheckBlue;
	font-size   : 45px;
	font-weight : bold;
	text-align  : center;
	}

  .pressImages {
	overflow-x        : auto;
	overflow-y        : hidden;
	text-align        : center;
	-khtml-user-drag  : none;
	-moz-user-drag    : none;
	-o-user-drag      : none;
	-webkit-user-drag : none;
	white-space       : nowrap;

	@include respond-to("handhelds") {
	  height : 300px;
	  }

	.child {
	  display : inline-block;
	  }

	a {
	  -khtml-user-drag  : none;
	  -moz-user-drag    : none;
	  -o-user-drag      : none;
	  -webkit-user-drag : none;
	  }

	img {
	  cursor            : pointer !important;
	  height            : 345px;
	  -khtml-user-drag  : none;
	  -moz-user-drag    : none;
	  -o-user-drag      : none;
	  -webkit-user-drag : none;
	  @include respond-to("handhelds") {
		height : 300px;
		}
	  }
	}

  .pressImages::-webkit-scrollbar {
	@include respond-to("handhelds") {
	  display: none;
	  }

	}
  }

.TestimonialsTopPart {
  align-items : center;
  cursor      : pointer;
  display     : flex;
  flex-flow   : column;

  .textWithLogo {
	color         : $weCheckBlue !important;
	display       : flex;
	flex-flow     : row;
	margin-bottom : 20px;

	margin-top    : 30px;

	@include respond-to("handhelds") {
	  margin-bottom : 10px;
	  margin-top    : 0;
	  }

	h5 {
	  font-size    : 45px;
	  font-weight  : bold;
	  margin-left  : 10px;
	  margin-right : 5px;
	  @include respond-to("handhelds") {
		font-size : 30px;
		}
	  }

	img {
	  width : 240px;

	  @include respond-to("handhelds") {
		width : 140px;
		}
	  }
	}

  .topImage {
	cursor : pointer;
	width  : 100%;
	}

  .testimony {
	padding : 10px;

	img {
	  width : 13px;
	  }

	h2 {
	  color      : $weCheckBlue;
	  font-size  : calculateRem(25);
	  text-align : right;
	  }
	}

  a {
	text-decoration : none;
	}
  }

.TestimonialsPage {
  background     : url("#{$s3ImagesLink}/marketing/testimonials/grass.svg") repeat-x bottom,
  url("#{$s3ImagesLink}/marketing/testimonials/mansitting.svg") no-repeat bottom left;

  padding-bottom : 200px;

  padding-top    : calculateRem(80);
  @include respond-to("medium-handhelds") {
	background  : url("#{$s3ImagesLink}/marketing/testimonials/grass.svg") repeat-x bottom;
	padding-top : calculateRem(58);
	}
  }
