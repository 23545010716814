.login-single-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    position: relative;

    @media (max-width: 500px) {
        flex-direction: row;
        text-align: start;
    }

    &>p {
        white-space: pre-wrap;
        font-size: 18px;
        font-weight: 600;
        color: #1D3557;
        min-width: max-content;

        &::first-line {
            font-weight: 700;
            font-size: 20px;
        }

        @media (max-width: 500px) {
            font-size: 16px;
        }

        & > span {
            display: block;
            padding-block-start: 6px;
            font-size: 14px;
        }
    
    }

    &>.icon-container {
        max-width: 70px;
    }

    &>.text-section-container {
        width: 100%;
    }
}