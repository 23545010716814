@import "../../../../../css/all";

.PartnersSection {
  .SectionWrapper {
	&__title {
	  h2 {
		text-align : center !important;
		width      : 100%;
		@include respond-to("handhelds") {
		  font-size : 30px;
		  width     : 80%;
		  }
		}
	  }
	}

  .Partners {
	background      : url("#{$s3ImagesLink}/marketing/bricks.png") no-repeat center !important;
	display         : flex;
	justify-content : center;

	&__window {
	  align-items     : center;
	  background      : url("#{$s3ImagesLink}/marketing/lamp.png") no-repeat 0 center,
	  url("#{$s3ImagesLink}/marketing/lamp.png") no-repeat 100% center,
	  url("#{$s3ImagesLink}/marketing/big_window.png") no-repeat center;
	  background-size : auto, auto, calculateRem(928);
	  $width          : calculateRem(928);
	  $height         : calculateRem(514);
	  display         : flex;
	  height          : $height;
	  justify-content : center;
	  width           : calc(#{$width} + #{calculateRem(240)});
	  @include respond-to("medium-handhelds") {
		background : transparent;
		height     : auto;
		width      : 100%;
		}

	  &__container {
		align-items     : center;
		display         : flex;
		flex-direction  : column;
		height          : calc(#{$height} - #{calculateRem(70)});
		justify-content : center;
		text-align      : center;
		width           : calc(#{$width} - #{calculateRem(252)});
		@include respond-to("medium-handhelds") {
		  height : auto;
		  margin : 0;
		  width  : auto;
		  }

		h4 {
		  line-height : 120%;
		  }

		&__title {
		  font-weight : bold;
		  margin      : 0;
		  }

		&__pictures {
		  display               : grid;
		  grid-column-gap       : calculateRem(6);
		  grid-template-columns : 1fr 1fr;
		  justify-content       : center;
		  margin-top            : calculateRem(50);

		  &__picture {
			background-color : #FFFFFF;
			border           : calculateRem(2) solid #F48C90;
			height           : calculateRem(234);
			position         : relative;
			width            : calculateRem(260);
			@include respond-to("medium-handhelds") {
			  height : 34vw;
			  width  : 42vw;
			  }

			&__pin {
			  background-image : url("#{$s3ImagesLink}/marketing/picture-pin.png");
			  background-size  : cover;
			  height           : calculateRem(30);
			  margin           : calculateRem(-30) calculateRem(-25) 0 0;
			  position         : absolute;
			  right            : 50%;
			  width            : calculateRem(51);
			  }

			&__content {
			  align-items     : center;
			  border          : calculateRem(2) solid #F48C90;
			  display         : flex;
			  height          : 80%;
			  justify-content : center;
			  margin          : 10%;
			  width           : 80%;

			  img {
				width : 80%;
				}
			  }
			}
		  }
		}
	  }
	}
  }
