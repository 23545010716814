@import "../../css/all";

.ImageContainer {

  cursor   : pointer;
  overflow : hidden;
  position : relative;

  @include respond-to('handhelds') {
	height : 200px;
	}

  .BackgroundImg {
	background-position : center;
	background-size     : cover;
	height              : 100%;
	left                : 0;
	position            : absolute;
	top                 : 0;
	width               : 100%;


	&.hoverZoom {
	  transition : 0.5s transform ease;
	  }

	&.hoverZoom:hover {
	  transform : scale(1.07);
	  }

	}

  }