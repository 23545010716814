.LayoutContainer {
  direction: rtl;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100dvh;

  & > .inner-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    overflow-x: clip;

    & > * {
      flex-grow: 1;
    }
  }

  @media (width > 1000px) {
    &.overflow-hidden {
      max-height: 100vh;
      overflow-y: clip;
      & > .inner-content {
        max-height: 100vh;
        height: 100vh;
      }
    }
  }
}
