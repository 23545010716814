.FourOhFourPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    max-width: 100%;
    direction: rtl;
    position: relative;
    overflow: hidden;

    &>.Header {
        position: fixed;
        top: 0;
    }

    &>.center-image-container {
        display: flex;
        flex-direction: column;
        position: relative;

        &>.center-image {
            z-index: 2;
        }

        &>.center-image,
        &>.center-image-shadow {
            @media screen and (max-width: 570px) {
                max-width: 85vw;
            }
        }

        &>.background-container {
            position: absolute;
            left: 46%;
            transform: translate(-50%, 0);

            &>.background {
                transform: translateY(48px);
                max-width: 880px;

                @media screen and (max-width: 570px) {
                    max-width: 144vw;
                    transform: translateY(36.5%);
                }
            }

            &>.squares {
                position: absolute;
                left: -48px;
                top: -94px;
                @media screen and (max-width: 570px) {
                    max-width: 80px;
                    left: 22%;
                    top: -110px;    
                }
            }

        }
    }

    &>.cta-container {
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        position: absolute;
        bottom: min(240px, 21vh);

        &>h1 {
            color: #1C4680;
            font-size: clamp(19px, 2.2vw, 42px);
            text-align: center;
            font-weight: 800;
            min-width: max-content;

            &::first-line {
                font-weight: 600;
            }
        }

        &>button {
            border: none;
            font-size: clamp(24px, 1.7vw, 32px);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            padding-inline: 70px;
            padding-block: 10px;
            background-color: #168FFC;
            color: #FFFFFF;
            transition: background-color 0.1s ease;
            &:hover {
                cursor: pointer;
                background-color: #007FD4;
            }
        }
    }
}