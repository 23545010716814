.open-display-container {
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  padding-block-end: 16px;
  padding-block-start: 10px;
  z-index: 1;

  & > h4 {
    font-size: var(--font-size-sm);
    text-align: center;
    color: var(--light-blue-text);
    font-weight: 500;
  }

  & > .date-range {
    display: grid;
    padding-inline: 50px;
    padding-block-start: 10px;
    gap: var(--gap-sm);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;

    @media (width < 1000px) {
      gap: 18px 14px;
    }
  }
}
