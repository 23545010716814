.modal-contact-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > p {
    font-size: 20px;
    white-space: pre-wrap;
    @media (width < 600px) {
      white-space: normal;
      font-size: 16px;
    }
  }
  & > .contact-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }
}
