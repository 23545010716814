@import "../../css/all";

.Video {
  align-items      : center;
  background-color : rgba(29, 29, 29, 0.6);
  display          : flex;
  height           : 100%;
  justify-content  : center;
  position         : fixed;
  top              : 0;
  width            : 100vw;
  z-index          : 100;

  &__wrapper {
	display        : flex;
	flex-direction : column;
	}

  &--show {
	display : flex;
	}

  &--hidden {
	display : none;
	}

  &__close {
	background      : url("#{$s3ImagesLink}/shared/x3.svg") no-repeat center #FFFFFF;
	background-size : 60%;
	border          : 0;
	border-radius   : 100%;
	cursor          : pointer;
	height          : calculateRem(32);
	margin          : calculateRem(-20) calculateRem(-30) calculateRem(20) calculateRem(20);
	width           : calculateRem(32);
	@include respond-to("handhelds") {
	  margin-right : 0;
	  }
	}

  video {
	max-height : 500px;
	@include respond-to("handhelds") {
	  max-height : 80%;
	  }
	}

  iframe {
	border : none;
	height : 30vw;
	width  : 60vw;
	@include respond-to("medium-handhelds") {
	  height : 50vw;
	  width  : calc(95vw - #{calculateRem(60)});
	  }
	}
  }
