@import "../../../../../css/all";

.InputContainer {
  display       : flex;
  justify-content: space-between;
  border-bottom : 1px solid $weCheckBlue;
  width         : 400px;
  margin        : 0 auto 20px;

  &--disabled {
	pointer-events: none;
	}

  @include respond-to("medium-handhelds") {
	margin        : 10px auto;
	width         : 100%;
	}

  ::placeholder {
	color : $weCheckBlue;
	font-size     : calculateRem(18);
	}

  input {
	border        : none;
	color         : $weCheckBlue;
	font-size     : calculateRem(18);
	margin-bottom : 5px;
	}

  img {
	margin-left: 10px;
	}
  }

.SendDCWizard {
  display     : flex;
  flex-flow   : column;
  align-items : center;
  flex        : 1 1 100%;

  &--disabled {
	pointer-events: none;
	}

  .wizardContainer {
	display         : flex;
	justify-content : center;

	}

  }