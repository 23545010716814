@import "../../../css/all";

$maxCalcWidth : calculateRem(1200);

.MortCalc {
  align-items        : center;
  background-color   : #FFFFFF;
  color              : $weCheckBlue;
  direction          : rtl;
  display            : flex;
  flex-flow          : column;
  margin-block-start : 60px;
  padding            : 0;

  text-align         : center;
  width              : 100%;
  @include respond-to("handhelds") {
	top   : calculateRem(70);
	width : 100%;
	}
  .heading {
	@include respond-to("handhelds") {
	  display : none;
	  }
	}

  .calculator {
	align-items     : flex-start;
	display         : flex;
	flex-flow       : column;

	justify-content : center;
	margin          : calculateRem(20) auto 30px;
	width           : 100vw;
	@include respond-to("handhelds") {
	  align-items : center;
	  flex-flow   : column;
	  margin      : 0;
	  width       : 95%;
	  }
	}

  h1 {
	font-weight : bold;
	}

  p {
	white-space : normal;
	}

  .legalBox {
	align-items   : flex-start;
	display       : flex;
	flex-flow     : column;
	justify-items : right;
	margin-right  : 20px;
	width         : 100%;

	@include respond-to("handhelds") {
	  display : none;
	  }

	p {
	  text-align : right;
	  }

	.legalTitle {
	  font-weight : bold;
	  }

	.legalText {
	  font-size : 15px;
	  }

	img {
	  margin-left : 5px;
	  margin-top  : 2px;
	  width       : 18px;
	  }
	}
  }
