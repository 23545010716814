@import "../variables.scss";

.grid-section-container {
    display: grid;
    padding-inline: $section-padding-inline;
    padding-block: $section-padding-block;

    color: $base-color;

    grid-template-columns: 1fr 1fr;
    gap: 140px;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        gap: 40px 0;
      
    }
}