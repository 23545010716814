@import "../../../../../../../../css/all";

.ErrorSlide {
  display : flex;

  h4 {
	margin    : 0 auto;
	width     : 391px;
	font-size : calculateRem(26);

	@include respond-to("medium-handhelds") {
	  font-size : calculateRem(18);
	  width     : unset;
	  }
	}

  img {
	margin : 0 auto;
	width  : 130px;
	height : 198px;
	@include respond-to("medium-handhelds") {
	  width  : 82px;
	  height : 125px;
	  }
	}

  .buttons {
	display         : flex;
	justify-content : center;
	}

  .buttonDiv:first-child {
	margin-left : 20px;
	}

  .buttonDiv {
	margin-top : 40px;
	@include respond-to("medium-handhelds") {
	  margin-top : 20px;
	  }

	a {
	  margin    : 0 auto;
	  width     : 180px;
	  font-size : calculateRem(24);
	  height    : 64px;
	  @include respond-to("medium-handhelds") {
		width     : 130px;
		margin    : 0 auto;
		font-size : calculateRem(20);
		height    : 50px;
		}
	  }

	}

  }