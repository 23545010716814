.login-submit-button {
  background-color: #1799fc;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  padding-block: 12px;
  width: 175px;
  font-weight: 600;
  position: relative;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    &:not([disabled]) {
      cursor: pointer;
      background-color: #0389ef;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 50px;
  }

  &:disabled {
    opacity: 0.5;
  }
}
