.chat-container {
  width: 354px;
  flex-direction: column;
  bottom: 70px;
  position: absolute;
  .cs-main-container {
    border-radius: 5px 0 5px 5px;
    border: none;
  }

  @media (width < 1000px) {
    width: calc(100% - 20px) !important;
    max-width: calc(100vw - 20px) !important;
    transition: transform 0.2s linear;
    position: unset;
    bottom: unset;

    &.is-open {
      transform: translateY(90px);
      display: contents;
    }
    &:not(.is-loggedin) {
      bottom: 230px;
      &.is-open {
        transform: translateY(220px);
      }
    }
  }

  &:not(.is-open) {
    .cs-chat-container .cs-message-input {
      border: 0;
    }
  }
}

.cs-message-list {
  min-height: 0 !important;
  max-height: 0;
  max-width: calc(100vw - 20px) !important;
  height: 0 !important;
  padding: 0;
  transition: padding 0.2s linear, max-height 0.2s linear, height 0.2s linear;
  overflow-y: hidden !important;

  &.is-open {
    max-height: min(400px, 40vh) !important;
    height: min(400px, 40vh) !important;
    padding: 10px 0;
    overflow-y: visible !important;

    @media (width < 1000px) {
      max-height: 40dv !important;
      height: 40dvh !important;
    }
  }
}

.cs-message--outgoing {
  justify-content: flex-start !important;

  .cs-message__content {
    background-color: #3d5a80 !important;

    .cs-message__html-content {
      color: #ffffff !important;
    }
  }
}

.cs-message--incoming {
  justify-content: flex-end !important;

  .cs-message__content {
    background-color: #f2f2f2 !important;
  }
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
  background-color: #f2f2f2 !important;
}

.ps__rail-y {
  display: none !important;
}

@media (width < 1000px) {
  .cs-message-input__content-editor-container {
    font-size: 16px !important;
  }
}

.cs-chat-container
  .cs-message-input
  .cs-message-input__content-editor-wrapper:first-child {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.cs-typing-indicator {
  position: relative !important;
  float: left !important;
  margin: 10px;
}

.cs-typing-indicator__dot {
  background-color: rgb(29, 53, 87) !important;
  height: 8px !important;
  width: 8px !important;
  animation: cs-typing-indicator__typing-animation-override 1.3s infinite
    ease-in-out !important;

  &.cs-typing-indicator__dot:nth-child(3) {
    animation-delay: 0.5s !important;
    margin-right: 5px;
  }
  &.cs-typing-indicator__dot:nth-child(2) {
    animation-delay: 0.7s !important;
    margin-right: 5px;
  }
  &.cs-typing-indicator__dot:nth-child(1) {
    animation-delay: 0.9s !important;
  }
}

@keyframes cs-typing-indicator__typing-animation-override {
  0% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
