.form-container-newsletter-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--gap-sm);

  & > * {
    width: 100%;
  }

  & > .form-content-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-inline: 0;
    gap: var(--gap-sm);
    @media (width < 1000px) {
      gap: 20px;
    }
    &.is-open {
      overflow: visible;
      padding-block-end: 0;

      @media (width < 1000px) {
        max-height: 200px;
      }
    }

    > h2 {
      font-size: 22px;
      font-weight: 700;
      text-align: center;
    }

    & > * {
      width: 100%;
    }
  }

  & > .search-button {
    width: 200px;
    border: none;
    background-color: var(--light-blue);
    color: var(--white);
    border-radius: 5px;
    font-size: var(--font-size-lg);
    align-self: center;
    transition: background-color 0.1s ease-in-out;

    &.no-margin {
      margin: 0;
    }
    @media (width < 1000px) {
      height: 50px;
      margin-block-start: auto;
    }
    &:hover {
      &:not(:disabled) {
        cursor: pointer;
        background-color: var(--button-hover);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (width < 1000px) {
      font-size: 20px;
      width: 100%;
    }
  }

  @media (width < 1000px) {
    gap: 20px;
  }
}
