@import "../../../../../../../css/all";

.NameAndPhone {
  display   : flex;
  flex-flow : column;

  .errorLabel {
	color      : red;
	width      : 400px;
	text-align : right;
	font-size  : calculateRem(13);
	margin     : -20px auto 0;
	@include respond-to("medium-handhelds") {
      font-size  : calculateRem(12);
      width      : unset;
      margin     : -10px auto 0;
	  }
	}
  }