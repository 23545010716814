@import "../../css/all";

$popupWidth : calculateRem(1240);

.ProductsSection {
  display        : flex;
  flex-direction : column;
  margin-top     : 20px;

  width          : 100%;

  @include respond-to("medium-screens") {
	height : 930px;
	}

  @include respond-to("wide-screens") {
	height : 930px;
	}

  h2 {
	color         : $weCheckBlue;
	font-size     : calculateRem(43);
	font-weight   : bold;
	margin-bottom : calculateRem(20);
	margin-top    : calculateRem(40);
	text-align    : center;
	width         : 100%;
	@include respond-to("handheld1s") {
	  margin-bottom : 0;
	  margin-top    : 0;
	  }
	}

  .content {
	height : 100%;
	width  : 100%;

	.whiteDiv {
	  display         : flex;
	  height          : 30%;
	  justify-content : center;

	  @include respond-to("handhelds") {
		flex-flow       : column;
		height          : 100%;
		justify-content : unset;
		}
	  }

	.blueDiv {
	  background-color : $extraDarkBlue;
	  display          : flex;
	  flex-flow        : column;
	  height           : 70%;

	  .logoLine {
		display    : flex;
		flex-flow  : row-reverse;
		height     : 55px;
		margin-top : 30px;
		position   : relative;
		top        : 420px;

		@include respond-to("handhelds") {
		  margin-bottom : 10px;
		  margin-top    : 0;
		  }

		h3 {
		  color       : white;
		  font-size   : calculateRem(43);
		  font-weight : bold;
		  @include respond-to("handhelds") {
			font-size : 30px;
			}
		  }

		img {
		  margin-left : calculateRem(10);
		  width       : 190px;
		  }
		}

	  .buttonDiv {
		margin   : 0 auto;
		position : relative;
		top      : 430px;
		width    : calculateRem(200);
		}
	  }
	}
  }
