.login-container {
    height: 100%;
    background-color: #F2F2F2;


    &>.max-width-container {
        display: flex;
        direction: rtl;
        max-width: 1920px;
        margin: 0 auto;
        overflow-x: hidden;
        height: 100%;

        @media (max-width: 1000px) {
            flex-direction: column;
        }

        @media (max-width: 500px) {
            max-height: 200vh;
            overflow: hidden;
        }

        &>* {
            flex: 1;
        }
    }

}
