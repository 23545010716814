.display-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease-in-out, opacity 0.4s ease-in-out;

  &.is-open {
    max-height: 200px;
    opacity: 1;
  }

  & > .text-container {
    text-align: center;

    & > p {
      white-space: pre-wrap;
      &::first-line {
        font-weight: 700;
      }
    }
  }

  & > button {
    border: none;
    background-color: var(--white);
    font-weight: 600;
    font-size: 24px;
    padding-inline: 73px;
    padding-block: 9px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
