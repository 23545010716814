.marker-position {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.gm-style-iw,
.gm-style-iw-tc {
  animation: fadeIn 0.2s ease-in-out both;
}

.gm-ui-hover-effect {
  right: 0;
  top: 0 !important;
}

.cluster-icon {
  color: antiquewhite;

  & > div {
    background-color: #f24a51;
    width: 25px !important;
    height: 25px;
    color: var(--white) !important;
    top: -4px !important;
    right: -8px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info-window {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;
  aspect-ratio: 16/9;

  & > * {
    & > * {
      font-size: 16px;
      font-weight: 600;
    }
  }

  & > .title-container {
    & > h4 {
      font-weight: 700;
    }

    & > h5 {
      color: var(--light-blue-text);
    }
  }

  & > .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      display: flex;
      gap: 4px;

      & > span {
        font-weight: 700;
      }
    }

    .content-properties {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-top: 5px;

      .property-row {
        display: flex;
        gap: 5px;

        .property-item {
          background-color: var(--gray);
          border-radius: 5px;
          max-width: 51px;
          padding: 5px 10px;
          color: var(--dark-blue);
          flex: 1;
          text-align: center;
          margin: 0 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;

          &.selected {
            background-color: var(--light-blue-text);
            color: var(--white);
          }
        }

        .property-item:first-child {
          margin-left: 0;
        }

        .property-item:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
