$base-color: #00509E;
$secondary-color: #FFFFFF;
$mortgage-color: #3C99FC;

$header-font-h1: clamp(30px, 6.1cqi, 50px);
$header-weight-h1: 800;

$header-font-h2: clamp(20px, 4cqi, 30px);
$header-weight-h2: 600;

$header-font-h3: clamp(20px, 3cqi, 24px);
$header-weight-h3: 800;

$paragraph-font: clamp(16px, 2.5cqi, 20px);

$disclaimer-font: clamp(12px, 2cqi, 14px);

$mort-contact-header-font: clamp(23px, 5cqi, 36px);

$main-background-color: #FFFFFF;
$alternate-background-color: #F2F2F2;

$section-min-height: calc(100svh - 80px);
$section-padding-inline: clamp(26px, 6.3cqi, 130px);
$section-padding-block: min(120px, 10cqi) min(80px, 7cqi);

$button-background-color: #1799FC;
$button-hover-color: #35a6fc;
$button-font: clamp(18px, 3cqi, 24px);