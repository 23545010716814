@import "weCheckStyles";

$mobileRatio : 0.75;

@function calculateRem($size) {
  @return $size / 16 + rem;
  }

@mixin mobileSize($sizeInPx, $mobileSizeInPx) {
  @include respond-to("medium-handhelds") {
	@if $mobileSizeInPx == 0 {
	  font-size : calculateRem($sizeInPx * $mobileRatio);
	  } @else {
	  font-size : calculateRem($mobileSizeInPx);
	  }
	}
  }

@mixin heading($sizeInPx, $mobileSizeInPx: 0) {
  font-size   : calculateRem($sizeInPx);
  margin      : 0;
  font-weight : normal;

  @include mobileSize($sizeInPx, $mobileSizeInPx);
  }

@mixin paragraph($sizeInPx, $mobileSizeInPx: 0) {
  font-size   : calculateRem($sizeInPx);
  margin      : 0;
  padding     : 0;
  white-space : normal;

  @include mobileSize($sizeInPx, $mobileSizeInPx);
  }

@mixin wckLabel($sizeInPx, $mobileSizeInPx: 0) {
  font-size   : calculateRem($sizeInPx);
  margin      : 0;
  padding     : 0;
  white-space : normal;

  @include mobileSize($sizeInPx, $sizeInPx);
  }

h1 {
  @include heading(90, 48);
  }

h2 {
  @include heading(45, 32);
  }

h3 {
  @include heading(36);
  }

h4 {
  @include heading(32);
  }

h5 {
  @include heading(24);
  }

h6 {
  @include heading(20, 20);
  }

p {
  @include paragraph(20, 20);
  }

small {
  font-size : calculateRem(14);
  }

article.bigParagraph,
p.bigParagraph {
  @include paragraph(22);
  }

article.smallParagraph,
p.smallParagraph {
  @include paragraph(16);
  }

label {
  @include wckLabel(16);
  }

.close {
  color  : white !important;
  height : 32px;
  width  : 32px;
  }

.close:hover {
  opacity : 1;
  }

.close:before,
.close:after {
  background-color : white;
  color            : white !important;
  content          : " ";
  height           : 20px;
  left             : 15px;
  position         : absolute;
  width            : 2px;
  }

.close:before {
  transform : rotate(35deg);
  }

.close:after {
  transform : rotate(-35deg);
  }
