.year-container-wrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: var(--font-size-md);
    font-weight: 700;
    color: var(--light-blue-text);
    width: 100%;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &>.single-year-container {
        display: flex;
        justify-content: center;
        width: 100%; 
        flex-shrink: 0;
        transition: opacity 0.5s ease-in-out;
        justify-self: center;

        &:not(.selected) {
            transition: opacity 0.2s ease-in-out;
            opacity: 0;

        }

        @media (width < 1000px) {
            font-size: 16px;
        }
    }
}