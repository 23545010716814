@import "../../css/all";

.wecForm__Button {
  align-items     : center;
  border-radius   : calculateRem(4);
  direction       : rtl;
  display         : flex;
  height          : calculateRem(50);
  justify-content : center;
  padding         : 0 calculateRem(12);
  transition      : 0.2s;
  user-select     : none;

  h4 {
	font-weight : normal;
	}

  &:disabled {
	background-color : #EBF1F7 !important;
	border-color     : #EBF1F7 !important;
	color            : darken(#EBF1F7, 20%) !important;
	cursor           : default;
	}

  &--outline {
	@extend .wecForm__Button;
	background-color : transparent;

	.wecForm__Button__arrows {
	  background : url("#{$s3ImagesLink}/shared/ArrowsBlue.svg");
	  }

	&--primary {
	  @extend .wecForm__Button--outline;
	  border : calculateRem(4) solid $peacock-blue;
	  color  : $peacock-blue;

	  &:hover {
		background-color : $peacock-blue;

		color            : #FFFFFF;

		.wecForm__Button__arrows {
		  background : url("#{$s3ImagesLink}/shared/btnArrows.svg");
		  }
		}
	  }

	&--danger {
	  @extend .wecForm__Button--outline;
	  border : calculateRem(4) solid $pinkish-red;
	  color  : $pinkish-red;

	  .wecForm__Button__arrows {
		background : url("#{$s3ImagesLink}/shared/ArrowsRed.svg");
		}

	  &:hover {
		border-color : darken($newRed, 5%);
		}
	  }
	}

  &--primary {
	@extend .wecForm__Button;
	background-color : $peacock-blue;
	border-color     : $peacock-blue;
	color            : #FFFFFF;

	&:hover {
	  background-color : darken($peacock-blue, 5%);
	  }
	}

  &--danger {
	@extend .wecForm__Button;
	background-color : $pinkish-red;
	border-color     : $pinkish-red;
	color            : #FFFFFF;

	&:hover {
	  background-color : darken($pinkish-red, 5%);
	  }
	}

  &--old {
	@extend .wecForm__Button;
	background-color : $weCheckAzure;
	border           : 0;
	border-radius    : 2rem;
	color            : #FFFFFF;
	padding          : 0 calculateRem(20) 0 calculateRem(20);

	&:hover {
	  background-color : darken($weCheckAzure, 3%);
	  }

	&:disabled {
	  background : #7698AF !important;
	  color      : #D1D9DF !important;

	  &:hover {
		background-color : #7698AF !important;
		}
	  }

	&.wecForm__Button__arrows {
	  padding : 0 calculateRem(20) 0 calculateRem(30);
	  }
	}

  img {
	height      : calculateRem(30);
	margin-left : calculateRem(10);
	}

  &__arrows {
	background   : url("#{$s3ImagesLink}/shared/btnArrows.svg");
	height       : calculateRem(11);
	margin-right : calculateRem(10);
	margin-top   : calculateRem(1);
	width        : calculateRem(10);
	}
  }
