.payment-cta-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000029;
  padding-inline: 8px;
  padding-block: 10px;
  width: 100%;
  min-width: max-content;

  & > * {
    font-size: var(--font-size-sm);
  }

  & > h5 {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: max-content;

    &:hover {
      cursor: pointer;
    }

    @media (width < 1000px) {
      justify-content: center;
    }
  }

  & > .payment-cta-accordion {
    &.accordion-container {
      padding: 0;
      padding-block-end: 0;
      transition: all 0.2s linear;
      &.is-open {
        padding-block-start: 10px;
        overflow: visible;
      }
    }


    & > p {
      text-align: center;
      font-size: var(--font-size-sm);

      & > span {
        font-weight: 700;
      }
    }
  }
}
