@import "../../css/all.scss";

.Header {
  align-items: center;
  background-color: rgba($color: #ffffff, $alpha: 0.98);
  box-shadow: 0 6px 11px #cacaca7a;
  display: flex;
  gap: min(36px, 1.9vw);
  padding-block: 10px;
  padding-inline: min(65px, 3.4vw);
  position: sticky;
  left: 0;
  top: 0;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.2s linear;
  width: 100%;
  z-index: 100;
  height: 80px;

  @media (width > 1000px) {
    &.slide-up {
      transform: translate3d(0, -100%, 0);
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    overflow: hidden;
    padding-block: 10px;
    padding-inline: 10px;
    max-height: 66px;

    &.open {
      box-shadow: none;
      transition: box-shadow 0.2s ease;
      overflow: visible;
    }
  }

  & > .top-part-container {
    @media screen and (max-width: 1000px) {
      .right-side-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }

    & > .HeaderLogo {
      padding-inline-end: min(26px, 1.4vw);

      & > img {
        max-width: 160px;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 1000px) {
        padding-inline-end: 20px;

        & > img {
          width: 93px;
        }
      }
    }

    & > .hamburger-container {
      display: none;

      @media screen and (max-width: 1000px) {
        display: block;
      }
    }

    @media screen and (max-width: 1000px) {
      align-items: center;
      display: flex;
      height: 80px;
      justify-content: space-between;
      width: 100%;
    }
  }

  & > .link-list-container {
    .close-menu {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .bottom-buttons-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .bottom-buttons {
        display: flex;
        gap: 20px;

        a {
          color: #0d80d8;
        }
      }
    }

    align-items: center;
    display: flex;
    gap: min(30px, 1.6vw);
    justify-content: space-between;
    width: 100%;

    & > .buttons-container {
      display: flex;
      gap: 18px;
    }

    @media screen and (max-width: 1000px) {
      background-color: transparent;
      flex-direction: column-reverse;
      gap: 40px;
      max-height: 0;
      opacity: 0;
      overflow: scroll;
      position: fixed;
      top: 76px;
      transition: max-height 0.4s ease, background-color 0.2s ease;

      & > .buttons-container {
        opacity: 0;
      }

      &.open {
        position: relative;
        top: -50px;
        background-color: white;
        min-height: 100vh;
        min-width: 100vw;
        height: 100%;
        opacity: 1;
        padding-block-end: 60px;
        padding-block-start: 40px;
        padding-inline: 20px;
        transition: opacity 0.2s ease, width 0.2s ease; /* Exclude height from transition */

        & > .buttons-container {
          animation: changeOpacity 0.6s;
          gap: 40px;
          justify-content: center;
          opacity: 1;
          width: 100%;
        }
      }
    }

    & > .links-container {
      align-items: center;
      display: flex;
      gap: 20px;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 10px;
        align-items: flex-start;
        color: #1d3557;
      }
    }
  }

  &.justify-center {
    justify-content: center;

    @media screen and (max-width: 1400px) {
      & > .top-part-container {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }

    & > .link-list-container {
      display: none;
    }
  }
}
