.details-item-container {
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  padding-inline: 20px;
  padding-block: 10px;
  gap: 10px;

  @media (width <1000px) {
    padding-block: 8px;
    padding-inline: 10px;
  }

  & > .details-item-icon {
    display: flex;
  }

  & > .details-item-data-section {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > .details-item-label {
      color: var(--light-blue-text);
      font-size: 16px;
      font-weight: 600;
      @media (width <1000px) {
        font-size: 14px;
      }
    }

    & > .details-item-data {
      font-size: 18px;
      font-weight: 600;

      @media (width <1000px) {
        font-size: 16px;
      }
    }
  }
}
