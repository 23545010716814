.close-button {
    &.filter {
        &>img {
            width: 15px;
        }

        position: relative;
        right: auto;
        top: auto;
    }
}