@import "../../../../../../config/config.scss";
@import "../../../../../css/all.scss";

.WelcomeSection {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding-block-end: 40px;
	transform: translateY(-100px);

	@include respond-to("medium-handhelds") {
		display: flex;
		flex-direction: column;
		gap: 60px;
		transform: none;
	}

	@media screen and (max-width : 480px) {
		margin-block-start: 0;
	}

	&>.welcome-section-right-side {
		animation: slideFromRight;
		animation-duration: 1s;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include respond-to("medium-handhelds") {
			animation: none;
			gap: 22px;
			width: 100%;
		}

		&>.WelcomeTitle {
			background : url("#{$s3ImagesLink}/marketing/mort/NewAssets/blue-background-sliced.svg");
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			height: 100%;
			padding-block-end: clamp(20px, 12vw, 175px);
			padding-block-start: clamp(20px, 6vw, 126px);
			padding-block-start: clamp(20px, 9.6vw, 200px);
			padding-inline-end: clamp(20px, 12.7vw, 260px);
			padding-inline-start: clamp(20px, 6vw, 140px);

			@media screen and (max-width : 1250px) {
				animation: slideFromRight;
				animation-duration: 1s;
				width: auto;
			}

			@include respond-to("medium-handhelds") {
				background : url("#{$s3ImagesLink}/marketing/mort/NewAssets/blue-background-sliced-mobile.svg");
				background-repeat: no-repeat;
				background-size: 100%;
				justify-content: center;
				min-height: 60vw;
				padding-block-end: 0;
				padding-block-start: 26px;
				padding-inline-end: 0;
				padding-inline-start: 0;
			}

			&>h1 {
				color: #FFFFFF;
				display: flex;
				flex-direction: column;
				font-size: clamp(28px, 4.1vw, 67px);
				min-width: max-content;

				&>.second-line {
					align-items: center;
					display: flex;
					font-size: clamp(33px, 4.4vw, 82px);
					font-weight: 800;
					gap: 1vw;
					justify-content: space-between;

					&>.logo-container {
						align-items: center;
						display: flex;
						flex: 1;

						&>img {
							max-width: 390px;
							width: 100%;

							@include respond-to("medium-handhelds") {
								width: 168px;
							}
						}
					}
				}

				&>.third-line {
					align-self: flex-end;
					font-size: clamp(18px, 2.7vw, 42px);
					font-weight: 300;
				}
			}
		}
	}

	&>.welcome-section-left-side {
		animation: slideFromLeftIndent;
		animation-duration: 1s;
		display: flex;
		flex-direction: column;
		grid-column: 2;
		grid-row-end: 2;
		grid-row-start: 0;
		justify-content: flex-end;
		padding-block-start: 100px;
		transform: translateX(90px);

		@media screen and (max-width : 1250px) {
			padding-block-start: 0;
			width: 100%;
		}

		@include respond-to("medium-handhelds") {
			align-items: center;
			animation: slideFromRight;
			animation-duration: 1s;
			gap: 20px;
			padding-inline: 58px;
			transform: none;
		}

		&>.PresentorSection {
			align-items: center;
			display: flex;
			flex-direction: row-reverse;
			min-width: 100%;

			&:hover {
				animation: shake;
				animation-duration: 1s;
				cursor: pointer;
			}

			@media screen and (max-width : 1250px) {
				transform: none;
			}

			&>.floating-squares {
				transform: translate(-16px, 26px);

				&>img {
					max-width: 210px;
					width: 10.3vw;
				}
			}

			&>.presentor {
				&>img {
					max-width: 609px;
					width: 30vw;
				}
			}

			@include respond-to("medium-handhelds") {
				&>.floating-squares {
					display: none;
				}

				&>.presentor {
					&>img {
						width: 100%;
					}
				}
			}
		}

		&>.SupervisionAndTerms {
			align-items: center;
			display: flex;
			gap: 13px;
			justify-content: flex-end;
			padding-inline-end: 20px;

			@include respond-to("medium-handhelds") {
				flex-direction: column;
				padding-inline-end: 0;
			}

			&>.image-container {
				align-items: center;
				display: flex;

				&>img {
					max-width: 184px;
					width: 12vw;

					@include respond-to("medium-handhelds") {
						max-width: 146px;
						width: auto;
					}
				}
			}

			&>.terms-notice {
				color: #064F99;
				font-size: clamp(10px, 1vw, 16px);
				min-width: max-content;
				text-align: center;
			}
		}
	}
}

@keyframes slideFromRight {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes slideFromLeftIndent {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(90px);
	}
}

@keyframes shake {
	0% {
		transform: rotate(0);
	}

	10% {
		transform: rotate(-15deg);
	}

	50% {
		transform: rotate(15deg);
	}

	100% {
		transform: rotate(0);
	}
}