@import "../../../css/all";

.TenantPage {

  color: #00509E;

  margin-top : -20px;

  p {
	font-size : 18px;
	}

  .TestimonialsStrip {
	background-color: #F2F2F2 ;
	}

  }
