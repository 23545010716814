.benefits-section-wrapper {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;

    @media (width < 1000px) {
        grid-column: span 1;
    }

    &>.benefits-section-container {
        &>.item-lists-container {
            display: flex;
            gap: 140px;
            height: 100%;

            &>* {
                flex: 1;

                &>.item-list-list-container {
                    gap: 20px;
                }

            }

            @media (width < 1000px) {
                flex-direction: column;
                gap: 20px;
            }
        }
    }

    &>p {
        font-weight: 700;
        max-width: 750px;
    }
}