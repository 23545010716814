@import "../../../../../../css/all";

.ImgAndTextStrip {
  width           : 100%;
  display         : flex;
  flex-flow       : row;
  justify-content : space-evenly;
  margin-bottom   : 60px;
  margin-top      : 20px;

  @include respond-to("medium-handhelds") {
	margin-top : 0;
	flex-flow  : column;
	}

  .leftArrow {
	width  : 14px;
	margin : 0 auto;
	@include respond-to("medium-handhelds") {
	  margin-bottom : 30px;
	  margin-top    : 30px;
	  }
	}

  .imgAndTextBox {
	width         : 100%;
	display       : flex;
	align-content : center;
	text-align    : center;
	flex-flow     : column;
	font-size     : calculateRem(20);

	p {
	  width     : 100%;
	  margin    : 0 auto;
	  max-width : 210px;
	  font-size : calculateRem(20);
	  @include respond-to("medium-handhelds") {
		font-size : calculateRem(16);
		}
	  }

	.caption {
	  max-width : unset;
	  }

	img {
	  margin : 0 auto 10px;
	  width  : 50px;
	  height : 50px;
	  }
	}

  }