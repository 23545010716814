.month-year-container {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    font-size: var(--font-size-md);
    font-weight: 700;
    color: var(--light-blue-text);

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &>.month-year {
        display: flex;
        justify-content: center;
        min-width: 100%;

        transition: opacity 0.5s ease-in-out;

        &:not(.selected) {
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
        }

        @media (width < 1000px) {
            font-size: 16px;
        }
    }
}