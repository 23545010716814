@import "../../../../../components/product-pages/animations.scss";

.fourth-section-container {
    &>.product-pages-image-container {
        justify-content: center;

        &>img {
            max-width: 480px;
        }
    }

    &.in-view {
        &>.product-pages-image-container {
            animation: slideFromLeft 1s;
        }
    }


    &>.cta-container {
        &>* {
            opacity: 0;
        }


        &.cta-in-view {
            &>h3 {
                animation: slideFromLeft 0.5s, changeOpacity 0.2s;
                animation-fill-mode: forwards;
            }

            &>.cta-button-container {
                animation: slideFromRight 0.5s, changeOpacity 0.2s;
                animation-fill-mode: forwards;

            }
        }
    }
}