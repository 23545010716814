@import "../../css/all";

.CSDrop {
  direction   : rtl;
  display     : block;
  position    : relative;
  user-select : none;
  width       : 100%;

  &--focus {
	span {
	  border-color : #FF9FA3;
	  box-shadow   : inset 0 0 0 calculateRem(2) #FF9FA3;
	  font-weight  : 600;
	  }
	}

  span {
	align-items     : center;
	border          : solid calculateRem(1) #DBDBDB;
	border-radius   : calculateRem(5);
	color           : $darkBlue;
	cursor          : pointer;
	display         : flex;
	font-size       : calculateRem(20);
	justify-content : center;
	margin-bottom   : calculateRem(9);
	outline         : none;
	padding         : calculateRem(12) 0 calculateRem(12) 0;
	transition      : 0.2s;
	width           : 100%;

	.arrow {
	  border-left  : 0.4rem solid transparent;
	  border-right : 0.4rem solid transparent;
	  border-top   : 0.4rem solid $peacock-blue;
	  height       : 0;
	  margin-right : 0.4rem;
	  width        : 0;
	  }
	}

  .CSDrop__list {
	background-color : #FFFFFF;
	border           : calculateRem(1) solid #DBDBDB;
	font-size        : calculateRem(20);
	list-style-type  : none;
	overflow-y       : scroll;
	padding          : 0;
	position         : absolute;
	text-align       : center;
	width            : 100%;
	z-index          : 1000;

	li {
	  border-bottom : calculateRem(1) solid #DBDBDB;
	  cursor        : pointer;
	  padding       : calculateRem(12) 0;

	  &:last-child {
		border-bottom : 0;
		}

	  &:hover {
		background-color : rgba(237, 28, 36, 0.08);
		font-weight      : 700;
		}
	  }
	}

  &__list--hidden {
	@extend .CSDrop__list;
	display : none;
	}

  &__list--show {
	@extend .CSDrop__list;
	display    : block;
	margin-top : -10px;
	}
  }