.search-button {
    width: 200px;
    font-size: var(--font-size-lg);
    border: none;
    background-color: var(--light-blue);
    color: var(--white);
    border-radius: 5px;
    padding-block: 10px;
    display: flex;
    justify-content: center;
    margin-block-start: 20px;
    transition: background-color 0.1s ease-in-out;

    &.filter {
        margin-block-start: 10px;
    }

    &:hover  {
        &:not(:disabled) {
            cursor: pointer;
            background-color: var(--button-hover);
        }

    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    @media (width < 1000px) {
        font-size: 20px;
        width: 100%;
    }
}