.search-list-container {
    position: absolute;
    width: 100%;
    padding-inline: 28px;
    padding-block-end: 0;
    background-color: var(--white);
    font-size: var(--font-size-md);
    z-index: 0;
    border-radius: 0 0 10px 10px;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;

    &>.single-list-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding-block: 4px;
        transition: background-color 0.1s ease-in-out;

        &:not(:last-child) {
            border-bottom: 2px solid var(--gray);
        }

        &:hover {
            font-weight: 600;
            cursor: pointer;
        }

        @media (width < 1000px) {
            font-size: 16px;
            padding-block: 6px;
        }
    }

    &.is-open {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out, padding-block-end 0.3s ease-in-out;
        pointer-events: all;
        z-index: 1;

        &:has(.single-list-item) {
            padding-block-end: 10px;
        }
    }
}