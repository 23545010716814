.personal-area-drawer-contact-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > h4 {
    font-size: 18px;
    font-weight: 600;
  }

  @media (width < 1000px) {
    gap: 10px;

    & > h4 {
      font-size: 16px;
    }
  }
}
