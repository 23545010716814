@import "../../../../../components/product-pages/animations.scss";

.aia-third-section-container {
    &>.item-list-container {
        grid-column: span 2;
        transform: translateX(100%);

        &>.item-list-list-container {
            display: grid;
            gap: 26px 140px;
            grid-template-columns: 1fr 1fr;

            @media (width < 1000px) {
                grid-template-columns: 1fr;
                & > * {
                    &:nth-child(2) {
                        grid-area: 5;
                    }
                    &:nth-child(6) {
                        grid-area: 7;
                    }
                    &:nth-child(4) {
                        grid-area: 6;
                    }
                    &:nth-child(5) {
                        grid-area: 3;
                    }
                }

            }
        }

    }

    &.in-view {
        &>.item-list-container {
            animation: slideFromRight 1s;
            animation-fill-mode: forwards;

        }
    }

    &>.cta-button-container {
        display: flex;
        justify-content: center;
        grid-column: span 2;
    }
}