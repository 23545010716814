.line-with-icon-container {
  display: flex;
  align-items: center;
  gap: 20px;

  & > .icon-container {
    display: flex;
  }

  & > .text-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
