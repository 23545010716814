.tooltip-container {
  box-shadow: 0 3px 20px #00000029;
  pointer-events: auto !important;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: auto !important;

  &:hover {
    visibility: visible !important;
  }

  & > .links-tooltip-container {
    display: flex;
    padding-inline: 1vw;
    padding-block: 6px;
    gap: 20px;

    .links-column {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > a {
        text-decoration: none;
        font-size: clamp(16px, 1.1vw, 20px);
        color: #1D3557;
      }
    }
  }
}
