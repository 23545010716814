.personal-details-drawer-container {
  position: fixed;
  right: 0;
  top: 0;
  height: 100dvh;
  z-index: 100;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  pointer-events: none;

  & > .personal-details-drawer-content {
    transform: translateX(100%);
    height: 100%;
    background-color: var(--white);
    width: 550px;
    padding-block-start: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }

  &.is-open {
    background-color: #00000038;
    pointer-events: all;
    & > .personal-details-drawer-content {
      transform: translateX(0);
    }
  }

  @media (width < 1000px) {
    top: 0;

    & > .personal-details-drawer-content {
      width: 100%;
      padding-block-start: 18px;
    }

    &.is-open {
      background-color: transparent;
  
    }
  }
}
