.login-functional-section-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 35px;
  position: relative;
  overflow: hidden;
  padding-block-start: 130px;
  z-index: 3;
  min-height: calc(100vh - 90px);

  @media (max-width: 500px) {
    padding-block-start: 50px;
    padding-inline: 35px;
    min-height: 0;
  }

  & > .functional-section-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;
    width: 100%;
    z-index: 1;
    max-width: 500px;

    & > .foreign-resident-button {
      text-decoration: underline;
      font-weight: 600;
      color: #1d3557;
      background-color: transparent;
      border: none;
      font-size: 18px;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    & > .code-page-extra-info-container {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: hidden;
      animation: grow 1s ease-in-out forwards;

      & > .extra-buttons-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 500px) {
          gap: 4px;
        }
      }

      & > .checkboxes-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        @media (max-width: 500px) {
          gap: 10px;
        }
      }
    }
  }

  & > .section-image-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

    @media (max-width: 1000px) {
      display: none;
    }

    & > * {
      width: 100%;
      max-width: 900px;
    }
  }
  @keyframes grow {
    0% {
      max-height: 0;
    }

    100% {
      max-height: 2000px;
    }
  }
}
