.chat-button {
  width: 163px;
  height: 42px;
  background: #1d3557 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  border: unset;
  opacity: 1;
  position: relative;
  margin-left: calc(100% - 195px);

  .alert {
    position: absolute;
    top: -11px;
    left: -12px;
    width: 28px;
    padding: 3px;
    font-size: 16px;
    border-radius: 25px;
    color: white;
    text-align: center;
    height: 28px;
    background: #f24a51 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .chat-button-text {
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 21px;

    .ai {
      font-weight: bold;
    }
  }

  .chat-button-text-beta {
    top: 1px;
    right: 9px;
    font-weight: 700;
    font-size: 17px;
    position: absolute;
    transform: matrix(0.99, 0.1, -0.1, 0.99, 0, 0);
    text-align: center;
    letter-spacing: 0px;
    color: #1799fc;
    opacity: 1;
  }
}
