@import "../../config/config.scss";

.newsletter-container {
  --font-size-xs: clamp(10px, 0.9vw, 14px);
  --font-size-sm: clamp(12px, 0.9vw, 16px);
  --font-size-md: clamp(16px, 1.1vw, 20px);
  --font-size-lg: clamp(20px, 1.3vw, 24px);
  --font-size-xl: clamp(20px, 2.7vw, 50px);

  --gap-xxs: clamp(2px, 0.4vw, 4px);
  --gap-xs: clamp(5px, 0.55vw, 10px);
  --gap-sm: clamp(8px, 0.9vw, 18px);
  --gap-md: clamp(10px, 1.1vw, 20px);
  --gap-md-2: clamp(10px, 1.2vw, 25px);
  --gap-lg: clamp(10px, 1.6vw, 30px);
  --gap-xl: clamp(10px, 2.2vw, 40px);

  --gray: #f2f2f2;
  --gray-opacity: #f2f2f258;
  --gray-border: #707070;
  --white: #fff;
  --white-opacity: #ffffffe9;
  --light-blue: #1799fc;
  --light-blue-text: #2999fc;
  --dark-blue: #1d3557;
  --dark-blue-opacity: #1d3557e9;
  --green: #75c74e;
  --red: #f24a51;

  --button-hover: #0389ef;

  --shadow: #00000033;

  --close-button: url("#{$s3ImagesLink}/marketing/newsletter/icons/close.svg");

  --container-heading-lg: clamp(24px, 6vw, 50px);
  --container-paragraph: clamp(16px, 1.5vw, 20px);

  width: 100%;
  max-width: 100vw;
  padding-inline-start: 60px;
  padding-inline-end: clamp(10px, 3.1vw, 60px);
  padding-block-start: 10px;
  padding-block-end: min(40px, 4vh);
  height: calc(100dvh - 92px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.2s linear, height 0.2s linear;

  @media (width < 1000px) {
    padding-block-end: unset;
  }

  &:has(.pop-up-container:is(.is-open)) {
    & > *:not(.popup-container) {
      pointer-events: none;
    }
  }

  &.intro-visible {
    & > * {
      &:not(.intro-and-disclaimer-container, .map-container) {
        opacity: 0;
      }
      &:not(.intro-and-disclaimer-container) {
        pointer-events: none;
      }
    }
  }

  @media (width > 1000px) {
    &.data-view-visible {
      height: 100vh;
      transform: translateY(-92px);
    }
  }

  & > .intro-and-disclaimer-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    display: flex;
    width: max-content;
    height: max-content;
    flex-direction: column;
    gap: var(--gap-sm);
    @media (width < 1000px) {
      width: calc(100% - 20px);
    }
  }

  & > .cards-and-chat-container {
    width: 365px;
    align-self: start;
    border-radius: 10px 0px 10px 10px;
    padding-right: 15px;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);
    z-index: 1;

    @media (width < 1000px) {
      position: initial;
      width: 100vw;
      align-self: unset;
      background-color: #fff;
      border-radius: 20px 20px 0px 0px;
    }

    & > .cta-button {
      border: none;
      font-size: 24px;
      background-color: var(--light-blue);
      color: var(--white);
      padding-inline: 20px;
      padding-block: 10px;
      margin-block-end: 5px;
      border-radius: 5px;
      transition: background-color 0.1s ease-in-out;
      text-decoration: none;
      font-size: var(--font-size-lg);
      transition: opacity 0.2s ease-in-out;
      width: calc(100% - 20px) !important;
      max-width: calc(100vw - 20px) !important;
      cursor: pointer;

      @media (width < 1000px) {
        font-size: 18px;
      }
    }
  }

  @media (width < 1000px) {
    height: calc(100dvh - 76px);
    padding-inline: 10px;
  }

  & > .search-interface {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);

    @media (width > 1000px) {
      &.reverse {
        flex-direction: column-reverse;
      }
    }
  }
}

.accessibility_component,
.accessibility_div_wrap {
  display: none !important;
}

body {
  overflow-y: hidden;
}
