@import "../variables.scss";

.disclaimer-text {
    font-size: $disclaimer-font;
    padding-inline: $section-padding-inline;
    color: $base-color;
    background-color: $main-background-color;
    padding-block-end: 10px;
    &:is(:nth-child(even)) {
        background-color: $alternate-background-color;
    }
}