@import "../../../../../../../../css/all";

.SuccessSlide {
  display : flex;

  h4 {
	font-size : calculateRem(26);
	}

  p {
	margin : 0 auto;
	width  : 391px;
	@include respond-to("medium-handhelds") {
	  width : unset;
	  }
	}

  img {
	margin : 0 auto 20px;
	width  : 135px;
	height : 209px;
	@include respond-to("medium-handhelds") {
	  width  : 82px;
	  height : 125px;
	  }
	}

  .buttonDiv {
	margin-top : 40px;
	@include respond-to("medium-handhelds") {
	  margin-top : 20px;
	  }

	a {
	  margin    : 0 auto;
	  width     : 360px;
	  font-size : calculateRem(24);
	  height    : 64px;
	  @include respond-to("medium-handhelds") {
		width     : 100%;
		margin    : 0 auto;
		font-size : calculateRem(20);
		height    : 50px;
		}
	  }

	}

  }