.display-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  & > .accordion-container {
    padding-inline: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;

    &.is-open {
      max-height: 400px;
      padding-block-end: 0;
    }
  }
 
}
