.room-list-container {
    &.multi-select {
        &>.single-room {
            &.selected {
                transform: none;
            }
        }
    }
}

.room-select-container {
    &.multi-select {
        & > h4 {
            font-weight: 500;
        }
    }
}