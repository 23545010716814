@import "../../../../../components/product-pages/animations.scss";

.third-section-container {

    &>.item-list-container,
    &>.left-section-container .text-section-container {
        transform: translateX(100%);
    }

    &.in-view {

        &>.item-list-container,
        &>.left-section-container .text-section-container {
            animation: slideFromRight 1s;
            animation-fill-mode: forwards;
        }
    }

    &>.cta-container {
        opacity: 0;

        &.cta-in-view {
            animation: changeOpacity 0.5s;
            animation-fill-mode: forwards;
        }
    }
   
}