@import "../../../variables.scss";

.submit-button-mort-contact {
    background-color: $button-background-color;
    color: $secondary-color;
    font-size: $button-font;
    font-weight: 600;
    max-width: fit-content;
    text-decoration: none;
    padding: 14px 44px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    grid-column: span 2;
    padding-inline: 95px;
    height: 60px;
    min-width: 250px;
    border: none;
    position: relative;

    &:hover {
        &:not(:disabled) {
            background-color: $button-hover-color;
            cursor: pointer;
        }
    }

    &:is(:disabled) {
        opacity: 0.5;
    }

    @media screen and (max-width: 1000px) {
        max-width: none;
        width: 100%;
        text-align: center;
    }
}