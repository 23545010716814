.cta-animation-click-container {
    position: relative;
 

    &>.animation-container {
        z-index: 1;
        position: absolute;
        right: -20px;
        bottom: -30px;
        pointer-events: none;

        &>* {
            max-width: 70px;
        }

    }

    &>.cta-container {
        align-items: flex-start;
        gap: 25px;

        

        &>.animate-button {
            animation: growFlicker 1s;
            animation-delay: 1.5s;
        }
    }
}