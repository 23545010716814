.aia-first-section-container {
    &>.right-side-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    &>.aia-first-section-image {
        justify-content: center;

        &>img {
            max-width: 540px;
        }
    }
}