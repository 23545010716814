.modal-cancel-button {
  border: none;
  font-size: 20px;
  text-decoration: underline;
  color: var(--color-secondary);

  &:hover {
    cursor: pointer;
  }
  
  @media (width < 600px) {
    font-size: 16px;
  }
}
