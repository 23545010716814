@import "../../../../../css/all.scss";

.TestimonialsHeader {
  h1,
  h2 {
	@include respond-to("handhelds") {
	  width : 100%;
	  }
	}

  .row {
	padding : 0 calculateRem(25);
	}
  }

.TestimonialsVideoSection {
  .ribbon {
	background : #3D5A80;
	color      : white;
	font-size  : calc(15px + 1vw);
	padding    : 1rem 5rem;
	position   : absolute;
	top        : 0;
	transform  : rotate(45deg) translate(30%, -20px);
	}

  .ratingText {
	color       : #00509E;
	font-size   : calculateRem(25);
	font-weight : 600;
	text-align  : center;
	@include respond-to("handhelds") {
	  font-size : calculateRem(17);
	  }
	}

  .ratingStars {
	align-items : center;
	display     : flex;
	padding     : 1rem;

	.starSmall {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 1rem;
	  min-width           : 1rem;
	  }

	.starMedium {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 1.5rem;
	  min-width           : 1.5rem;
	  }

	.starLarge {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 2rem;
	  min-width           : 2rem;
	  }
	}

  .colInner {
	max-width : 600px;
	padding   : 20px calc(4vw + 20px);
	}

  .btn--azure.medium {
	border-radius : 200px;
	font-size     : calculateRem(20);
	font-weight   : 500;
	padding       : calculateRem(5) calculateRem(20);
	@include respond-to("handhelds") {
	  font-size : calculateRem(17);
	  }
	}

  .grayBackground {
	background : #EFEFEF;
	@include respond-to("handhelds") {
	  background : unset;
	  }
	}
  }
