.filter-container {
  width: clamp(330px, 21.1vw, 404px);
  align-self: flex-end;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;

  @media (width > 1000px) {
    &.fade-out {
      opacity: 0;
    }
  }

  .search-button-wrapper {
    background-color: var(--gray);
    width: 100%;
    max-width: calc(100vw - 20px) !important;
    padding-block: 10px;
    padding-inline: 20px;
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    .cta-button {
      text-align: center;
      align-self: center;
      border: none;
      font-size: 24px;
      background-color: var(--light-blue);
      color: var(--white);
      padding-inline: 20px;
      padding-block: 10px;
      border-radius: 5px;
      width: 100%;
      min-width: 100%;
      transition: background-color 0.1s ease-in-out;
      text-decoration: none;
      font-size: var(--font-size-lg);
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
      text-align: center;
    }
  }

  & > .overlay-buttons {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    padding-block-start: 18px;
    padding-inline: 14px;
    pointer-events: none;

    &.is-open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.is-open {
    background-color: var(--gray);
    overflow: visible;
    display: unset;
  }

  & > .filter-accordion-content {
    gap: var(--gap-md);
    padding-inline: clamp(16px, 1.2vw, 25px);
    @media (width < 1000px) {
      padding-inline: 26px;
    }
  }

  & > .filter-search-button-container {
    width: 100%;
    border-radius: 5px;
    background-color: var(--white);
    font-size: var(--font-size-lg);
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .filter-title-container {
    padding-block: clamp(10px, 1vw, 18px);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: max-content;
    border-radius: 5px;
    background-color: var(--white);
    width: 70px;

    @media (width < 1000px) {
      border-radius: 10px;
      background-color: var(--white);
      width: unset;
    }

    &.is-open {
      pointer-events: none;
      background-color: unset;
      width: unset;
    }

    &:hover {
      &:not(.is-open) {
        cursor: pointer;
      }
    }

    & > h3 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      text-align: center;
      transform: translateX(-12px);
      min-width: max-content;
      transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
    }

    & > .icon-container {
      max-width: 15px;
      transition: opacity 0.2s ease-in-out;
    }

    &.is-open {
      & > h3 {
        transform: translateX(0);
      }

      & > .icon-container {
        opacity: 0;
      }
    }
  }
  &:not(.is-open) {
    & > .filter-title-container {
      &.is-filtered {
        // background-color: var(--light-blue);
      }
    }
    @media (width < 1000px) {
      & > .filter-title-container {
        & > h3 {
          font-size: 32px;
        }
        & > .icon-container {
          max-width: 25px;
        }
      }
    }
  }
  @media (width < 1000px) {
    transform: scale(0.5);
    position: absolute;
    left: 10px;
    top: 10px;
    transition: transform 0.2s linear;
    overflow: hidden;
    transform-origin: top left;
    width: calc(100% - 290px);
    max-width: 70px;
    display: unset;
    flex-direction: unset;

    &.is-open {
      transform: scale(1);
      width: clamp(330px, 21.1vw, 404px);
      max-width: clamp(330px, 21.1vw, 404px);
    }

    & > .filter-title-container {
      padding-inline: 15px;
      justify-content: center;
      padding-block: 21px;
    }
  }
}
