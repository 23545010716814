.login-hide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
  height: 100%;
  gap: var(--gap-md);
  background-color: var(--gray-opacity);
  transition: max-height 0.2s linear;
  max-height: 100%;

  @media (width < 1000px) {
    padding-block-end: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.less-spacing {
    gap: var(--gap-sm);
    max-height: 200px;
    justify-self: flex-end;
  }

  & > .animation-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (width < 1000px) {
      margin-block: auto;
    }

    & > .bell-animation {
      transition: opacity 0.2s ease;

      & > * {
        width: clamp(20px, 1.8vw, 36px);
      }

      opacity: 0;

      &.show-animation {
        opacity: 1;
      }
    }

    & > .login-hide-paragraph {
      font-size: var(--font-size-sm);

      & > span {
        font-weight: 700;
      }

      @media (width < 1000px) {
        white-space: pre-wrap;
        font-size: 14px;
      }
    }
  }
}
