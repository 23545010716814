.year-select-container {
  background-color: var(--white);
  height: clamp(40px, 3vw, 50px);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 15px;
  max-width: 100%;

  & > button[disabled] {
    opacity: 0.5;
  }

  & > .arrow-left,
  & > .arrow-right {
    border: none;
    background-color: transparent;
    display: flex;
    padding: 0;
    transition: opacity 0.3s ease-in-out;

    & > img {
      width: 25px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & > .arrow-left {
    & > img {
      transform: rotate(180deg);
    }
  }
}
