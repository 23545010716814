.loan-paid-container {
  background-color: var(--white-opacity);
  color: var(--light-blue-text);
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  & > p {
    font-size: 22px;
    font-weight: 700;
    transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    background-color: transparent;

    & > p {
      transform: translateY(130%);
      font-size: 18px;
    }
  }
}
