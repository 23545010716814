@import "../../../variables.scss";

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 6px;
    &:hover {
        cursor: pointer;
    }
    & > .checkbox {
        width: 13px;
        height: 13px;
        border: 1px solid $base-color;
        border-radius: 50%;
        &.checked {
            background-color: $base-color;
        }
    }
}