@import "../../../../../css/all";

.ThePressSection {
  display   : flex;
  flex-flow : column;

  h1 {
	color       : $weCheckBlue;
	font-size   : 45px;
	font-weight : bold;
	text-align  : center;
	}

  .pressImages {
	display           : flex;
	flex-flow         : row;
	overflow-x        : scroll;
	overflow-y        : hidden;
	-khtml-user-drag  : none;
	-moz-user-drag    : none;
	-o-user-drag      : none;
	-webkit-user-drag : none;

	@include respond-to("handhelds") {
	  height : 300px;
	  }

	a {
	  -khtml-user-drag  : none;
	  -moz-user-drag    : none;
	  -o-user-drag      : none;
	  -webkit-user-drag : none;
	  }

	img {
	  cursor            : pointer !important;
	  height            : 345px;
	  -khtml-user-drag  : none;
	  -moz-user-drag    : none;
	  -o-user-drag      : none;
	  -webkit-user-drag : none;
	  @include respond-to("handhelds") {
		height : 300px;
		}
	  }
	}
  }
