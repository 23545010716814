.top-section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>.text-section {
        display: flex;
        flex-direction: column;
        gap: 18px;

        &>h1 {
            font-size: clamp(46px, 4.4vw, 90px);
            font-weight: 700;
            line-height: 100%;
            min-width: max-content;

            &::first-line {
                font-weight: 500;
            }
            
        }

        &>h2 {
            font-size: clamp(17px, 1.2vw, 24px);
            padding-inline: min(24px, 1.6vw);
            padding-block: 20px;
            box-shadow: 0 0 20px #0098FF73;
            min-width: max-content;
            max-width: max-content;
            border-radius: 9px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 570px) {
                min-width: 0;
                display: block;
                padding-block: 10px;
                text-align: center;
                padding-inline: 12px;
            }

            &>span {
                color: #0098FF;
                font-size: clamp(31px, 2.1vw, 42px);
                font-weight: 700;
                min-width: max-content;
            }

        }

        &>p {
            font-size: clamp(17px, 1vw, 20px);
            white-space: pre-wrap;
            max-width: 680px;
        }
    }

    &>.image-section {


        &>img {
            width: min(686px, 34vw);

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }
    }
}