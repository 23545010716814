@import "../../../../../../../../css/all";

.AssetDetailsSlide {
  display : flex;
  @include respond-to("medium-handhelds") {
	width : 100%;
	}

  .btn--gray {
	pointer-events : none;
	background: $weCheckAzure;
	opacity: 50%;
	}


  .buttonDiv {
	margin-top : 40px;
	@include respond-to("medium-handhelds") {
	  margin-top : 60px;
	  }

	a {
	  margin    : 0 auto;
	  width     : 365px;
	  font-size : calculateRem(24);
	  height    : 64px;
	  @include respond-to("medium-handhelds") {
		width     : 100%;
		margin    : 0 auto;
		font-size : calculateRem(20);
		height    : 50px;
		}
	  }

	}
  }