.animated-steps-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow-y: clip;
    padding-block-start: 130px;

    @media (max-width: 500px) {
        padding-block-start: 50px;
        padding-inline: 35px;
        overflow-y: visible;
    }

    &>.half-circle {
        position: absolute;
        width: 1230px;
        height: 1230px;
        top: -140px;
        right: 0;
        background-color: #f6f6f6;
        border-radius: 50%;
        z-index: 1;

        &:nth-child(2) {
            width: 1080px;
            height: 1080px;
            background: linear-gradient(to left, #f9f9f9 0%, #f2f2f2 80%);
            top: -50px;
            z-index: 2;
            right: 120px;
            box-shadow: 10px 0px 10px 20px #f4f4f45d;

        }

        @media (max-width: 1000px) {
            top: auto;
            bottom: -50%;
            left: auto;
            right: auto;

            &:nth-child(2) {
                top: auto;
                left: auto;
                right: auto;
                box-shadow: 0px -10px 10px 20px #f4f4f45d;
                background: linear-gradient(to bottom, #f9f9f9 0%, #f2f2f2 80%);

            }

        }

        @media (max-width: 500px) {
            bottom: -550px;

            &:nth-child(2) {
                bottom: -500px;

            }
        }
    }

    &>.item-row-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding-block-end: 130px;
        position: relative;
        max-width: max-content;
        max-height: max-content;
        z-index: 3;

        @media (max-width: 500px) {
            padding-block-end: 50px;
            max-width: 100%;
        }

        &>.step-animation-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
            pointer-events: none;

            @media (max-width: 500px) {
                display: none;
            }

            &>.animation-container {
                position: absolute;
                top: 25%;
                width: 70px;
                left: -70px;
                top: 50%;

                &:first-child {
                    right: -70px;
                    top: 25%;
                }
            }

        }

        &>h3 {
            font-size: 24px;
            text-align: center;
            white-space: pre-wrap;
            color: #1799FC;
            font-weight: 500;

            &::first-line {
                font-weight: bold;
            }

        }

        &>.item-row-list-container {
            display: flex;
            flex-direction: column;
            gap: 50px;

            &>.login-single-item-container {
                &:not(:first-child) {
                    opacity: 0;
                    animation: slideFromTop 0.5s, changeOpacity 0.5s;
                    animation-fill-mode: forwards;
                }
            }
        }

        &>* {
            flex: 1;
        }

        @container (width < 1000px) {
            flex-direction: column;
            gap: 80px;
        }
    }
}

@keyframes changeOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFromTop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}