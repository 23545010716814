@import "../../../../css/all";

.TermsSection {
  color           : $weCheckBlue;
  justify-content : center;

  justify-items   : center;
  margin-left     : calculateRem(70);
  text-align      : center;
  @include respond-to("handhelds") {
	margin-left : 0;
	margin-top  : calculateRem(25);
	}

  .bricksImage {
	display : none;
	@include respond-to("handhelds") {
	  display : unset;
	  width   : 100%;
	  }
	}

  .grayBox {
	align-items      : center;
	background-color : $mortGray;
	display          : flex;
	flex-flow        : row;
	justify-items    : center;
	margin           : calculateRem(10);
	padding          : calculateRem(15);

	h5 {
	  margin-right : calculateRem(10);
	  }

	img {
	  width : calculateRem(40);
	  }
	}
  }

.TermsAndVideo {
  background : url("#{$s3ImagesLink}/dashboard/mortgage/briksline.svg") bottom repeat-x;
  margin     : 0 auto;
  width      : 90%;

  @include respond-to("handhelds") {
	background : none !important;
	padding    : 5px;
	width      : 100%;
	}
  }
