.open-details-button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #064f99;
  font-weight: 600;
  gap: 10px;
  & > img {
    width: 43px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (width < 1000px) {
    font-size: 14px;
    min-width: max-content;
    & > img {
      width: 24px;
    }
  }
}
