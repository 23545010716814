@import "../../../../../css/all";

.WhoWeAre {
  align-items     : center;
  color           : #284780;
  display         : flex;
  justify-content : space-between;
  padding-block   : 60px;
  padding-inline  : 8vw;
  @include respond-to("handhelds") {
	flex-direction : column;
	gap            : 40px;
	padding-inline : 6vw;
	}

  & > .right-side-container {
	display        : flex;
	flex-direction : column;
	gap            : 20px;

	&.in-view {
	  animation : slideFromRight 1s;
	  }

	& > h2 {
	  font-size   : clamp(30px, 3.2vw, 64px);
	  font-weight : 800;
	  }

	& > .content-container {
	  display        : flex;
	  flex-direction : column;
	  gap            : 24px;

	  & > .paragraph-container {
		display        : flex;
		flex-direction : column;
		@include respond-to("handhelds") {
		  & > br {
			display : none;
			}
		  }

		& > h3 {
		  color       : #ED1C24;
		  display     : flex;
		  font-size   : clamp(16px, 1.5vw, 30px);
		  font-weight : 700;
		  gap         : 2px;

		  & > img {
			width : min(150px, 7.4vw);
			@include respond-to("handhelds") {
			  width : 75px;
			  }
			}
		  }

		& > p {
		  font-size   : clamp(16px, 1.3vw, 26px);
		  font-weight : 600;
		  @include respond-to("handhelds") {
			& > br {
			  display : none;
			  }
			}
		  }
		}
	  }
	}

  & > .left-side-container {
	align-items    : center;
	display        : flex;
	flex-direction : column;
	gap            : 40px;

	&.in-view {
	  animation : slideFromLeft 1s;
	  }

	& > h4 {
	  font-size   : clamp(22px, 2.6vw, 53px);
	  font-weight : 800;
	  }

	& > .image-container {
	  & > img {
		width : min(600px, 30vw);
		@include respond-to("handhelds") {
		  width : 100%;
		  }
		}
	  }
	}
  }
