@import "../../../../../css/all";

.ChooseSection {
  width : 100%;

  .content {
	align-items       : center;
	display           : flex;
	flex-flow         : column;
	margin            : 0 auto;
	padding-block-end : 20px;
	width             : 800px;

	@include respond-to("handhelds") {
	  width : 100% !important;
	  }
	}

  h3 {
	color       : $weCheckBlue;
	font-size   : calculateRem(30);
	font-weight : bold;
	@include respond-to("handhelds") {
	  font-size : calculateRem(22);
	  }
	}

  .lowerDesc {
	margin-top : calculateRem(15);
	text-align : center;
	width      : calculateRem(500);
	@include respond-to("handhelds") {
	  margin-bottom : 0 !important;
	  width         : 90%;
	  }
	}

  .bricksImage {
	height     : calculateRem(25);
	margin-top : calculateRem(30);
	@include respond-to("handhelds") {
	  height : unset !important;
	  width  : 100%;
	  }
	}
  }
