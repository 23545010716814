.error-message {
    color: #F24A51;
}

.phone-input {
    font-weight: 600;
    width: 100%;
    max-width: 500px;
    font-size: 24px;
    padding-inline: 24px;
    height: 70px;
    border: none;
    border-radius: 10px;
    direction: ltr;
    text-align: center;
    outline: 2px solid #fff !important;
    transition: outline 0.1s ease-in-out;

    @media (max-width: 500px) {
        font-size: 16px;
        height: 50px;
    }

    &::placeholder {
        color: #064F99;
        font-size: 24px;
        opacity: 0.5;
        @media (max-width: 500px) {
            font-size: 16px;
        }
    }

    &:hover,
    &:focus {
        outline: 2px solid #e7e7e7 !important;
    }
}

label {
    width: 100%;
}