.products-section-wrapper {
  position: relative;
  --background: #f2f2f2;
  --font-color: #1d3557;
  --font-secondary-color: #1799fc;

  --font-size-md: clamp(16px, 1.1vw, 20px);
  --font-size-lg: clamp(20px, 1.3vw, 24px);
  --font-size-xl: clamp(24px, 1.8vw, 36px);
  --font-size-xl2: clamp(30px, 2.7vw, 50px);

  --gap-xs: clamp(5px, 0.55vw, 10px);
  --gap-lg: clamp(10px, 1.6vw, 30px);
  --gap-md-2: clamp(10px, 1.2vw, 25px);

  --card-background: #fff;

  & > h2 {
    font-size: var(--font-size-xl2);
    font-weight: 700;
    color: var(--font-color);
    background-color: var(--background);
    width: 100%;
    text-align: center;
    padding-block-start: 30px;
  }
  & > .products-section-container {
    color: var(--font-color);
    background-color: var(--background);
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-md-2);

    @media (width < 1000px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
