.MortgageContactFormContainer {
    box-shadow: 0 3px 20px #00000029;
    border-radius: 10px;
    padding-block: 30px;
    padding-inline: min(90px, 4.4vw);
    z-index: 999;
    background-color: #FFFFFF;
    width: calc(100vw - 16vw);
    bottom: calc(50% - 500px);
    transition: border-radius 0.6s ease, width 0.3s ease, padding 0.4s ease, bottom 0.4s ease;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1400px) {
        padding-inline: 45px;
        gap: 20px;
        width: 100%;
    }

    &>.form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;

        &>button {
            border: none;
            background-color: transparent;
            position: absolute;
            right: 2vh;
            top: 2vh;

            &:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: 1400px) {
            gap: 20px;
            max-height: 570px;
            transition: max-height 0.6s ease;

            &>button {
                &.display-none {
                    display: none;
                }
            }

        }

        &>h3 {
            font-size: clamp(30px, 1.8vw, 36px);
            color: #284780;
            font-weight: 700;
            transition: opacity 0.5s ease;

            @media screen and (max-width: 1400px) {
                text-align: center;
            }

            &.hidden {
                opacity: 0;
            }
        }

        &>.contact-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 60px;
            width: 100%;
            transition: opacity 0.5s ease;

            &.hidden {
                opacity: 0;
            }

            @media screen and (max-width: 1400px) {
                gap: 30px;
            }

            &>.form-inputs {
                display: flex;
                gap: 40px;
                width: 100%;

                @media screen and (max-width: 1400px) {
                    flex-direction: column;
                }

                &>.form-input {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    flex: 1;

                    &>input,
                    &>select {
                        font-size: clamp(17px, 1vw, 20px);
                        border: none;
                        border-bottom: 2px solid #00509E;
                        color: #00509E;
                        padding-block: 4px;

                        &::placeholder {
                            color: #00509E;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    &>.error {
                        font-size: clamp(14px, 1vw, 20px);
                        position: absolute;
                        bottom: -25px;
                        min-width: max-content;
                        color: #EE4D4D;
                        @media screen and (max-width: 570px) {
                        bottom: -20px;
                        }
                    }
                }
            }

            &>button {
                border: none;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                padding-inline: 60px;
                padding-block: 16px;
                background-color: #1799FC;
                color: #FFFFFF;
                transition: background-color 0.1s ease;
                width: 152px;
                height: 60px;
                position: relative;
                z-index: 4;

                &:hover {
                    cursor: pointer;
                    background-color: #007FD4;
                }

                &:disabled {
                    opacity: 0.5;

                    &:hover {
                        cursor: auto;
                        background-color: #1799FC;
                    }
                }
            }

        }
    }

    &.fixed-position,
    &.absolute-position {
        position: fixed;
        bottom: 0;
        width: 100vw;
        border-radius: 40px 40px 0 0;
        gap: 30px;
        transition: gap 0.6s ease, width 0.3s ease, bottom 0.6s ease;
        &>.form-content {

            &>.contact-form {
                gap: 40px;
                transition: gap 0.6s ease;

            }
        }

        @media screen and (max-width: 1400px) {
            width: calc(100vw - 36px);
            bottom: 18px;
            border-radius: 10px;
            transition: gap 0.6s ease, max-height 0.6s ease, padding 0.3s ease;

            &.closed {
                padding: 20px;

            }

            &>.form-content {
                overflow: hidden;

                &.open {
                    &>button {
                        border: none;
                        background-color: transparent;
                        position: absolute;
                        right: 0;
                        top: 1vh;
                    }
                }

                &.closed {
                    max-height: 0;
                }

            }

            &>.mobile-form-not-open {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &>h3 {
                    font-size: clamp(17px, 2vw, 40px);
                    font-weight: 800;
                    flex-basis: 60%;
                }

                &>button {
                    font-size: clamp(17px, 2vw, 40px);
                    border: none;
                    background-color: #1799FC;
                    border-radius: 10px;
                    color: #FFFFFF;
                    padding: 10px;
                }
            }

        }
    }

    &.absolute-position {
        position: absolute;
    }
}

@keyframes fadeOutIn {
    0% {
        opacity: 1;
        position: relative;
    }
    10% {
        opacity: 0;
        position: fixed;
    }
    100% {
        opacity: 1;
    }

}