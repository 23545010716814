.additional-mort-fourth-section {
    &>.item-row-container {
        grid-column: span 2;

        @media (width < 1000px) {
            grid-column: span 1;
        }
    }

    &>.cta-button {
        height: fit-content;
        align-self: center;
        justify-self: center;
    }

    @container (width < 1000px) {
        & > .item-row-container {
            & > .item-row-list-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 80px 20px;
            }
        }
    }
}