.card-container {
  background: var(--card-background);
  border-radius: 15px;
  padding-inline: 45px;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 46px;

  & > .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--gap-xs);

    & > .card-icon-container {
        max-width: min(70px, 30%);
    }

    & > .card-title-container {
      & > h3 {
        font-size: var(--font-size-xl);
        font-weight: 600;
      }
    }

    & > .card-secondary-title-container {
      color: var(--font-secondary-color);
      & > h4 {
        font-size: var(--font-size-lg);
        font-weight: 600;
      }
    }

    & > .card-paragraph-container {
      & > p {
        font-size: var(--font-size-md);
        white-space: pre-wrap;
        & > span {
          font-weight: 600;
        }
      }
    }
  }

  & > .card-button-cta {
    display: flex;
    justify-content: center;
    color: var(--font-secondary-color);
    font-size: 20px;
    font-weight: 600;
    gap: 10px;
  }
}
