.login-title-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #064f99;

    &>h2 {
        font-size: 50px;
        font-weight: 700;

        @media (max-width: 500px) {
            font-size: 36px;

        }
    }

    &>h3 {
        font-size: 24px;
        font-weight: 500;

        @media (max-width: 500px) {
            font-size: 16px;

        }

    }
}