.contact-buttons-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-inline: 10%;

  & > .personal-area-contact {
    flex: 1;
    font-size: var(--font-size-sm2);
    border: none;
    color: white;
    background-color: #3c99fc;
    border-radius: 5px;
    padding-block: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: var(--button-hover);
    }
  }
}
