@import "../../../../../css/all";

.WhyWeCheck {
  display        : flex;
  flex-direction : column;
  gap            : 108px;
  padding-block  : 60px;
  padding-inline : 8vw;
  @include respond-to("handhelds") {
	gap            : 50px;
	padding-block  : 40px;
	padding-inline : 4vw;
	}

  & > .title-container {
	& > h2 {
	  color       : #284780;
	  display     : flex;
	  font-size   : clamp(29px, 3.4vw, 64px);
	  font-weight : 800;
	  gap         : 0.5vw;
	  @include respond-to("handhelds") {
		justify-content : center;
		}

	  & > img {
		max-width : 320px;
		width     : 17vw;
		@include respond-to("handhelds") {
		  width : 150px;
		  }
		}
	  }

	&.in-view {
	  animation : slideFromRight 1s;
	  }
	}

  & > .cards-grid-container {
	display               : grid;
	gap                   : 40px;
	grid-template-columns : repeat(4, minmax(0, 1fr));
	padding-inline        : min(20px, 1.2vw);
	@include respond-to("handhelds") {
	  column-gap            : 18px;
	  grid-auto-rows        : 1fr;
	  grid-template-columns : repeat(2, minmax(0, 1fr));
	  padding-inline        : 0;
	  row-gap               : 50px;
	  }

	&.in-view {
	  animation : slideFromLeft 1s;
	  @include respond-to("handhelds") {
		animation : none;
		& > .card-container {
		  &:nth-child(1),
		  &:nth-child(2) {
			animation : slideFromRight 1s;
			}

		  &:nth-child(3),
		  &:nth-child(4) {
			animation : slideFromLeft 1s;
			}
		  }
		}
	  }

	& > .card-container {
	  align-items      : center;
	  background-color : #F7F9FC;
	  border-radius    : 27px;
	  box-shadow       : 5px 7px 8px #00000029;
	  color            : #284780;
	  display          : flex;
	  flex-direction   : column;
	  padding-block    : 50px;
	  padding-inline   : min(55px, 2.8vw);
	  position         : relative;
	  @include respond-to("handhelds") {
		padding-block-end   : 20px;
		padding-block-start : 40px;
		}

	  & > img {
		position : absolute;
		right    : -0.9vw;
		top      : -40px;
		width    : 100px;
		@include respond-to("handhelds") {
		  left   : 0;
		  margin : 0 auto;
		  right  : 0;
		  top    : -20px;
		  width  : 50px;
		  }
		}

	  & > .card-content {
		display        : flex;
		flex-direction : column;
		gap            : 10px;
		text-align     : center;

		& > h3 {
		  font-size   : clamp(16px, 1.6vw, 30px);
		  font-weight : 700;
		  min-width   : max-content;
		  }

		& > p {
		  font-size : clamp(14px, 1.1vw, 20px);
		  }
		}
	  }
	}
  }
