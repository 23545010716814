@import "../../../../../css/all";

.CalcSectionOwner {
  color           : #054F99;
  display         : flex;
  justify-content : center;
  margin-block    : 6%;
  padding-block   : 6%;
  padding-inline  : 8vw;
  position        : relative;
  text-align      : center;
  overflow        : hidden;

  @include respond-to("handhelds") {
	padding-inline : 6vw;
	}

  & > .background-container {
	background-image    : url("#{$s3ImagesLink}/marketing/mort/calcBG.svg");
	background-position : center;
	background-repeat   : no-repeat;
	background-size     : 74%;
	height              : 100%;
	position            : absolute;
	right               : -3.8%;
	top                 : 25px;
	width               : 100%;
	@include respond-to("handhelds") {
	  display : none;
	  }
	}

  & > .content-container {
	align-items         : center;
	background          : #EAEFF7;
	border-radius       : 180px;
	box-shadow          : 2px 5px 14px #00000021;
	display             : flex;
	gap                 : 20px;
	padding-block-end   : 50px;
	padding-block-start : 120px;
	padding-inline      : 4vw;
	@media screen and (max-width : 890px) {
	  border-radius  : 50px;
	  flex-direction : column;
	  width          : 100%;
	  }
	@include respond-to("handhelds") {
	  padding-block : 40px;
	  }

	& > .right-side-container {
	  align-items    : center;
	  display        : flex;
	  flex-direction : column;
	  gap            : 30px;

	  & > h2 {
		font-size   : clamp(30px, 2.3vw, 44px);
		font-weight : 700;
		}

	  & > .image-container {
		& > img {
		  width : min(606px, 31.5vw);
		  @include respond-to("handhelds") {
			width : 100%;
			}
		  }
		}
	  }

	& > .left-side-container {
	  display        : flex;
	  flex-direction : column;
	  gap            : 30px;
	  min-width      : max-content;
	  z-index        : 2;

	  & > h3 {
		display        : flex;
		flex-direction : column;
		font-size      : clamp(18px, 2.6vw, 36px);
		font-weight    : 600;

		& > span {
		  display         : flex;
		  justify-content : center;

		  & > img {
			width : min(190px, 9.7vw);
			@include respond-to("handhelds") {
			  width : 90px;
			  }
			}
		  }
		}

	  & > a {
		background      : #ED1C24;
		border-radius   : 4px;
		color           : #FFFFFF;
		font-size       : clamp(16px, 1.4vw, 26px);
		font-weight     : 500;
		margin          : 0 auto;
		padding-block   : 20px;
		padding-inline  : 2vw;
		text-decoration : none;
		@include respond-to("handhelds") {
		  border-radius : 10px;
		  padding-block : 12px;
		  width         : 100%;
		  }
		}

	  & > p {
		color     : #ED1C24;
		font-size : clamp(16px, 1vw, 18px);
		}
	  }
	}
  }
