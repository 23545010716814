@import "../../../variables.scss";

dialog::backdrop {
    backdrop-filter: blur(0.25rem);
}

.mort-contact-success-modal {
    border: none;
    border-radius: 5px;
    color: $base-color;
    padding: 40px 80px;
    transition: opacity 0.2s ease;

    &>form {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &>button {
            position: absolute;
            color: $base-color;
            border: none;
            background-color: transparent;
            font-weight: 800;
            right: -60px;
            top: -25px;

            &>.close-container {
                position: relative;
                padding: 10px;

                &>* {
                    height: 16px;
                    width: 2px;
                    background-color: $base-color;
                    position: absolute;
                    transform: rotate(-45deg);

                    &:first-child {
                        transform: rotate(45deg);
                    }
                }

                &:hover {
                    cursor: pointer;
                }


            }
        }

        &>h2 {
            font-size: $mort-contact-header-font;
            font-weight: 700;
            padding-block-start: 10px;
        }

        &>.product-pages-image-container {
            max-width: 100px;
        }
    }
}