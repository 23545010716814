.single-loan-container {
  background-color: white;
  padding-block-start: 10px;
  padding-inline: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: #1d3557;
  height: fit-content;
  position: relative;
  overflow: hidden;
  width: clamp(300px, 21vw, 370px);

  @media (width < 1000px) {
    width: 100%;
  }

  & > .open-more-info {
    border: none;
    background-color: transparent;
    padding-block-start: 15px;
    padding-block-end: 6px;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
    & > img {
      transition: transform 0.2s linear;
    }
    &.is-open {
      & > img {
        transform: rotate(180deg);
      }
    }
  }

  & > .single-loan-data {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > * {
      &:not(.payment-cta-container) {
        width: 100%;
      }
    }

    &.accordion-container {
      padding: 0;
      padding-block-end: 0;
      transition: all 0.2s linear;

      &.is-open {
        padding-block-start: 30px;
        overflow: visible;
      }
    }

    .button-and-tooltip-container {
      position: relative;
      z-index: 3;
      display: flex;

      & > .copy-button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        max-width: 18px;
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        & > img {
          width: 100%;
        }
      }

      & > .tooltip {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 10px;
        font-weight: 700;

        &.active {
          animation: slideDownCurrent 1s linear both;
        }

        @keyframes slideDownCurrent {
          0% {
            opacity: 0;
            transform: translate(-50%, 0);
          }

          50% {
            opacity: 1;
          }

          100% {
            opacity: 0;
            transform: translate(-50%, 70%);
          }
        }
      }
    }

    & > .data-container {
      display: flex;
      flex-direction: column;
      gap: var(--gap-xs);
      min-width: max-content;

      & > p {
        font-size: var(--font-size-sm3);
        font-weight: 600;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: visible;

        .deal-identifier-container {
          display: flex;
          gap: var(--gap-xxs);
        }

        & > span:not(.indicator) {
          position: relative;
          z-index: 1;
          background-color: white;
        }

        & > span:not(.indicator)::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: -100%;
          width: 100%;
          height: 10px;
          background-color: var(--white);
          z-index: 2;
        }

        & > .indicator::after {
          content: "";
          position: absolute;
          margin-inline: 1px;
          bottom: 5px;
          width: 100%;
          height: 1px;
          background-color: #8996a7;
        }
      }
    }
  }

  & > .single-loan-header {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;

    @media (width < 1000px) {
      gap: 0;
    }

    & > .right-side {
      display: flex;
      flex-direction: column;
      gap: var(--gap-xxs);
      & > h5 {
        font-size: var(--font-size-md2);
        font-weight: 600;
      }

      & > p {
        font-size: 18px;
        & > .shekel-container {
          font-size: 12px;
        }
      }
    }

    & > .left-side {
      & > h5 {
        color: #1799fc;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--font-size-md);
        font-weight: 600;

        & > span {
          & > .shekel-container {
            font-size: var(--font-size-xs);
          }
        }

        & > .next-payment-cta {
          font-weight: 500;
          font-size: var(--font-size-sm2);
        }
      }
    }
  }

  &.paid {
    & > * :not(.loan-paid-container) {
      pointer-events: none;
    }

    & > .open-more-info {
      visibility: hidden;
    }
  }
}
