.spinner-container {
    animation: fadeIn 0.4s;
    &>.spinner-background {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -20px 0 0 -20px;
        width: 40px;
        height: 40px;

        &>circle {
            stroke: hsl(210, 70, 75);
        }
    }

    &>.spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -20px 0 0 -20px;
        width: 40px;
        height: 40px;

        & .path {
            stroke: #FFF;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite forwards;
        }


    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }

        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

}