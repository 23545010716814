.second-part-container {
  flex-wrap: wrap;
  & > .icon-container {
    justify-content: center;

    & > * {
      width: 25%;
      margin-block: 20px;

      @media (width < 1000px) {
        width: 70%;
      }
    }
  }

  & > .paragraphs-container {
    display: flex;
    flex-direction: column;

    @media (width < 1000px) {
      max-width: 100%;
    }

    & > .paragraph-first-part {
      font-weight: 700;
      margin-bottom: 10px;
    }

    .info-boxes-container {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .info-box {
        display: flex;
        align-items: center;
        text-align: start;
        justify-content: flex-start;
        gap: 10px;

        p {
          text-align: start;
        }

        .icon-container {
          max-width: 40px;
        }
      }
    }
  }
}
