@import "../../css/all.scss";

.footer {
  background: linear-gradient(84deg, #043363 0%, #064f99 100%);
  color: #ffffff;
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  justify-content: space-between;
  padding-block: 40px;
  padding-inline: 4%;

  &.display-none {
    display: none;
  }

  @include respond-to("medium-screens") {
    padding-inline: calculateRem(24);
  }

  @include respond-to("medium-handhelds") {
    background: linear-gradient(145deg, #043363 0%, #064f99 100%);
    gap: 30px;
    grid-template-columns: 1fr;
    justify-content: center;
    padding-block: 40px;
  }

  & > * {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  & > .logo-container {
    & > .logo {
      max-width: 210px;
      @include respond-to("medium-handhelds") {
        max-width: 127px;
      }
    }
  }

  & > .social-links-list {
    flex-direction: row;
    gap: 30px;
    @include respond-to("medium-handhelds") {
      grid-area: 2;
    }
  }

  & > .footer-content {
    align-items: center;
    flex-direction: column;
    gap: 12px;

    & > * {
      font-size: calculateRem(20);
      font-weight: normal;
    }

    & > .links-container {
      display: flex;
      gap: 20px;

      & > * {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }
}

.half-width {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (width < 500px) {
    width: 75%;
  }
}
