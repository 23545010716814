@import "../../../../../css/all";

.TheWeCheckMortgage {
  background          : url("#{$s3ImagesLink}/marketing/mort/Main/mortgage-section-background.png");
  background-position : center;
  background-repeat   : no-repeat;
  background-size     : cover;
  color               : #054F99;
  display             : flex;
  flex-direction      : column;
  gap                 : 100px;
  padding-block       : 60px;
  padding-inline      : 8vw;
  @include respond-to("handhelds") {
	background        : url("#{$s3ImagesLink}/marketing/mort/Main/mortgage-section-background-mobile.png");
	background-repeat : no-repeat;
	background-size   : cover;
	gap               : 30px;
	padding-block     : 30px;
	padding-inline    : 4vw;
	}

  & > .title-container {
	&.in-view {
	  animation : slideFromRight 1s;
	  }

	@include respond-to("handhelds") {
	  text-align : center;
	  }

	& > h2 {
	  display     : flex;
	  font-size   : clamp(30px, 3.3vw, 64px);
	  font-weight : 800;
	  line-height : 100%;
	  min-width   : max-content;
	  @include respond-to("handhelds") {
		font-weight     : 800;
		gap             : 1vw;
		justify-content : center;
		line-height     : normal;
		}

	  & > img {
		width : min(330px, 17vw);
		@include respond-to("handhelds") {
		  width : 160px;
		  }
		}
	  }

	& > h3 {
	  font-size   : clamp(30px, 3.1vw, 58px);
	  font-weight : 600;
	  }
	}

  & > .content-container {
	align-items          : flex-end;
	display              : flex;
	gap                  : min(70px, 3.7vw);
	padding-block-end    : 90px;
	padding-inline-end   : 10vw;
	padding-inline-start : 8vw;
	@media screen and (max-width : 890px) {
	  justify-content   : center;
	  padding-block-end : 0;
	  padding-inline    : 4vw;
	  }

	& > .rows-container-owner {
	  display : flex;
	  @media screen and (max-width : 890px) {
		flex-direction : column;
		gap            : 205px;
		}

	  & > .rows-container {
		display        : grid;
		gap            : 36px;
		grid-auto-rows : 1fr;

		&.in-view {
		  animation : slideFromLeft 1s;
		  }

		&:first-child {
		  &.in-view {
			animation : slideFromRight 1s;
			}
		  }

		& > .single-row-item {
		  align-items     : center;
		  background      : #FFFFFF;
		  border-radius   : 27px;
		  box-shadow      : 5px 7px 8px #00000029;
		  display         : flex;
		  flex-direction  : column;
		  justify-content : space-between;
		  max-height      : 115px;
		  padding-block   : 18px;
		  padding-inline  : 2vw;
		  @include respond-to("handhelds") {
			align-items     : center;
			border-radius   : 14px;
			height          : 70px;
			justify-content : center;
			padding-block   : 0;
			width           : 85px;
			}

		  & > .image-container {
			display         : flex;
			justify-content : center;
			width           : 100%;
			@include respond-to("handhelds") {
			  & > img {
				max-width : 26px;
				}
			  }
			}

		  & > h4 {
			color       : #484848;
			font-size   : clamp(12px, 0.9vw, 16px);
			font-weight : 600;
			min-width   : max-content;
			@include respond-to("handhelds") {
			  min-width  : 0;
			  text-align : center;
			  }
			}
		  }
		}
	  }

	& > .columns-container {
	  display : flex;
	  gap     : min(100px, 5.1vw);
	  width   : 100%;
	  @media screen and (max-width : 890px) {
		flex-direction : column;
		gap            : 100px;
		}

	  & > .single-column {
		display        : flex;
		flex           : 1;
		flex-direction : column;
		gap            : 36px;
		position       : relative;
		text-align     : center;
		width          : calc(50% - min(50px, 2.55vw));

		&.in-view {
		  animation : slideFromLeft 1s;
		  }

		&:first-child {
		  &.in-view {
			animation : slideFromRight 1s;
			}
		  }

		@include respond-to("handhelds") {
		  width : auto;
		  }

		& > .image-container {
		  position : absolute;
		  right    : -10%;
		  top      : -7%;
		  @include respond-to("handhelds") {
			right : -12%;
			}

		  & > img {
			width : min(130px, 6.8vw);
			@include respond-to("handhelds") {
			  width : 70px;
			  }
			}
		  }

		& > .title-container {
		  background     : #FFFFFF;
		  border-radius  : 27px;
		  box-shadow     : 5px 7px 8px #00000029;
		  min-width      : max-content;
		  padding-block  : 24px;
		  padding-inline : min(25px, 1.3vw);
		  text-align     : center;
		  @include respond-to("handhelds") {
			border-radius : 14px;
			padding-block : 12px;
			}

		  & > h3 {
			font-size   : clamp(20px, 2.1vw, 40px);
			font-weight : 700;
			}

		  & > h4 {
			color     : #B9D5E5;
			font-size : clamp(18px, 1.5vw, 30px);
			}
		  }

		& > .content-container {
		  display        : grid;
		  gap            : 36px;
		  grid-auto-rows : 1fr;

		  & > .single-item {
			align-items     : center;
			background      : #FFFFFF;
			border-radius   : 27px;
			box-shadow      : 5px 7px 8px #00000029;
			display         : flex;
			justify-content : center;
			max-height      : 115px;
			padding-block   : 46px;
			padding-inline  : 3vw;

			@include respond-to("handhelds") {
			  align-items     : center;
			  border-radius   : 14px;
			  display         : flex;
			  height          : 70px;
			  justify-content : center;
			  min-width       : 0;
			  padding-block   : 0;
			  padding-inline  : 8px;
			  }

			& > p {
			  font-size   : clamp(18px, 1.2vw, 24px);
			  font-weight : 700;
			  }
			}
		  }
		}
	  }
	}
  }
