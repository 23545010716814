.close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    @media (width < 1000px) {
        &>img {
            width: 15px;
        }
    }
}