.info-button {
    background-color: transparent;
    border: none;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &>img {
        width: 20px;
    }
}