.new-request-button-container {
  background-color: var(--light-blue);
  color: var(--white);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-sm3);
  border-radius: 10px;
  transition: background-color 0.1s ease-in-out;
  padding-inline: 10px;
  grid-column: 4;
  grid-row: 1;
  height: max-content;
  padding-block: clamp(10px, 1.1vw, 16px);

  &:hover {
    cursor: pointer;
    background-color: var(--button-hover);
  }

  & > span {
    font-size: var(--container-heading-lg);
    font-weight: 600;
    line-height: 0.9;
  }

  &:only-child {
    position: absolute;
    width: 100%;
    max-width: 320px;
    left: 50%;
    transform: translateX(-50%);
    & > span {
      display: none;
    }
  }

  @media (width < 1000px) {
    width: 100%;
    border-radius: 5px;
    & > span {
      display: none;
    }
  }
}
