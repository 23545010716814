.info-section-container {
  display: flex;
  gap: min(70px, 3.7vw);
  animation: fadeIn 0.2s ease-in-out;

  @media (width < 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  & > * {
    &:first-child {
      @media (width < 1000px) {
        grid-column: span 2;
        text-align: center;

        & > * {
          flex-direction: row;
          justify-content: center;
        }
      }
    }

    & > h4 {
      display: flex;
      gap: 4px;
      font-size: var(--font-size-md);
      font-weight: 700;

      & > span {
        display: flex;
        gap: 4px;
        min-width: max-content;

        & > span {
          font-weight: 600;

          &.data-indicator-difference-value {
            display: flex;
            align-items: center;
            gap: 4px;

            transition: color 0.2s ease;

            &.positive {
              color: var(--green);
            }

            &.negative {
              color: var(--red);
            }

            & > img {
              width: clamp(13px, 0.9vw, 17px);
            }

            @media (width < 1000px) {
                gap: 1px;
              & > img {
                width: 13px;
              }
            }
          }
        }

        @media (width < 1000px) {
          &:has(.data-indicator-difference-value) {
            font-weight: 500;
          }
        }
      }

      @media (width < 1000px) {
        flex-direction: column;
        font-size: 14px;

        &:has(.data-indicator-difference-value) {
          background-color: var(--gray);
          padding-inline: 9px;
          padding-block: 6px;
          width: 100%;
          border-radius: 10px;
          @media (width < 1000px) {
            padding-inline: 14px;
            padding-block: 10px;
          }
        }
      }
    }
  }

  & > .room-number {
    span {
      color: var(--light-blue-text);
    }
  }

  @media (width < 1000px) {
    gap: 15px 10px;
  }
}
