@import "../../../../css/all";

.WhoAreWe {
  background      : url("#{$s3ImagesLink}/dashboard/mortgage/briksline.svg") top repeat-x;

  color           : $weCheckBlue;
  display         : flex;
  flex-flow       : row-reverse;
  justify-content : center;

  justify-items   : center;
  margin          : calculateRem(25) auto 0;
  text-align      : center;
  width           : 85%;
  @include respond-to("handhelds") {
	flex-flow : column;
	width     : 100%;
	}

  .wording {
	align-items : center;
	margin-top  : calculateRem(40);
	padding     : calculateRem(15);
	text-align  : right;
	@include respond-to("handhelds") {
	  margin-top : 0;
	  text-align : center;
	  }

	h5 {
	  font-size   : calculateRem(24);
	  font-weight : normal;
	  margin-top  : calculateRem(2);
	  text-align  : right;
	  @include respond-to("handhelds") {
		font-size : calculateRem(18);
		}
	  }

	.redH5 {
	  color       : red;
	  font-weight : bold;
	  margin-top  : calculateRem(15);
	  text-align  : right;
	  }
	}

  .buttonBox {
	width : 60%;
	}

  .textWithLogo {
	align-items : center;
	color       : red;
	display     : flex;
	flex-flow   : row;
	text-align  : right;

	img {
	  margin-right : calculateRem(5);
	  margin-top   : calculateRem(15);
	  width        : 120px;
	  @include respond-to("handhelds") {
		margin-top : calculateRem(0);
		margin-top : calculateRem(17);
		width      : 90px;
		}
	  }

	h3 {
	  margin-left  : calculateRem(6);
	  margin-right : calculateRem(6);
	  }
	}

  h1 {
	font-size   : calculateRem(35);
	font-weight : bold;
	}

  .manImg {
	margin-bottom : calculateRem(20);
	margin-top    : calculateRem(20);
	width         : 100%;
	}
  }
