.modal-submit-button {
  grid-column: span 2;
  background-color: var(--background-secondary);
  border: none;
  color: white;
  padding-block: 18px;
  font-size: 20px;
  border-radius: 10px;
  transition: background-color 0.1s ease-in-out;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: #0389ef;
  }

  @media (width < 600px) {
    font-size: 16px;
  }
}
