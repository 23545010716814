.complete-mort-second-section {
  align-items: center;
  &.grid-section-container {
    grid-row-gap: 0;
  }

  & > .product-pages-image-container {
    justify-content: center;
    align-items: center;
    & > * {
      max-width: 480px;
    }
  }

  & .airplane-animation-and-cta {
    position: relative;
    grid-column: span 2;
    @media (width < 1000px) {
      grid-column: span 1;
    }

    & > .animation-container {
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 40px;
    }

    & > .cta-container {
      & > .animate-appearance {
        opacity: 0;
        animation: changeOpacity 0.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
      }
    }
  }

  @media (width < 1000px) {
    gap: 50px;

    .product-pages-image-container {
      justify-content: center;

      & > img {
        max-width: 300px;
      }
    }

    & > * {
      width: 100%;
    }
  }
}
