.hamburger-container {
  background     : none;
  border         : none;
  --button-color : #333;

  & > .hamburger {
	& > .line {
	  fill             : #2F5099;
	  transform-origin : center;
	  transition       : y 200ms ease-in 200ms, transform 200ms ease-in,
	  opacity 0ms 200ms;
	  }

	&.fill-white {
	  & > .line {
		fill : #FFFFFF;
		}
	  }

	&.open {
	  & > .line {
		transition : y 200ms ease-in, transform 200ms ease-in 200ms,
		opacity 0ms 200ms;

		&.top,
		&.bottom {
		  y : 45;
		  }

		&.top {
		  transform: rotate(45deg);
		  }

		&.middle {
		  opacity : 0;
		  }

		&.bottom {
		  transform: rotate(-45deg);
		  }
		}
	  }
	}
  }

