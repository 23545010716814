@import "../variables.scss";

.cta-button {
    background-color: $button-background-color;
    color: $secondary-color;
    font-size: $button-font;
    font-weight: 600;
    max-width: fit-content;
    text-decoration: none;
    padding: 14px 44px;
    border-radius: 8px;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: $button-hover-color;
    }

    @media screen and (max-width: 1000px) {
        max-width: none;
        width: 100%;
        text-align: center;
    }
}