@import "../variables.scss";

.cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    grid-column: span 2;
    @media (width < 1000px) {
        grid-column: span 1;
        gap: 20px;
        & > h3 {
            text-align: center;
        }
    }
}