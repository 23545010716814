@import "../../css/all";

.mortInfo {
  color               : $weCheckBlue;
  display             : flex;
  flex-direction      : column;
  padding-block-start : 80px;
  text-align          : center;
  @include respond-to("handhelds") {
	padding-block-start : 0;
	}

   & > * {
	scroll-margin-block-start: 80px;
   }	

  .logoLine {
	display         : flex;
	flex-flow       : row;
	height          : 30px;
	justify-content : center;
	width           : 100%;
	@include respond-to("handhelds") {
	  width : 90%;
	  }

	img {
	  margin-left  : calculateRem(5);
	  margin-right : calculateRem(5);
	  margin-top   : calculateRem(8);
	  width        : calculateRem(105);
	  @include respond-to("handhelds") {
		margin-right : -10px;
		margin-top   : -5px;
		}
	  }
	}

  .buttonBox {
	display         : flex;
	flex-flow       : row;
	justify-content : center;
	margin          : calculateRem(20) auto calculateRem(5);
	width           : 90%;
	@include respond-to("handhelds") {
	  flex-flow : column;
	  }

	a {
	  font-size     : 23px;
	  height        : calculateRem(70);
	  margin-bottom : calculateRem(15);
	  margin-left   : calculateRem(15);
	  width         : 400px;
	  @include respond-to("handhelds") {
		width : unset !important;
		}
	  @include respond-to("handhelds") {
		margin-left : 0 !important;
		}
	  }

	.calcImg {
	  margin-left : 10px;
	  @include respond-to("handhelds") {
		position : absolute;
		right    : 40px;
		}
	  }
	}

  .bricksImage {
	width : 30%;
	@include respond-to("handhelds") {
	  width : 100%;
	  }
	}

  h2 {
	font-weight : bold;
	}

  h3 {
	font-size   : calculateRem(25);
	font-weight : bold;
	}

  h5 {
	font-size   : calculateRem(19);
	font-weight : bold;
	}

  p {
	font-size : calculateRem(26);
	@include respond-to("handhelds") {
	  font-size : calculateRem(20);
	  }
	}

  .inRow {
	display         : flex;
	flex-flow       : row;
	justify-content : center;
	margin          : 0 auto;
	width           : 100%;
	}
  }
