.layout-personal-area-header-container {
  position: sticky;
  height: 92px;
  background-color: white;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  box-shadow: 0 6px 11px #cacaca7a;
  justify-content: space-between;
  padding-inline: 70px;
  z-index: 98;

  & > .logo-and-logout-container {
    display: flex;
    align-items: center;
    gap: 45px;
    & > .logo-container {
      & > img {
        max-width: 160px;
        width: 100%;

        @media (width < 1000px) {
          max-width: 114px;
        }
      }
    }
  }

  @media (width < 1000px) {
    padding-inline: 20px;
    height: auto;
    padding-block: 20px;
    box-shadow: none;
  }
}
