.single-month-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--gray);
    border-radius: 5px;
    aspect-ratio: 1;
    transition: background-color 0.1s ease, color 0.1s ease, border-radius 0.05s ease;
    direction: rtl;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &::after {
        content: " ";
        position: absolute;
        left: -18px;
        top: 0;
        width: 18px;
        height: 100%;
        transition: background-color 0.05s ease;
    }

    &:not(.disabled) {
        &.selected {
            border-radius: 0;
    
            &.start-month,
            &.start-of-row {
                border-radius: 0 5px 5px 0;
            }
    
            &.end-month,
            &.end-of-row {
                border-radius: 5px 0 0 5px;
            }
    
            &:not(.end-month, .end-of-row, .only-start-month) {
                &::after {
                    background-color: var(--dark-blue);
                }
            }
    
            &.only-start-month,
            &.only-item-in-row {
                border-radius: 5px;
            }
    
            &:hover {
                color: var(--light-blue-text);
            }
    
        }
    
        &:hover,
        &.selected {
            background-color: var(--dark-blue);
            color: var(--white);
            cursor: pointer;
        }
    
        &.before-hovered {
            background-color: color-mix(in srgb, var(--dark-blue-opacity) 80%, var(--white) 20%);
            color: var(--white);
        }
    
    }

    &>.month-name-container {
        font-weight: 700;
        font-size: var(--font-size-sm);
    }

    &>.year-container {
        font-size: 14px;
    }
}