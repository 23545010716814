.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }


    &:focus {
        outline: none;
    }

    &>.checkbox {
        min-width: 14px;
        max-width: 14px;
        min-height: 14px;
        max-height: 14px;
        border: 1px solid #1D3557;
        border-radius: 50%;
        transition: background-color 0.1s ease-in-out;

        &.checked {
            background-color: #1D3557;
        }
    }

    &>p {
        font-size: 14px;
        color: #1D3557;
        text-align: start;
        font-weight: 600;

        &>span {
            z-index: 5;
            font-size: 14px;
            font-weight: 600;
            text-decoration: underline;

        }

    }
}