@import "../variables.scss";

h3 {
    font-weight: $header-weight-h3;
    font-size: $header-font-h3;
    white-space: pre-wrap;

    @media (width < 1000px) {
        white-space: inherit;
    }
}