.pop-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: calc(100% - calc(60px + clamp(10px, 3.1vw, 60px)));
  position: absolute;
  gap: var(--gap-xl);
  bottom: 0;
  transform: translateY(100%);
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
  padding-block-start: 20px;
  padding-block-end: 25px;
  transition: transform 0.2s linear, min-height 0.2s linear;
  z-index: 100;
  min-height: 300px;

  &.is-open {
    transform: translateY(0);
    & > .close-button {
      &.delay-visibility {
        animation: fadeInWithPointerEvents 0.2s ease-in-out both 3s;

        @keyframes fadeInWithPointerEvents {
          0% {
            opacity: 0;
            pointer-events: none;
          }
          100% {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  &.view-options {
    min-height: 260px;
  }

  & > .popup-spread-image {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20vw);
    z-index: -1;
    pointer-events: none;

    @media (width < 1000px) {
      width: 100%;
      max-width: 210px;
      bottom: 70px;
    }
  }

  & > * {
    &:not(.close-button) {
      animation: fadeIn 0.2s ease-in-out;
    }
  }

  & > .pop-up-content {
    & > .popup-animation {
      display: flex;
      justify-content: center;
    }

    & > p {
      font-size: var(--font-size-md);
      text-align: center;
      &::first-line {
        font-weight: 600;
      }
    }
  }
  & > .search-button {
    min-width: max-content;
    padding-inline: 20px;
    pointer-events: all;
  }
  @media (width < 1000px) {
    padding-inline: 20px;
    width: 100%;
    &:not(.view-options) {
      &.is-open {
        height: 410px;
        justify-content: space-between;
      }
    }
  }
}

.pop-up-background {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;

  &.is-open {
    backdrop-filter: blur(5px);
  }
}
