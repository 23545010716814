.auto-complete-container {
  position: relative;

  &:has(.is-open) {
    & > .auto-complete {
      border-radius: 10px 10px 0 0;
      & > .chevron {
        transform: rotate(180deg);
      }
    }
  }

  &:is([aria-disabled="true"]) {
    opacity: 0.5;
    pointer-events: none;
  }

  & > .auto-complete {
    width: 100%;
    height: clamp(40px, 3vw, 50px);

    display: flex;
    align-items: center;
    background-color: var(--white);
    padding-inline: 14px;
    border-radius: 10px;
    gap: 10px;

    & > .icon-container {
      min-width: 20px;
      max-width: 20px;

      &.chevron {
        min-width: 14px;
        max-width: 14px;
        transition: transform 0.2s ease-in-out;
      }
    }

    & > input {
      width: 100%;
      font-size: var(--font-size-md);
      border: none;

      &:disabled {
        background-color: transparent;
      }
    }

    @media (width < 1000px) {
      padding-inline: 20px;
      height: 50px;

      & > .icon-container {
        min-width: 16px;
        max-width: 16px;
      }

      & > input {
        font-size: 16px;
      }
    }
  }
}
