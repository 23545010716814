@import "../../../../../../../components/product-pages/animations.scss";

.bottom-section-container {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 125px;

    @media (width < 1000px) {
        grid-column: span 1;
    }

    &>.airplane-animation-and-cta {
        position: relative;

        &>.animation-container {
            z-index: 1;
            pointer-events: none;
            position: absolute;
            top: 0;
        }

        &>.cta-container {
            &>.animate-appearance {
                opacity: 0;
                animation: changeOpacity 0.5s;
                animation-delay: 1s;
                animation-fill-mode: forwards;
            }
        }
    }
}
