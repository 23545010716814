.login-button {
  border: none;
  font-size: 16px;
  background-color: #2999fc;
  color: #fff;
  padding-inline: 50px;
  padding-inline: 29px;
  border-radius: 10px;
  padding-block: 4px;
  transition: background-color 0.1s ease-in-out;
  text-decoration: none;
  float: left;
  margin: 10px 0;
  width: 122px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #0389ef;
  }

  @media (width < 1000px) {
    font-size: 16px;
    padding-inline: 12px;
    padding-block: 6px;
  }
}
