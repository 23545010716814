@import "../../../css/all";

.RentInfo {
  background-color: #ffffff;
  direction: rtl;
  margin: 0;
  min-height: 100%;
  padding: 0;
  top: 0;
  width: 100%;

  & > .inner-content {
    & > * {
      scroll-margin-block-start: 80px;
    }
    & > .extra-padding {
      padding-block-start: 30px;
    }
  }

  .btn--red {
    background-color: $newRed;
  }

  a {
    text-decoration: none;
  }

  .products {
    margin: 0 auto;
    width: 60%;
    @include respond-to("handhelds") {
      width: 100%;
    }
  }

  p {
    white-space: normal;
  }

  @include respond-to("iphone5") {
    h1 {
      font-size: calculateRem(46);
    }
  }

  .btn {
    align-items: center;
    border: calculateRem(2) solid $newRed;
    border-radius: calculateRem(5);
    color: $newRed;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    justify-content: center;
    padding: calculateRem(14) calculateRem(30);
    text-decoration: none;
    transition: 0.2s;
    user-select: none;

    &--red {
      @extend .btn;
      background-color: $newRed;
      color: #ffffff;

      &:hover {
        background-color: darken($newRed, 5%);
      }
    }

    &--blue {
      @extend .btn;
      background-color: $weCheckBlue;
      border: calculateRem(2) solid $weCheckBlue;
      color: #ffffff;

      &:hover {
        background-color: darken($weCheckBlue, 5%);
      }
    }

    &--white {
      @extend .btn;
      background-color: white;
      color: red;

      &:hover {
        background-color: darken($newRed, 5%);
      }
    }

    &--whiteBlue {
      @extend .btn;
      background-color: white !important;
      border: calculateRem(2) solid $weCheckBlue;
      color: $weCheckBlue !important;

      &:hover {
        background-color: darken($weCheckBlue, 5%);
      }
    }
  }

  .smallBtn {
    @extend .btn;
    border: calculateRem(2) solid $weCheckBlue;
    border-radius: calculateRem(15);
    padding: calculateRem(20) calculateRem(6);

    &--blue {
      @extend .smallBtn;
      background-color: $weCheckBlue;
      border: calculateRem(2) solid $weCheckBlue;
      color: #ffffff;

      &:hover {
        background-color: darken($weCheckBlue, 5%);
      }
    }

    &--white {
      @extend .smallBtn;
      background-color: white;
      color: $weCheckBlue;

      &:hover {
        background-color: darken($weCheckBlue, 5%);
        color: white;
      }
    }
  }

  &__products {
  }
}
