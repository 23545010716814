@import "../../../../../css/all";

.MortgageAdvisersTestimonials {
  color : #1961A7;

  & > .mobileGrid {
	display : none;

	&.in-view {
	  animation : slideFromLeft 1s;
	  }

	@include respond-to("handhelds") {
	  display : grid;
	  gap     : 20px;
	  }
	}

  .title-container {
	&.in-view {
	  animation : slideFromRight 1s;
	  }
	}

  .Grid {
	display               : grid;

	grid-column-gap       : 25px;

	grid-row-gap          : 25px;

	grid-template-columns : repeat(12, 1fr);
	grid-template-rows    : repeat(12, 1fr);

	&.in-view {
	  animation : slideFromLeft 1s;
	  }

	@include respond-to("handhelds") {
	  display : none;
	  }
	@include respond-to("handhelds") {
	  grid-column-gap       : px;
	  grid-row-gap          : 10px;
	  grid-template-columns : 1fr;
	  grid-template-rows    : repeat(5, 1fr);
	  }

	.row,
	.col,
	a,
	.ImageContainer {
	  min-height : 100%;
	  }
	}

  .Item1 {
	grid-area : 1 / 1 / 5 / 7;
	}

  .Item2 {
	grid-area : 5 / 1 / 13 / 7;
	}

  .Item3 {
	grid-area : 1 / 7 / 5 / 13;
	}

  .Item4 {
	grid-area : 5 / 7 / 9 / 13;
	}

  .Item5 {
	grid-area : 9 / 7 / 13 / 13;
	}

  .Decoration {
	padding : 2rem;
	}

  h2 {
	font-size   : calculateRem(64);
	font-weight : 600;
	margin      : 3rem 0;
	@include respond-to("handhelds") {
	  font-size   : calculateRem(30);
	  font-weight : bold;
	  margin      : unset;
	  text-align  : center;
	  }
	}

  h4 {
	font-size   : 26px;
	font-weight : 700;
	@include respond-to("handhelds") {
	  font-size : 16px;
	  }
	}

  h5 {
	font-size   : 26px;
	font-weight : 600;
	@include respond-to("handhelds") {
	  font-size : 16px;
	  min-width : max-content;
	  }
	}

  p {
	font-size : calculateRem(22);
	@include respond-to("handhelds") {
	  font-size : 16px;
	  }
	}

  .IntroText {
	color      : $mortBlue;
	font-size  : calculateRem(30);
	text-align : center;
	@include respond-to("handhelds") {
	  font-size : calculateRem(16);
	  }
	}

  .btn--azure {
	margin : 2rem;
	width  : 300px;
	}

  .ratingStars {
	align-items     : flex-end;
	display         : flex;
	justify-content : center;
	padding         : 1rem;

	.starSmall {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 1rem;
	  min-width           : 1rem;
	  }

	.starMedium {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 1.5rem;
	  min-width           : 1.5rem;
	  }

	.starLarge {
	  background-image    : url("#{$s3ImagesLink}/marketing/testimonials/star.svg");
	  background-position : center;
	  background-repeat   : inherit;
	  background-size     : cover;
	  margin              : 0.5rem;
	  min-height          : 2rem;
	  min-width           : 2rem;
	  }
	}
  }
