.link-and-tooltip-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    &>a {
        color: #1D3557 !important;
        font-size: clamp(16px, 1.1vw, 20px);
        font-weight: 600;
        text-decoration: none;
        transition: 0.2s ease;

        &>span {
            color: #EE4D4D;
        }

        &.text-bold {
            font-weight: 700;
        }

        &:hover {
            cursor: pointer;
            text-shadow: 0 0 1px gray;
        }

        &.white-text {
            color: #FFFFFF;

            &:hover {
                text-shadow: 0 0 2px white;
            }
        }

        @media screen and (max-width: 1000px) {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        text-align: center;
    }

}