.contact-button-container {
  display: flex;
  align-items: center;
  background-color: white;
  padding-block: 10px;
  padding-inline: 16px;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--color-secondary);
  min-width: max-content;

  &:focus {
    outline: none;
  }

  @media (width < 600px) {
    font-size: 16px;
  }
}
