@import "../../../css/all";

.AccessPage {
  min-height: calc(100vh - #{calculateRem(150)});
  max-width: 650px;
  margin: auto;

  @media (width < 1000px) {
    padding-inline: 4%;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  li {
    color: #1d3557;
  }

  h1 {
    font-size: 30px;
    font-weight: 800;

    @media (width < 1000px) {
      font-size: 24px;
    }
  }

  a {
    color: #1799fc;
    text-decoration: underline;
  }

  p:first-of-type {
    margin-top: calculateRem(5);
  }

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (width < 1000px) {
      font-size: 14px;
    }

    &.withSpacing {
      margin-bottom: calculateRem(10);
    }

    &.disclaimer {
      margin-top: calculateRem(25);
      font-weight: 600;
    }
  }

  ol {
    list-style: disc;
    padding-inline: 17px;
  }

  li {
    font-size: 20px;

    @media (width < 1000px) {
      font-size: 14px;
    }
  }

  li:not(:last-child) {
    margin-bottom: calculateRem(10);
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin-top: calculateRem(10);
    font-size: 24px;

    @media (width < 1000px) {
      font-size: 18px;
    }
  }

  h4 {
    margin-top: calculateRem(10);
    margin-bottom: calculateRem(5);
    font-size: 20px;
    font-weight: 700;

    @media (width < 1000px) {
      font-size: 18px;
    }
  }
}
