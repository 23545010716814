.data-view-container {
  min-width: calc(100% - calc(60px + clamp(10px, 3.1vw, 60px)));
  height: calc(100% - clamp(250px, 10vw, 150px));
  background-color: var(--white-opacity);
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  padding-block-start: 30px;
  padding-inline: 80px;
  z-index: 2;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-lg);
  overflow-x: clip;
  color: var(--dark-blue);

  & > .before-login-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-md);
    animation: fadeIn 0.2s ease-in-out;
    text-align: center;

    & > .search-button {
      text-decoration: none;
    }

    & > p {
      font-size: var(--font-size-md);
      color: var(--light-blue-text);
    }
  }

  & > .heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-lg);
    & > h2 {
      font-size: var(--font-size-xl);
      font-weight: 700;
      text-wrap: balance;
      text-align: center;
    }
  }

  @media (width < 1000px) {
    width: 100%;
    border-radius: 20px 20px 0 0;
    padding-block-end: min(25px, 2vh);
    padding-inline: 20px;
    min-height: 70vh;
    height: auto;
    gap: min(20px, 2vh);
  }

  &.data-view-visible {
    transform: translate3d(0, 0, 0);
  }
}
