@import "../../css/all";

.wckForm__Search {
  position : relative;
  margin   : 0;

  input {
	font-size: calculateRem(18);
	background      : url("#{$s3ImagesLink}/dashboard/shared/magnifying.svg") no-repeat calculateRem(10) center;
	background-size : calculateRem(14);
	}

  .cityInput {
	background      : url("#{$s3ImagesLink}/marketing/tenantPage/city.svg") no-repeat calculateRem(10) ;
	background-size : calculateRem(24);
	background-position-y: 0;
	}

  .streetInput {
	background      : url("#{$s3ImagesLink}/marketing/tenantPage/street.svg") no-repeat calculateRem(10) center;
	background-size : calculateRem(24);
	background-position-y: 0;
	}

  &__loader {
	position  : absolute;
	width     : calculateRem(16);
	height    : calculateRem(16);
	left      : calculateRem(-5);
	animation : loaderAnimation 0.7s linear infinite;
	}

  .UIWck__select__dropdown {
	top : calculateRem(50);
	}
  }

@keyframes loaderAnimation {
  from {
	transform : rotate(0deg);
	}
  to {
	transform : rotate(360deg);
	}
  }