@import "../variables.scss";

.product-pages-contact-form {
  position: relative;
  overflow: visible;
  background-color: $alternate-background-color;

  &.white {
    background-color: $secondary-color;
  }

  & > .contact-form-image {
    position: absolute;
    bottom: 0;
    left: clamp(26px, 6.3cqi, 130px);
    width: min(740px, calc(50% - 140px));
  }

  @media (width < 1000px) {
    flex-direction: column;
    & > * {
      width: 100%;
    }
    & > .contact-form-image {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
    }
  }
}
