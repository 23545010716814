@import "../../css/all";

.LogoLine {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: center;

  img {
    margin-right: calculateRem(5);
    width: 110px;
  }

  h3 {
    font-weight: normal !important;
    margin-left: calculateRem(6);
    margin-right: calculateRem(6);
    @include respond-to("handhelds") {
      font-size: calculateRem(20) !important;
    }
  }
}

.animatedLogo {
  width: 135px;
  height: 45px;
  position: relative;
  top: 10px;
  @include respond-to("handhelds") {
    top: unset;
  }

  svg {
    transform: unset !important;
  }
}
