@import "../../../../../css/all";

.HowToSection {
  width : 100%;

  h2 {
	font-size     : calculateRem(40);
	font-weight   : bold;
	margin-bottom : 20px;
	margin-top    : 60px;

	@include respond-to("medium-handhelds") {
	  margin-top : 20px;
	  font-size  : calculateRem(25);
	  text-align : center;
	  }
	}

  .container {
	display       : flex;
	flex-flow     : column;
	padding-right : 125px;
	padding-left  : 125px;
	width         : 100%;

	@include respond-to("medium-handhelds") {
	  padding : 0;
	  }
	}
  }
