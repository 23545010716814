@import "../../../../../../../../css/all";

.OwnerDetailsSlide {
  display : flex;
  @include respond-to("medium-handhelds") {
	width : 100%;
	}

  .btn--gray {
	pointer-events : none;
	background: $weCheckAzure;
	opacity: 50%;
	}

  h3:first-child {
	margin-bottom: 0;
  }

  h3 {
	margin-bottom: 100px;
	@include respond-to("medium-handhelds") {
	  margin-bottom: 50px
	  }
	}

  .buttons {
	display         : flex;
	justify-content : center;
	@include respond-to("medium-handhelds") {
	  justify-content : space-between;
	  }

	.buttonDiv:first-child {
	  width         : 73px;
	  border        : 1px solid #054F99;
	  border-radius : 10px;
	  margin-left: 20px;
	  @include respond-to("medium-handhelds") {
		width         : 50px;
		margin-left: 0;
		}

	  img {
		margin-top : 23px;
		@include respond-to("medium-handhelds") {
		  margin-top : 16px;
		  }
		}
	  }

	.buttonDiv {
	  margin-top : 50px;
	  @include respond-to("medium-handhelds") {
		margin-top : 60px;
		}

	  a {
		margin    : 0 auto;
		width     : 280px;
		font-size : calculateRem(24);
		height    : 64px;
		@include respond-to("medium-handhelds") {
		  width     : 214px;
		  margin    : 0 auto;
		  font-size : calculateRem(20);
		  height    : 50px;
		  }
		}

	  }

	}


  }