@import "../../../../../css/all.scss";

.AppStoreSection {
  align-items          : center;
  background           : linear-gradient(225deg, #064F99 0%, #043363 100%);
  display              : flex;
  justify-content      : space-between;
  padding-inline-start : clamp(70px, 7.3vw, 140px);
  @include respond-to("medium-handhelds") {
	flex-direction       : column;
	gap                  : 40px;
	padding-block-start  : 35px;
	padding-inline-start : 0;
	}

  & > .TitleAndButtons {
	display        : flex;
	flex-direction : column;
	gap            : 30px;

	& > .title-section {
	  color     : #FFFFFF;
	  min-width : max-content;
	  width     : 100%;

	  & > h2 {
		font-size   : clamp(39px, 5.3vw, 70px);
		font-weight : bold;
		}

	  & > h3 {
		align-items : center;
		display     : flex;
		font-size   : clamp(18px, 2.3vw, 30px);
		gap         : 10px;
		@include respond-to("medium-handhelds") {
		  gap : 6px;
		  }

		& > .logo-container {
		  align-items : center;
		  display     : flex;

		  & > img {
			max-width : 144px;
			@include respond-to("medium-handhelds") {
			  width : 92px;
			  }
			}
		  }
		}
	  }

	& > .buttons-section {
	  display : flex;
	  gap     : 46px;
	  @include respond-to("medium-handhelds") {
		align-items    : center;
		flex-direction : column;
		gap            : 12px;
		}

	  & > * {
		align-items      : center;
		background-color : #FFFFFF;
		border-radius    : 10px;
		color            : #25436D;
		display          : flex;
		font-size        : clamp(23px, 2.4vw, 32px);
		font-weight      : 600;
		gap              : clamp(10px, 2vw, 20px);
		justify-content  : center;
		max-width        : 253px;
		padding-block    : 16px;
		transition       : opacity 0.1s ease-in-out;
		width            : 100%;

		&:hover {
		  opacity : 0.9;
		  }

		& > img {
		  max-width : 35px;
		  width     : 100%;
		  @include respond-to("medium-handhelds") {
			max-width : 25px;
			}
		  }

		@include respond-to("medium-handhelds") {
		  max-width : 187px;
		  }
		}
	  }
	}

  & > .presentor-image-container {
	display         : flex;
	justify-content : flex-end;
	max-width       : 60%;
	@include respond-to("medium-handhelds") {
	  max-width : 266px;
	  }

	& > img {
	  width : 100%;
	  }
	}
  }
