@import "../../../../../css/all";

.MortContactUs {
  align-items      : flex-end;
  background-color : #F7F9FC;
  color            : #284780;
  display          : flex;
  justify-content  : space-between;
  padding-block    : 60px;
  padding-inline   : 8vw;
  @media screen and (max-width : 890px) {
	flex-direction : column-reverse;
	gap            : 40px;
	padding-inline : 6vw;
	}

  & > .image-container {
	align-items : flex-end;
	display     : flex;
	flex-basis  : 65%;
	@media screen and (max-width : 890px) {
	  flex-basis : auto;
	  width      : 100%;
	  }

	& > img {
	  width : min(85%, 800px);
	  @media screen and (max-width : 890px) {
		width : 100%;
		}
	  }
	}

  & > .form-container {
	display        : flex;
	flex-direction : column;
	gap            : 20px;
	@media screen and (max-width : 890px) {
	  width : 100%;
	  }

	& > .successDiv {
	  align-items : center;
	  display     : flex;
	  gap         : 1vw;

	  & > img {
		width : 60px;
		@include respond-to("handhelds") {
		  width : 40px;
		  }
		}
	  }

	& > .title-container {
	  min-width  : max-content;
	  text-align : center;

	  & > h2 {
		font-size   : clamp(30px, 2.5vw, 60px);
		font-weight : 700;
		}

	  & > h4 {
		font-size : clamp(16px, 1.2vw, 28px);

		& > a {
		  color           : #284780;
		  text-decoration : underline;
		  }
		}
	  }

	& > .wckForm {
	  & > .contact-form {
		display        : flex;
		flex-direction : column;
		gap            : 16px;

		& > textarea {
		  height : 240px;
		  @include respond-to("handhelds") {
			height : 140px;
			}
		  }

		& > input,
		& > textarea {
		  border         : 1px solid #054F99;
		  border-radius  : 4px;
		  color          : #284780;
		  font-size      : clamp(14px, 1vw, 23px);
		  font-weight    : 600;
		  padding-block  : 20px;
		  padding-inline : 30px;
		  resize         : none;
		  width          : auto;

		  &:focus {
			outline : none;
			}

		  &:-webkit-autofill {
			box-shadow : none;
			}

		  @include respond-to("handhelds") {
			padding-block  : 10px;
			padding-inline : 10px;
			}
		  }

		& > .ErrorLabel,
		.textDanger {
		  font-size   : clamp(14px, 1vw, 23px);
		  font-weight : 600;
		  }

		& > ::placeholder {
		  color : #284780;
		  }

		& > button {
		  background    : #ED1C24;
		  border        : none;
		  border-radius : 4px;
		  color         : #FFFFFF;
		  font-size     : clamp(16px, 1.2vw, 28px);
		  padding-block : 27px;

		  &:hover {
			cursor  : pointer;
			opacity : 1.2;
			}

		  &:disabled {
			cursor  : auto;
			opacity : 0.5;
			}

		  @include respond-to("handhelds") {
			padding-block : 16px;
			}
		  }
		}
	  }
	}
  }
