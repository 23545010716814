@import "../../../variables.scss";

.form-select-container {
    position: relative;
    display: flex;
    flex-direction: column;

    background-color: $secondary-color;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: $header-weight-h2;
    z-index: 10;
    
    &:hover  {
        cursor: pointer;
    }

    &>input {
        display: none;
    }

    &>label {
        color: $mortgage-color;
        pointer-events: none;
    }

    &>.select-value {
        pointer-events: none;
    }

    &>.options-list {
        max-height: 0;
        position: absolute;
        overflow: hidden;
        top: 50%;
        right: 0;
        width: 100%;
        pointer-events: none;
        background-color: $secondary-color;
        &.is-open {
            max-height: 1000px;
            pointer-events: all;
            padding: 10px 20px;
            transition: max-height 0.5s ease;
            border-radius: 0 0 5px 5px;
            
        }

        &>* {
            padding-block: 6px;
            border-top: 2px solid $alternate-background-color;
            &:hover {
                color: $mortgage-color;
            }
        }
    }
    & > .error-message {
        color: red;
        position: absolute;
        left: 10px;
        font-weight: $header-weight-h2;
        padding-inline-start: 20px;
        bottom: -22px;
    }
}

