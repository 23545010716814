.personal-details-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .details-close-button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-inline: 30px;
    padding-block-end: 40px;
    align-items: center;

    & > .personal-details-close-button {
      display: flex;
      background-color: transparent;
      border: none;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    }

    @media (width < 1000px) {
      padding-block-end: 15px;
      padding-inline: 20px;

      & > .personal-details-close-button {
        & > img {
          max-width: 10px;
        }
      }
    }
  }

  & > .header-image-and-last-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > .last-login {
      padding-block-start: 18px;
      padding-block-end: 10px;
      color: #818181;
      font-size: 18px;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #e1e1e1;
    }

    @media (width < 1000px) {
      & > .image-container {
        & > img {
          width: 50px;
        }
      }

      & > .last-login {
        font-size: 14px;
      }
    }
  }

  & > .log-out-button {
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #818181;
    padding-block: 10px 20px;

    @media (width < 1000px) {
      font-size: 14px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
