.single-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > .product-pages-image-container {
        max-width: 50px;
        max-height: 50px;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}