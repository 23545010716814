@import "../../../../css/all";

.CalcSection {
  color           : $weCheckBlue;
  justify-content : center;
  justify-items   : center;
  margin-top      : calculateRem(70);
  text-align      : center;
  @include respond-to("handhelds") {
	margin-top : calculateRem(35);
	}

  .textWithLogo {
	align-items     : center;
	display         : flex;
	flex-flow       : row;
	justify-content : center;

	img {
	  margin-right : calculateRem(5);
	  width        : 140px;
	  @include respond-to("handhelds") {
		width : 100px;
		}
	  }

	h3 {
	  margin-left  : calculateRem(6);
	  margin-right : calculateRem(6);
	  }
	}

  .textSection {
	@include respond-to("handhelds") {
	  margin : 0 auto;
	  width  : 90%;
	  }

	h4 {
	  @include respond-to("handhelds") {
		font-size : calculateRem(20);
		}
	  }
	}

  h1 {
	font-weight : bold;
	}

  .buttonBox {
	margin-top : calculateRem(40) !important;

	@include respond-to("handhelds") {
	  margin-top : calculateRem(20) !important;
	  }
	}

  .calcImg {
	margin-bottom : calculateRem(20);
	margin-top    : calculateRem(20);
	width         : 20%;
	@include respond-to("handhelds") {
	  width : 85%;
	  }
	}
  }
