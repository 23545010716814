.card-list-container {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 0 120px;
    z-index: 1;
    @media (width < 1000px) {
        grid-template-columns: 1fr;
        gap: 50px;
        grid-auto-rows: auto;
    }
}