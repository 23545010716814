@import "../../../../../components/product-pages/animations.scss";

.aia-fourth-section {
    & > .text-and-cta {
        display: flex;
        flex-direction: column;
        gap: 40px;

        & > .cta-container {
            align-items: flex-start;
            gap: 25px;
        }

        & > .text-section-container {
            transform: translateX(100%);
        }

        &.in-view {
            & > .text-section-container {
                animation: slideFromRight 1s;
                animation-fill-mode: forwards;
            }   
        }
    }

    & > .safecheck-image {
        justify-content: center;
        & > img {
            max-width: 500px;
        }
    }
    @media (width < 1000px) {
        gap: 50px;
        & > * {
            width: 100%;
        }
    }
}