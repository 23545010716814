.newsletter-cta-container {
  display: flex;
  gap: 20px;
  position: absolute;
  right: 60px;
  top: 10px;

  @media (width < 1000px) {
    right: 15px;
    gap: 12px;
  }

  & > .double-check-cta {
    border: none;
    font-size: 24px;
    background-color: var(--light-blue);
    color: var(--white);
    min-width: 270px;
    max-width: 270px;
    text-align: center;
    padding-block: 10px;
    border-radius: 5px;
    transition: background-color 0.1s ease-in-out;
    text-decoration: none;
    font-size: var(--font-size-lg);
    transition: opacity 0.2s ease-in-out;

    &.white-background {
      background-color: var(--white);
      color: var(--dark-blue);

      @media (width < 1000px) {
        font-weight: 600;
      }
    }

    &.is-open {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }

    @media (width < 1000px) {
      font-size: 16px;
      font-weight: 700;
      padding-block: 6px;
      padding-inline: 5px;
      min-width: unset;
      max-width: unset;
    }
  }

  & > .buttons-container {
    position: absolute;
    top: 0;
    background-color: var(--white);
    border-radius: 5px;
    padding-inline: 30px;
    padding-block: 20px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in-out, transform 0.2s linear;
    transform-origin: top right;
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-md);

    & > h4 {
      font-size: var(--font-size-lg);
      text-align: center;
    }

    &.is-open {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }

    & > .buttons-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      min-width: max-content;
      justify-content: space-between;
      & > * {
        flex-direction: column;
        gap: 0;
        text-align: center;
        min-width: max-content;
        flex: 1;
        & > * {
          font-size: var(--font-size-md);
          color: var(--dark-blue);
        }
      }
    }
  }
}
