@import "../../../../../css/all";
@import "../../../../../rent/pages/main/index.scss";

$maxCalcWidth : calculateRem(1200);
$fieldWidth   : calculateRem(290);
$fieldHeight  : calculateRem(60);

.CalculatorSettings {
  display         : flex;
  flex-direction  : column;
  gap             : 40px;
  justify-content : center;
  margin          : 0 auto;
  width           : 100%;

  & > .content-container {
	margin : 0 auto;
	@include respond-to("handhelds") {
	  width : 100%;
	  }
	}

  .customDrop {
	span {
	  width : 100% !important;
	  }

	p {
	  margin-top    : 10px;
	  padding-right : 5px;
	  width         : 100%;
	  }

	.arrow {
	  margin-left : 4px;
	  margin-top  : 10px;
	  }
	}

  @include respond-to("handhelds") {
	width : 100%;
	}

  .resultsSection {
	display    : flex;
	flex-flow  : column;
	margin-top : calculateRem(10);

	@include respond-to("handhelds") {
	  margin-bottom : 20px;
	  }

	.mobileOnly {
	  img {
		width : 53px !important;
		}
	  }

	.offer {
	  height     : $fieldHeight;
	  margin     : 0 auto;
	  margin-top : 20px;
	  width      : 200px;

	  @include respond-to("handhelds") {
		height     : 40px;
		margin-top : 32px;
		width      : 170px;
		}

	  a {
		color           : white;
		font-size       : 18px;
		text-decoration : none;
		@include respond-to("handhelds") {
		  font-size : 16px;
		  }
		}

	  span {
		width : 250px;
		}

	  .arrow {
		border       : solid white;
		border-width : 0 5px 5px 0;
		display      : inline-block;
		margin-right : 20px;
		margin-top   : 5px;
		padding      : 5px;
		@include respond-to("handhelds") {
		  border-width : 0 3px 3px 0;
		  display      : none;
		  margin-top   : -12px;
		  padding      : 3px;
		  }
		}

	  .left {
		transform : rotate(135deg);
		}
	  }

	.disableField {
	  opacity : 0.5;
	  }

	p {
	  font-size     : 26px;
	  line-height   : $fieldHeight;
	  padding-right : 10px;
	  text-align    : right;
	  @include respond-to("handhelds") {
		font-size   : 24px;
		line-height : 40px;
		}
	  }

	.boldGreen {
	  color       : $lightGreen;
	  font-weight : bold;
	  }

	.textAndResultBox {
	  display         : flex;
	  flex-flow       : column;
	  justify-content : right;

	  justify-items   : right;
	  margin-top      : calculateRem(10);
	  }

	.twoBoxes {
	  display         : flex;
	  flex-flow       : row;
	  flex-grow       : 1;
	  justify-content : space-between;
	  margin-top      : calculateRem(10);

	  p {
		width : $fieldWidth;
		@include respond-to("handhelds") {
		  text-align : right;
		  width      : 170px;
		  }
		}
	  }

	p {
	  background    : #F4F4F4 0% 0% no-repeat padding-box;
	  border-radius : 5px;
	  height        : $fieldHeight;
	  margin-top    : calculateRem(5);
	  @include respond-to("handhelds") {
		height : 40px;
		}
	  }

	h3 {
	  font-size   : calculateRem(15);
	  font-weight : bold !important;
	  text-align  : right;

	  @include respond-to("handhelds") {
		font-size : calculateRem(14) !important;
		}
	  }

	.LogoLine {
	  justify-content : right;

	  img {
		margin-right : 0 !important;
		width        : 75px;
		@include respond-to("handhelds") {
		  width : 60px;
		  }
		}
	  }
	}

  .progressSection {
	box-shadow     : 0px 8px 15px #054F9933;
	display        : flex;
	flex-flow      : column;
	height         : auto;
	padding-bottom : 20px;
	width          : 600px !important;

	@include respond-to("handhelds") {
	  width : 100% !important;
	  }

	.smallImg {
	  margin-bottom : calculateRem(5);
	  margin-right  : calculateRem(5);
	  width         : calculateRem(16);
	  }

	h5 {
	  font-size    : 25px;
	  margin-right : 20px;
	  margin-top   : 20px;

	  text-align   : right;
	  @include respond-to("handhelds") {
		font-size  : 20px;
		margin-top : 10px;
		}
	  }

	.slider {
	  direction    : ltr !important;

	  margin-right : 20px;
	  width        : 90%;
	  }

	input {
	  background    : #FFFFFF 0% 0% no-repeat padding-box;
	  border        : 1px solid #054F99;
	  border-radius : 5px;
	  color         : $peacock-blue;
	  font-size     : 20px;
	  height        : 42px;
	  margin-right  : 20px;
	  margin-top    : 10px !important;
	  text-align    : center;
	  width         : 90%;
	  }
	}

  .CalcSettings {
	align-items     : center;
	display         : flex;
	flex-flow       : column;
	justify-content : center;
	min-width       : calculateRem(300);
	width           : 600px !important;

	@include respond-to("handhelds") {
	  min-width : unset !important;
	  width     : 100% !important;
	  }

	.assetChoices {
	  display         : flex;
	  flex-flow       : column;
	  justify-content : right;
	  justify-items   : right;
	  width           : 100%;

	  .propertyButtons {
		display   : flex;
		flex-flow : row;

		.tabDiv {
		  display         : flex;
		  height          : 50px;
		  justify-content : right !important;
		  justify-items   : center;
		  text-align      : right;
		  width           : 140px;

		  @include respond-to("handhelds") {
			height : 45px;
			}

		  p {
			font-size    : calculateRem(17);
			font-weight  : bold;
			margin-right : 10px;
			margin-top   : 10px;
			@include respond-to("handhelds") {
			  font-size : calculateRem(15);
			  }
			}

		  &--blue {
			background-color : $weCheckBlue;
			border           : calculateRem(2) solid $weCheckBlue !important;
			border-radius    : 0 !important;
			color            : #FFFFFF;

			&:hover {
			  background-color : darken($weCheckBlue, 5%);
			  }
			}

		  &--lightBlue {
			background-color : $weCheckBlue !important;
			border           : calculateRem(2) solid $weCheckBlue;
			color            : white;
			opacity          : 0.5;

			&:hover {
			  background-color : darken($weCheckBlue, 5%);
			  }
			}
		  }
		}

	  .dealType {
		align-items      : center;
		background-color : $weCheckBlue;
		display          : flex;
		height           : 80px;
		justify-content  : right;
		padding          : calculateRem(10);
		width            : 100%;

		.comboDiv {
		  color     : white;
		  display   : flex;
		  flex-flow : column;
		  width     : 120px;

		  ul {
			overflow-y : unset;
			}

		  .CSDrop {
			background-color : white;
			color            : $weCheckBlue !important;

			li {
			  color     : $weCheckBlue !important;
			  font-size : 15px;
			  }

			span {
			  border     : none;
			  color      : $weCheckBlue;
			  height     : 30px;
			  text-align : center;
			  width      : 140px;
			  }
			}

		  p {
			font-size     : calculateRem(15);
			margin-bottom : 3px;
			text-align    : right;
			}
		  }

		.comboDivLong {
		  @extend .comboDiv;

		  width : 150px;

		  @include respond-to("handhelds") {
			width : unset !important;
			}

		  .CSDrop {
			span {
			  width : 140px;
			  @include respond-to("handhelds") {
				width : 140px;
				}
			  }
			}
		  }

		.comboDivLeft {
		  @extend .comboDiv;

		  margin-right : 10px;

		  width        : 150px !important;
		  }

		.dealTypeButtons {
		  display   : flex;
		  flex-flow : row;

		  a {
			margin-left : calculateRem(10);
			width       : 110px;
			}
		  }
		}
	  }

	.calcType {
	  @include respond-to("handhelds") {
		}

	  a {
		margin-bottom : calculateRem(10);
		}
	  }

	.btn {
	  align-items     : center;
	  display         : flex;
	  height          : 50px;
	  justify-content : center;
	  width           : 140px;

	  &--blue {
		@extend .btn;
		background-color : $weCheckBlue;
		border           : calculateRem(2) solid $weCheckBlue !important;
		border-radius    : 0 !important;
		color            : #FFFFFF;

		&:hover {
		  background-color : darken($weCheckBlue, 5%);
		  }
		}

	  &--lightBlue {
		@extend .btn;
		background-color : $weCheckBlue !important;
		border           : calculateRem(2) solid $weCheckBlue;
		color            : white;
		opacity          : 50%;

		&:hover {
		  background-color : darken($weCheckBlue, 5%);
		  }
		}
	  }

	h5 {
	  font-weight   : bold;
	  margin-bottom : calculateRem(10);
	  margin-top    : calculateRem(30);
	  }

	button {
	  border        : calculateRem(1) solid #FFFFFF;
	  border-radius : 24px;
	  box-shadow    : 0px 0px 6px #00000080;
	  height        : 47px;
	  margin-top    : calculateRem(20);
	  width         : 126px;
	  }
	}
  }
