.plus-minus-icon {
  align-items     : center;
  display         : flex;
  justify-content : flex-end;
  max-width       : 20px;
  min-width       : 20px;
  position        : relative;

  & > .line {
	background-color : #90B0D0;
	border-radius    : 1px;
	height           : 3px;
	position         : absolute;
	transition       : transform 0.1s ease;

	width            : 14px;

	&.horizontal {
	  transform : rotate(90deg);
	  }
	}

  &.animate {
	& > .line {
	  &.horizontal {
		transform : rotate(180deg);
		}
	  }
	}
  }
