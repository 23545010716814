@import "../../../../../../../css/all";

.GenderSelect {
  width   : 300px;
  display : flex;

  @include respond-to("medium-handhelds") {
	margin-bottom   : 0;
	margin-top      : 0;
	justify-content : space-around;
	width           : 200px;
	}

  .AGender {
	display   : flex;
	flex-flow : column;

	p {
	  margin-top : 10px;
	  @include respond-to("medium-handhelds") {
		font-weight : normal;
		}
	  }
	}

  .selctedImg {
	box-shadow : 0 2px 12px 1px $weCheckBlue;
	}

  img {
	width         : 125px;
	border-radius : 50px;
	@include respond-to("medium-handhelds") {
	  width         : 65px;
	  border-radius : 50px;
	  }
	}
  }

.GenderBox {
  display         : flex;
  flex-flow       : column;
  justify-content : center;
  margin          : 0 auto;

  .GenderSelect {
	img {
	  width : 70px;
	  }

	justify-content : space-between;
	}
  }
