@import "../../../../../css/all";

.LoansContainer {
  background     : linear-gradient(
				  to top,
				  #1A2D55 0%,
				  #284780 60%,
				  #F7F9FC 60%,
				  #F7F9FC 100%
  );
  display        : flex;
  flex-direction : column;
  gap            : 85px;
  padding-block  : 60px;
  padding-inline : 8vw;
  @include respond-to("handhelds") {
	align-items   : center;
	background    : #F7F9FC;
	gap           : 20px;
	padding-block : 40px;
	}

  & > .title-container {
	color : #284780;

	&.in-view {
	  animation : slideFromRight 1s;
	  }

	& > h2 {
	  font-size   : clamp(30px, 3.4vw, 64px);
	  font-weight : 800;
	  }
	}

  & > .content-container {
	display         : flex;
	gap             : 16px;
	justify-content : center;
	position        : relative;
	width           : 100%;

	& > .decorative-image {
	  & > img {
		width : min(6vw, 130px);
		}

	  @include respond-to("handhelds") {
		left     : -14%;
		position : absolute;
		top      : -5%;
		& > img {
		  width : 55px;
		  }
		&:first-child {
		  right : -6%;
		  top   : 42%;
		  }
		}
	  }

	& > .cards {
	  display : flex;
	  gap     : 8.5vw;
	  @include respond-to("handhelds") {
		flex-direction : column;
		padding-inline : 20px;
		z-index        : 2;
		}

	  & > .card-container {
		align-items      : center;
		background-color : #FFFFFF;
		border-radius    : 27px;
		box-shadow       : 5px 3px 50px #0098FF29;
		display          : flex;
		flex             : 1;
		flex-direction   : column;
		gap              : 14px;
		max-width        : 560px;
		padding-block    : 70px;
		padding-inline   : min(4vw, 75px);
		width            : calc(50% - 4.25vw);
		@include respond-to("handhelds") {
		  gap           : 8px;
		  padding-block : 20px;
		  width         : auto;
		  }

		&.in-view {
		  animation : slideFromLeft 1s;
		  }

		&:first-child {
		  &.in-view {
			animation : slideFromRight 1s;
			}
		  }

		& > .image-container {
		  & > img {
			max-width : 7.5vw;
			}

		  @include respond-to("handhelds") {
			& > img {
			  max-width : 75px;
			  }
			}
		  }

		& > .title-container {
		  align-self : flex-start;
		  @include respond-to("handhelds") {
			align-self : center;
			text-align : center;
			}

		  & > * {
			line-height : 100%;
			min-width   : max-content;
			}

		  & > h3 {
			color       : #284780;
			font-size   : clamp(24px, 2.6vw, 50px);
			font-weight : 700;
			}

		  & > h4 {
			color     : #98C1D9;
			font-size : clamp(16px, 1.7vw, 31px);
			}
		  }

		& > h5 {
		  align-self : flex-start;
		  color      : #3D5A80;
		  font-size  : clamp(17px, 1.9vw, 35px);
		  @include respond-to("handhelds") {
			align-self  : center;
			font-weight : 600;
			}
		  }

		& > .content-container {
		  align-self : flex-start;
		  @include respond-to("handhelds") {
			align-self : center;
			text-align : center;
			}

		  & > p {
			color     : #3D5A80;
			font-size : clamp(16px, 1.1vw, 20px);
			}
		  }
		}
	  }
	}

  & > .cta-container {
	align-items    : center;
	display        : flex;
	flex-direction : column;
	gap            : 60px;

	&.in-view {
	  animation : slideFromRight 1s;
	  }

	@include respond-to("handhelds") {
	  gap : 20px;
	  }

	& > a {
	  border-radius  : 10px;
	  font-size      : clamp(16px, 1.2vw, 22px);
	  height         : auto;
	  padding-block  : 20px;
	  padding-inline : 1.5vw;
	  @include respond-to("handhelds") {
		padding-block : 10px;
		width         : calc(100% - 2vw);
		}
	  }

	& > p {
	  color           : #FFFFFF;
	  display         : flex;
	  font-size       : clamp(18px, 1.6vw, 30px);
	  font-size       : 30px;
	  gap             : 0.5vw;
	  justify-content : center;
	  min-width       : 100%;

	  & > * {
		display : flex;
		gap     : 0.5vw;
		width   : max-content;

		& > img {
		  max-width : 178px;
		  width     : 9vw;
		  }
		}

	  @include respond-to("handhelds") {
		align-items    : center;
		color          : #004680;
		flex-direction : column;
		font-weight    : 700;
		& > * {
		  gap : 4px;

		  & > img {
			width : 90px;
			}
		  }
		}
	  }
	}

  &.mort-page {
	background : linear-gradient(
					to top,
					#1A2D55 0%,
					#284780 60%,
					transparent 60%,
					transparent 100%
	);
	@include respond-to("handhelds") {
	  background : linear-gradient(
					  to top,
					  #1A2D55 0%,
					  #284780 70%,
					  transparent 70%,
					  transparent 100%
	  );
	  transform  : translateY(-100px);
	  }

	& > .title-container {
	  display : none;
	  }

	& > .content-container {
	  & > .decorative-image {
		display : none;
		}

	  & > .cards {
		gap : 2vw;
		@include respond-to("handhelds") {
		  gap : 30px;
		  }

		& > .card-container {
		  width : min(540px, 28vw);
		  @include respond-to("handhelds") {
			width : auto;
			}
		  }
		}
	  }


	}

  & > .cta-container {
	@include respond-to("handhelds") {
	  display : none;
	  }

	& > a {
	  display : none;
	  }
	}
  }
