@import "../../../../../components/product-pages/animations.scss";

.safecheck-third-section {
    &>.text-and-cta {
        display: flex;
        flex-direction: column;
        gap: 40px;
        transform: translateX(100%);

        &.in-view {
            animation: slideFromRight 1s;
            animation-fill-mode: forwards;
        }
    }

    &>.safecheck-image {
        justify-content: center;

        &>img {
            max-width: 500px;
        }
    }

    @media (width < 1000px) {
        gap: 50px;

        &>* {
            width: 100%;
        }
    }
}