.slider-container {
  width: 100%;
  margin: auto;
  text-align: center;

  .slider-label {
    font-size: 16px;
    direction: rtl;
    color: #1d3557;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .slider-title {
    color: #1d3557;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    direction: rtl;
    text-align: right;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: #d3d3d3;
    outline: none;
    transition: opacity 0.2s;
    position: relative;
    border-radius: 5px;
    z-index: 0;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      background: transparent;
    }

    &::-moz-range-track {
      width: 100%;
      height: 6px;
      background: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background: #1799fc;
      border: 5px solid white;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 2;
      margin-top: -6px; /* Adjust to center the thumb */
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      background: #1799fc;
      border: 2px solid white;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 2;
    }

    &::-moz-range-progress {
      background: #1799fc;
      height: 6px;
    }
  }
}
