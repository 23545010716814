

.additional-mort-third-section {
    position: relative;
    & > .product-pages-image-container {
        position: absolute;
        top: min(120px, 10cqi);
        left: clamp(26px, 6.3cqi, 130px);
        width: min(700px, 36.4vw);

        & > * {
            width: 100%;
        }

        @media (width < 1500px) {
            display: none;
        }
    }
    &>.animation-cta-container {
        grid-column: span 2;
        display: flex;
        justify-content: center;

    
        &>.cta-animation-click-container {
            width: 1200px;

            &>.animation-container {
                right: min(410px, 30vw);

                @media (width < 1000px) {
                    right: -20px;
                }
                
            }

            &>.cta-container {
                width: 100%;
                align-items: center;
                &>* {
                    min-width: max-content;

                }

            }
        }
    }
}