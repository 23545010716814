.date-range-picker-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);

  & > h4 {
    font-size: var(--font-size-md);
    font-weight: 500;
    text-align: center;
    max-height: 30px;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;

    &.hide-row {
      max-height: 0;
    }
  }
}
