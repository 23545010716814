.scroll-indicator-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>* {
        &>svg {
            width: 60px !important;
        }
    }

    &>p {
        color: var(--light-blue-text);
    }
}