.additional-mort-second-section {
    align-items: center;
    gap: 60px 200px;


    &>.video-container {
        grid-row: span 2;

        @media (width < 1000px) {
            grid-row: span 1;
        }
    }

    &>.airplane-animation-and-cta {
        position: relative;

        &>.animation-container {
            z-index: 1;
            pointer-events: none;
            position: absolute;
            top: 0;
            right: -50%;
            & > * {
                min-width: 1000px;
            }
        @media (width < 1000px) {
            right: auto;
            & > * {
                min-width: 0;
            }
        }
        }

        &>.cta-container {
            &>.animate-appearance {
                opacity: 0;
                animation: changeOpacity 0.5s;
                animation-delay: 1s;
                animation-fill-mode: forwards;
            }

            align-items: flex-start;
            grid-column: span 1;

            @media (width < 1000px) {
                align-items: center;
            }
        }
    }
}