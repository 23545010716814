@import "../../../../../css/all";

.WhatWeSaySection {
  display    : flex;
  margin-top : 40px;
  width      : 100%;

  @include respond-to("handhelds") {
	margin-top : 60px;
	}

  .container {
	display       : flex;
	padding-right : 125px;
	width         : 100%;

	@include respond-to("medium-handhelds") {
	  display       : flex;
	  flex-flow     : column;
	  padding-right : 0;
	  }

	.textContent {
	  padding   : 40px 0 0;
	  max-width : 900px;

	  @include respond-to("handhelds") {
		padding : 28px;
		}

	  h2 {
		font-size   : calculateRem(40);
		font-weight : bold;
		@include respond-to("handhelds") {
		  font-size    : calculateRem(25);
		  margin-right : 20px;
		  }
		}

	  .shortList {
		width : 70%;

		@include respond-to("handhelds") {
		  width : 90%;
		  }
		}

	  .longList {
		width : 90%;
		}

	  .IconInfoLine {
		display       : flex;
		flex-flow     : row;
		margin-bottom : 20px;

		p {
		  @include respond-to("handhelds") {
			font-size : calculateRem(16);
			}
		  }

		img {
		  width       : 24px;
		  margin-left : 10px;
		  margin-top  : 5px;
		  @include respond-to("handhelds") {
			width : 14px;
			}
		  }
		}
	  }

	.buttonDiv {
	  a {
		width     : 360px;
		font-size : calculateRem(24);
		@include respond-to("medium-handhelds") {
		  width     : 358px;
		  margin    : 0 auto;
		  font-size : calculateRem(18);
		  height    : 50px;
		  }
		}

	  }

	.image {
	  display         : flex;
	  justify-content : center;
	  flex            : 1 1;
	  @include respond-to("medium-handhelds") {
		display : none;
		}

	  img {
		width : 308px;
		}
	  }


	}


  }


