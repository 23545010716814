@import "../../../../../../../../css/all";

.TenantDetailsSlide {
  display : flex;
  @include respond-to("medium-handhelds") {
	width : 100%;
	}

  .btn--gray {
	pointer-events : none;
	background     : $weCheckAzure;
	opacity        : 50%;
	}

  .dataInput {
	:first-child {
	  margin-top : 10px;
	  @include respond-to("medium-handhelds") {
		margin-top : 0;
		}
	  }
	}


  .buttons {
	display         : flex;
	justify-content : center;
	@include respond-to("medium-handhelds") {
	  justify-content : space-between;
	  }

	.buttonDiv:first-child {
	  margin-left   : 20px;
	  width         : 73px;
	  border        : 1px solid #054F99;
	  border-radius : 10px;
	  @include respond-to("medium-handhelds") {
		width       : 50px;
		margin-left : 0;
		}

	  img {
		margin-top : 23px;
		@include respond-to("medium-handhelds") {
		  margin-top : 16px;
		  }
		}
	  }

	.buttonDiv {
	  margin-top : 40px;
	  @include respond-to("medium-handhelds") {
		margin-top : 8px;
		}

	  a {
		margin    : 0 auto;
		width     : 280px;
		font-size : calculateRem(24);
		height    : 64px;
		@include respond-to("medium-handhelds") {
		  width     : 214px;
		  margin    : 0 auto;
		  font-size : calculateRem(20);
		  height    : 50px;
		  }
		}

	  }

	}
  }