.search-component-container {
  width: clamp(330px, 21.1vw, 404px);
  min-height: min(200px, 13vw);
  background-color: var(--gray);
  border-radius: 10px;
  padding-block-start: 16px;
  padding-block-end: clamp(16px, 1.3vw, 28px);
  padding-inline: clamp(16px, 1.2vw, 25px);
  overflow-x: clip;
  position: relative;
  z-index: 1;
  align-self: flex-end;
  transition: height 0.2s linear;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  &.data-view-visible,
  &.is-logged-in {
    height: auto;
  }

  &.is-logged-in {
    overflow-x: visible;
    min-height: 0;
    z-index: 0;
  }

  @media (width < 1000px) {
    position: absolute;
    width: 100%;
    max-height: none;
    left: 0;
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    padding-inline: 38px;
    padding-block-start: 40px;
    padding-block-end: 30px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    height: 500px;
    transform: translateY(calc(100% - 80px));
    background-color: var(--white);
    z-index: 0;
    gap: unset;

    & > .hidden-click-to-close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 38px;
      pointer-events: none;
      z-index: 2;
    }

    & > * {
      transition: opacity 0.2s ease-in-out;
      transition-delay: 0.2s;

      &:not(.mobile-closed-display) {
        opacity: 0;
      }
    }

    &.is-logged-in {
      height: 430px;
      transform: translateY(340px);
    }

    &.is-open {
      z-index: 2;
      transform: translateY(0);
      background-color: var(--gray);
      height: 510px;

      &:not(.is-logged-in) {
        height: 265px;
      }

      & > .hidden-click-to-close {
        pointer-events: all;
      }

      & > .mobile-closed-display {
        opacity: 0;
        pointer-events: none;
      }

      & > * {
        &:not(.mobile-closed-display) {
          opacity: 1;
        }
      }
    }

    &::after {
      content: " ";
      position: absolute;
      width: 100px;
      height: 4px;
      top: 15px;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: var(--dark-blue);
      border-radius: 3px;
    }

    &.slide-down {
      transform: translateY(100%);
    }
  }
}

@keyframes flicker {
  0% {
    background-color: var(--gray);
  }

  50% {
    background-color: var(--white);
  }

  100% {
    background-color: var(--gray);
  }
}
