.login-line,
.login-line * {
    overflow: visible;
    color: var(--light-blue-text);
    font-size: var(--font-size-md);
    text-align: center;
    min-width: max-content;

    &>a {
        &:hover {
            text-decoration: underline;
        }
    }

    @media (width < 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}