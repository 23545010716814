.SuccessMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 1s ease;
    gap: 20px;
    z-index: -1;

    &.visible {
        opacity: 1;
        z-index: 0;
    }

    &>.text-container {
        &>h3 {
            text-align: center;
            font-size: clamp(24px, 1.8vw, 36px);

            &::first-line {
                font-weight: 700;
            }
        }
    }
}