@import "../../css/all";

.wckForm__input {
  position        : relative;
  height          : calculateRem(31);
  display         : flex;
  flex-flow       : column;
  justify-content : flex-end;

  margin          : calculateRem(4) 0 10px;

  &--error {
	background-color : rgba(237, 28, 36, 0.11);

	.wckForm__input {
	  &__focusBorder {
		background-color : $pinkish-red;
		width            : 100%;
		left             : 0;
		}

	  &__label {
		color : $pinkish-red;
		}
	  }

	input {
	  color : $pinkish-red !important;
	  }
	}

  &--disabled {
	.wckForm__input__label {
	  color : #939393;
	  }

	input {
	  color : #939393 !important;
	  }
	}

  &__focusBorder {
	background-color : $light-blue-grey;
	position         : absolute;
	bottom           : 0;
	left             : 50%;
	width            : 0;
	height           : calculateRem(2);
	transition       : 0.4s;
	}

  &__noFocusBorder {
	background-color : $light-blue-grey;
	position         : absolute;
	bottom           : 0;
	left             : 50%;
	width            : 0;
	height           : calculateRem(22);
	transition       : 0.4s;
	}

  &__label {
	right          : 0;
	padding        : calculateRem(5) calculateRem(12) 0;
	width          : 100%;
	height         : 100%;
	position       : absolute;
	display        : none;
	font-weight    : normal !important;
	color          : $weCheckBlue;
	pointer-events : none;
	transition     : .2s;
	margin-top     : 0 !important;

	&--focused {
	  padding-top : calculateRem(3);
	  font-size   : calculateRem(12);
	  font-weight : normal !important;
	  }
	}

  input {
	background    : transparent;
	height        : calculateRem(31);
	border        : 0;
	border-bottom : 1px solid rgb(6, 79, 153);

	color         : $weCheckBlue;
	width         : 100%;
	transition    : 0.2s;
	outline       : none;

	&:disabled {
	  opacity: 0.5;
	  }
	}

  ::placeholder {
	color     : $weCheckBlue;
	font-size : calculateRem(18);
	}


  .inputEx {

	&:focus ~ .wckForm__input__focusBorder {
	  width : 100%;
	  left  : 0;
	  }
	}

  &__simple {
	background : transparent;
	height     : calculateRem(50);
	border     : 0;
	padding    : calculateRem(14) calculateRem(12) 0;
	color      : $weCheckBlue;
	width      : 100%;
	transition : 0.2s;
	outline    : none;

	&:disabled {
	  color        : $black-color !important;
	  border-color : #A2BEDA;
	  }

	::placeholder {
	  color : red !important;
	  }

	&:disabled::-webkit-input-placeholder {
	  color : #A2BEDA !important;
	  }

	&:disabled:-moz-placeholder {
	  color : #A2BEDA !important;
	  }

	&:disabled::-moz-placeholder {
	  color : #A2BEDA !important;
	  }

	&:disabled:-ms-input-placeholder {
	  color : #A2BEDA !important;
	  }
	}

  &__classic {
	@extend .wckForm__input__simple;

	&:focus ~ .wckForm__input__focusBorder {
	  width : 100%;
	  left  : 0;
	  }
	}


  }

