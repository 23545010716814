@import "../../../../css/all";

.ProductCube {
  background-color : white;
  border-radius    : 20px;
  box-shadow       : 0 3px 50px #0098FF3B;
  height           : 550px;
  margin-left      : calculateRem(30);
  min-width        : 387px;
  padding          : calculateRem(30);
  width            : 387px;

  @include respond-to("handhelds") {
	height    : unset !important;
	margin    : 20px auto 0;
	min-width : unset;
	padding   : calculateRem(10);
	width     : 90%;
	}

  p {
	color       : $weCheckBlue;
	font-size   : calculateRem(17);
	font-weight : normal;
	margin-top  : calculateRem(10);
	text-align  : center;
	}

  .content {
	img {
	  height : 100px;
	  width  : 100%;
	  }

	.title {
	  font-size   : calculateRem(36);
	  font-weight : bold;
	  margin-top  : 0;
	  }
	}

  .productLink {
	display: flex;
	justify-content: center;
	bottom      : 30px;
	color       : $weCheckAzure;
	font-size   : 18px;
	cursor      : pointer;
	font-weight : bold;
	position    : relative;
	@include respond-to("handhelds") {
	  bottom        : unset;
	  margin-bottom : 30px;
	  margin-top    : 30px;
	  }
	}
  }