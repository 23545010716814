@import "../../../../css/all";

.ExplainSection {
  color           : $weCheckBlue;
  justify-content : center;
  justify-items   : center;
  text-align      : center;

  .textWithLogo {
	align-items     : center;
	display         : flex;
	flex-flow       : row;
	justify-content : center;
	margin-top      : calculateRem(20);

	img {
	  width : 110px;
	  }

	h3 {
	  margin-left  : calculateRem(6);
	  margin-right : calculateRem(6);
	  }
	}

  .topPart {
	margin-bottom : calculateRem(30);
	@include respond-to("handhelds") {
	  margin-bottom : calculateRem(10);
	  }

	.LogoLine {
	  h3 {
		font-weight : bold !important;
		@include respond-to("handhelds") {
		  font-size : calculateRem(25) !important;
		  }
		}
	  }
	}

  .twoCubes {
	display   : flex;
	flex-flow : row;
	margin    : 0 auto;
	padding   : calculateRem(10);
	width     : calculateRem(1000);

	@include respond-to("handhelds") {
	  width : unset;
	  }

	.cube {
	  align-items : center;
	  border      : calculateRem(2) solid $mortBorder;
	  display     : flex;
	  flex-flow   : column;
	  padding-top : calculateRem(5);
	  width       : 50%;

	  h3,
	  h5 {
		font-weight : normal;
		}

	  .logo {
		width : calculateRem(140);
		@include respond-to("handhelds") {
		  width : calculateRem(90);
		  }
		}

	  .symbol {
		margin-bottom : calculateRem(10);
		margin-top    : calculateRem(10);
		width         : calculateRem(20);
		@include respond-to("handhelds") {
		  margin : 0;
		  }
		}

	  .blueBox {
		align-items      : center;
		background-color : $weCheckBlue;
		border           : calculateRem(1) solid $weCheckBlue;
		border-radius    : calculateRem(5);
		color            : white;
		display          : flex;
		flex-flow        : column;
		height           : calculateRem(80);
		justify-content  : center;
		margin           : calculateRem(10) calculateRem(20);
		padding-bottom   : calculateRem(10);
		padding-top      : calculateRem(10);
		width            : 80%;
		width            : 90%;
		@include respond-to("handhelds") {
		  height : calculateRem(70);
		  }
		}

	  .blueBoxBig {
		@extend .blueBox;
		border-radius : 0;
		height        : 100px;
		margin        : unset !important;
		margin-top    : 15px !important;

		width         : 90%;
		@include respond-to("handhelds") {
		  width : 100%;
		  }
		}

	  .grayBox {
		align-items      : center;
		background-color : $mortGray;
		border           : calculateRem(1) solid $mortGray;
		border-radius    : calculateRem(5);
		display          : flex;
		flex-flow        : column;
		height           : calculateRem(80);

		justify-content  : center;
		margin           : calculateRem(10) calculateRem(20);
		width            : 90%;
		@include respond-to("handhelds") {
		  height : calculateRem(40);
		  }
		}

	  .grayBoxHouse {
		@extend .grayBox;
		height : calculateRem(140);
		@include respond-to("handhelds") {
		  height : calculateRem(80);
		  }

		.house {
		  width : calculateRem(90);
		  @include respond-to("handhelds") {
			width : calculateRem(60);
			}
		  }
		}
	  }

	.firstCube {
	  @extend .cube;
	  margin-left : calculateRem(5);
	  }

	.secondCube {
	  @extend .cube;
	  }
	}

  h2 {
	font-weight   : bold;
	margin-bottom : calculateRem(10);
	}

  h3 {
	font-size   : calculateRem(25);
	font-weight : bold;
	}

  h5 {
	font-size   : calculateRem(29);
	font-weight : bold;
	@include respond-to("handhelds") {
	  font-size : calculateRem(19);
	  }
	}

  .topSection {
	margin-top    : calculateRem(10);

	padding-left  : calculateRem(10);
	padding-right : calculateRem(10);

	h2 {
	  margin-bottom : calculateRem(10);
	  }

	.buttonBox {
	  margin : calculateRem(20) auto calculateRem(5);
	  width  : 97%;

	  a {
		font-size     : 23px;
		height        : calculateRem(70);
		margin-bottom : calculateRem(15);
		}

	  .calcImg {
		position : absolute;
		right    : 30px;
		}
	  }
	}
  }
