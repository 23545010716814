.room-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &>h4 {
        font-size: var(--font-size-md);
        font-weight: 600;

        @media (width < 1000px) {
            font-size: 16px;
        }
    }

    &>.room-list-container {
        display: flex;
        align-items: center;
        gap: 12px;

        &>.single-room {
            background-color: var(--white);
            box-shadow: 0 3px 6px var(--shadow);
            font-size: 14px;
            font-weight: 600;
            padding-inline: 18px;
            padding-block: 2px;
            border-radius: 5px;
            color: var(--dark-blue);
            transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                transform: scale(1.3);
                background-color: var(--light-blue);
                color: var(--white);
            }
        }
    }
}