@import "../../../../../css/all";

.SoWhatIsTheProcess {
  background : $extraDarkBlue;
  width: 100%;

  

  & > .content-container {
	&.in-view {
	  animation : slideFromLeft 1s;
	  }
	}

  .GridBox {
	direction             : ltr;
	display               : grid;
	grid-column-gap       : 0px;
	grid-row-gap          : 0px;
	grid-template-columns : repeat(24, 1fr);
	grid-template-rows    : repeat(12, 1fr);

	img {
	  max-width : 100%;
	  }

	.div1 {
	  align-items : flex-end;
	  display     : flex;
	  grid-area   : 1 / 13 / 3 / 18;
	  }

	.div2 {
	  grid-area : 3 / 16 / 10 / 20;
	  }

	.div3 {
	  grid-area : 3 / 11 / 10 / 15;
	  }

	.div4 {
	  grid-area : 3 / 6 / 10 / 10;
	  }

	.div5 {
	  grid-area : 1 / 20 / 5 / 22;
	  }

	.div6 {
	  grid-area : 11 / 18 / 13 / 20;
	  }

	.div7 {
	  grid-area : 10 / 8 / 12 / 13;
	  }

	.WhiteBox {
	  align-items    : center;
	  background     : white;
	  border-radius  : 1.5rem;
	  direction      : rtl;
	  display        : flex;
	  flex-direction : column;
	  gap            : 1rem;
	  min-height     : 100%;
	  min-width      : 100%;
	  padding        : 2rem;
	  position       : relative;
	  text-align     : center;

	  h3 {
		color       : #223C6E;
		font-size   : calculateRem(26);
		font-weight : bold;
		font-weight : 800;
		}

	  p {
		color       : #525252;
		font-size   : calculateRem(16);
		font-weight : 600;
		}

	  .ProcessIcon {
		max-width : 100px;
		}

	  .absoluteNumber {
		color       : #223C6E;
		font-size   : 3rem;
		font-weight : bold;
		line-height : 3rem;
		position    : absolute;
		right       : 1rem;
		top         : 1rem;
		}
	  }
	}

  img {

	&.in-view {
	  animation : slideFromRight 1s;
	  }
	}

  & > .cta-container {
	&.in-view {
	  animation : slideFromLeft 1s;
	  }
	}
  }
