.modal-text-section-container {
  & > h2 {
    font-size: 30px;
    font-weight: 700;
    @media (width < 600px) {
      font-size: 26px;
    }
  }
  & > p {
    font-size: 25px;
    white-space: pre-wrap;
    @media (width < 600px) {
      font-size: 16px;
    }
  }
}
