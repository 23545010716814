.reduced-mort-second-section {
  align-items: center;
  &__animation {
    & > * {
      width: min(490px, 24vw);
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    grid-column: span 2;
    gap: 50px;

    & > .item-list-container {
        @media (width < 1000px) {
          grid-column: span 1;
        }
        & > .item-list-list-container {
          display: grid;
          gap: 50px;
          grid-template-columns: repeat(3, 1fr);
    
          & > * {
            max-width: 400px;
          }
    
          @media (width < 1000px) {
            grid-template-columns: 1fr;
            & > * {
              &:nth-child(2) {
                grid-area: 3;
              }
              &:nth-child(3) {
                grid-area: 5;
              }
            }
          }
        }
      }
  }

 
  @media (width < 1000px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
    padding-block-end: 0;
    &__animation {
        display: flex;
        grid-area: 3;
      & > * {
        width: 100%;
      }
    }
  }
}
