.login-button-container{
    font-size: var(--font-size-lg);
    color: var(--white);
    background-color: var(--light-blue);
    padding-inline: 33px;
    padding-block: 10px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: var(--button-hover);
    }

    @media (width < 1000px) {
        width: calc(100% - 76px);
    }
}