@import "../../../../css/all";

.TopSection {
  .fixedImage {
	position : absolute;
	z-index  : -1;
	}

  .fixedLeftImage {
	@extend .fixedImage;
	left : 0;
	top  : 90px;
	@include respond-to("handhelds") {
	  display : none;
	  left    : unset;
	  right   : 0;
	  top     : 75px;
	  }
	}

  .fixedRightImage {
	@extend .fixedImage;
	right : 0;
	top   : 150px;
	@include respond-to("handhelds") {
	  display : none;
	  }
	}

  .bricksImage {
	margin-bottom : calculateRem(20);
	@include respond-to("handhelds") {
	  margin-bottom : calculateRem(10);
	  }
	}

  .textPart {
	width : 100%;

	@include respond-to("handhelds") {
	  margin-top : calculateRem(10) !important;
	  padding    : 10px;
	  top        : inherit;
	  }

	h2 {
	  font-size     : 70px;
	  font-weight   : normal;
	  margin-bottom : calculateRem(10);
	  @include respond-to("handhelds") {
		font-size   : 30px;
		font-weight : bold;
		}
	  }

	.smallLogo {
	  img {
		margin-right : 0 !important;
		width        : calculateRem(70) !important;
		}
	  }
	}

  .buttonBox {
	margin-top : calculateRem(50) !important;
	@include respond-to("handhelds") {
	  margin-top : calculateRem(20) !important;
	  }
	}
  }
