.video-player[open] {
  border: none;
  border-radius: 10px;
  width: 60%;
  height: 60%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (width < 1000px) {
    width: 100%;
    height: fit-content;
  }

  & > * {
    &:not(.close-button) {
      border-radius: 8px;
      padding: 0;
      width: 90%;
      height: 90%;
    }
  }
}
