.map-view-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    height: 100%;
    overflow: hidden;

    &>.map-image-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: calc(100% - 40px);

        &>img {
            height: 100%;
            width: 120px;
            padding-block-end: 10px;
        }
    }

    &>h3 {
        font-size: var(--font-size-md);
        font-weight: 700;
        margin-block-start: 0;
        margin-block-end: 0;
        min-width: max-content;
    }
}