.legal-disclaimer-container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-block: 15px;
  border-radius: 10px;
  padding-inline: 67px;
  max-width: 900px;

  @media (width < 1000px) {
    padding-inline: 20px;
  }

  & > .closed-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-xs);
    min-width: max-content;
    @media (width < 1000px) {
      min-width: 0;
    }
    & > .info-icon-container {
      display: flex;
      align-items: center;
    }

    & > .text-container {
      & > p {
        font-size: var(--font-size-xs);
        & > span {
          padding-inline-start: 16px;
          color: var(--light-blue-text);
          text-decoration: underline;
          transition: opacity 0.1s ease-in-out;
          &.hide {
            opacity: 0;
          }
          @media (width < 1000px) {
            padding-inline-start: 10px;
          }
        }
      }
    }
  }

  & > .legal-disclaimer-accordion {
    width: 100%;
    padding-inline-start: calc(20px + var(--gap-xs));
    padding-block-end: 0;
    transition: max-height 0.2s linear, opacity 0.2s linear;
    &.is-open {
      max-height: 150px;
    }
    & > p {
      font-size: var(--font-size-xs);
    }
  }
}
