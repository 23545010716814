@import "../../../variables.scss";

.form-container-mort-contact {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 50%;

    &>form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }

    @container (width < 540px) {
        &>form {
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;

            &>.submit-button-mort-contact {
                grid-column: span 1;
            }
        }
    }
    .legalDisclaimer{
        margin-top: -20px;
        font-size: var(--par-font-size-sm);
        @media (max-width: 768px) {
          text-align: center;
          font-size: var(--par-font-size-xs);
        }
    }
    @media (width < 1000px) {
        max-width: none;
        padding-bottom: 60%;
    }
}