@import "../../css/all.scss";
@import "../../rent/pages/main/index.scss";

.SectionWrapper {
  color   : #0062A8;
  padding : calculateRem(20) 0;

  &__title {
	align-items    : center;
	cursor         : default;
	display        : flex;
	flex-direction : column;
	text-align     : center;

	h2 {
	  font-size   : 45px;
	  font-weight : bold;
	  margin      : 0;
	  padding     : 0;
	  text-align  : right;
	  width       : 100%;
	  @include respond-to("handhelds") {
		font-size  : 30px;
		text-align : center;
		}
	  }

	h4 {
	  direction   : rtl;
	  line-height : calculateRem(40);
	  text-align  : right;
	  width       : 100%;
	  @include respond-to("handhelds") {
		text-align : center;
		}
	  }
	}

  &__container {
	@include respond-to("medium-handhelds") {
	  //   padding : calculateRem(20) calculateRem(1);
	  }
	}

  &__brikim {
	background-image : url("#{$s3ImagesLink}/shared/brikim.png");
	height           : calculateRem(25);
	width            : 100%;
	}
  }
