.reduced-mort-third-section {
  position: relative;
  @media (width < 1000px) {
    display: flex;
    flex-direction: column;
  }
  & > .reduced-mort-third-section-title {
    grid-column: span 2;
  }

  & > .reduced-mort-third-section-spread {
    position: absolute;
    width: min(560px, 29vw);
    left: -20px;
    bottom: 0;

    @media (width < 1000px) {
      display: none;
    }
  }
  & > .animation-cta-container {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    padding-block-start: 80px;

    @media (width < 1000px) {
      grid-column: span 1;
      padding-block-start: 0;
    }

    & > .cta-animation-click-container {
      width: 1200px;

      & > .animation-container {
        right: min(410px, 30vw);

        @media (width < 1000px) {
          right: -20px;
        }
      }

      & > .cta-container {
        width: 100%;
        align-items: center;

        & > * {
          min-width: max-content;
        }
      }
    }
  }
}
