@import "../../../../../../config/config.scss";
@import "../../../variables.scss";

.single-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    position: relative;

    &>p {
        white-space: pre-wrap;

        &::first-line {
            font-weight: bold;
        }
    }

    &>.icon-container {
        max-width: 50px;
    }

    @container (width < 1000px) {
        gap: 10px;
    }

    &>.text-section-container {
        width: 100%;
    }

    &.is-titled {
        &>.text-section-container {
            &>h3 {
                font-size: $paragraph-font;
                padding-block-end: 0;
            }
        }
    }

    &:not(:last-child, .is-titled) {
        &::after {
            content: url("#{$s3ImagesLink}/marketing/product-pages/shared/chevron-left.svg");
            position: absolute;
            left: -10px;
            top: 40%;

            @media (width < 1000px) {
                transform: rotate(270deg);
                left: auto;
                top: auto;
                bottom: -40%;
            }
        }
    }
}