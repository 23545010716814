@import "../../../../../../config/config.scss";

.VideoSection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding-block-start: 108px;
    padding-block-end: 400px;

    @media screen and (max-width: 570px) {
        padding-block-start: 50px;
        padding-block-end: 200px;
        gap: 10px;
    }

    &>.background {
        background: linear-gradient(to right bottom, #004680, #10406F, #183A5F, #1C334F, #1F2D40);
        height: 100%;
        width: 100vw;
        position: absolute;
        bottom: 0;
    }

    &>.title-container {
        z-index: 1;

        &>h2 {
            font-size: clamp(24px, 2vw, 40px);
            color: #FFFFFF;
        }
    }

    &>.video-image {
        background: url("#{$s3ImagesLink}/marketing/mort/landing-page/video-img.png");
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 1;
        width: min(800px, 40vw);
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-size 0.4s ease;

        @media screen and (max-width: 1400px) {
            width: 100%;

        }
        @media screen and (max-width: 570px) {
            height: 188px;
        }

        &>.image-container {
            display: flex;
            align-items: center;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.3);
            width: 108px;
            height: 108px;
            backdrop-filter: blur(25px);
            transition: background-color 0.2s ease;

            &>img {
                transform: translateX(-60%);
            }
            @media screen and (max-width: 570px) {
                width: 50px;
                height: 50px;
                & > img {
                    width: 21px;
                    height: 23px;
                }
            }

        }

        &:hover {
            background-size: 110%;
            cursor: pointer;

            &>.image-container {
                background-color: rgba(0, 0, 0, 0.6);

            }
        }
    }
}