@import "../../../../../css/all";

.HelpSection {
  display         : flex;
  flex-flow       : row-reverse;
  justify-content : center;
  padding-left    : 125px;
  margin-top      : 100px;
  @include respond-to("medium-handhelds") {
	margin-top   : 0;
	flex-flow    : column-reverse;
	padding-left : 0;
	}

  .HelpKit {
	display       : flex;
	flex-flow     : column;
	align-items   : center;
	margin-top    : 100px;
	margin-bottom : 200px;
	width         : 100%;

	@include respond-to("medium-handhelds") {
	  margin-top    : 30px;
	  margin-bottom : 0;
	  padding       : 22px;
	  }

	p {
	  font-size     : calculateRem(24);
	  margin-bottom : 30px;
	  @include respond-to("medium-handhelds") {
		font-size  : calculateRem(16);
		text-align : center;
		}
	  }

	img {
	  height        : 141px;
	  margin-bottom : 20px;
	  }

	a {
	  width     : 360px;
	  height    : 64px !important;
	  font-size : calculateRem(24) !important;
	  @include respond-to("medium-handhelds") {
		font-size     : calculateRem(18) !important;
		height        : 50px !important;
		margin-bottom : 50px;
		}
	  }
	}
  }