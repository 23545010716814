.details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  box-shadow: 0 -4px 10px #0000001a;
  border-radius: 20px 20px 0 0;
  padding-block-start: 30px;
  padding-inline: 30px;
  gap: 30px;

  @media (width < 1000px) {
    padding-block-start: 20px;
    padding-inline: 20px;
    gap: 20px;
  }

  & > .details-item-list-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    & > * {
      flex: 1;
    }

    @media (width < 1000px) {
      gap: 10px;
    }
  }
}
