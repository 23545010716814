.personal-area-layout-container {
  --font-size-xs: clamp(10px, 0.9vw, 14px);
  --font-size-sm: clamp(12px, 0.9vw, 16px);
  --font-size-sm2: clamp(14px, 0.9vw, 16px);
  --font-size-sm3: clamp(16px, 1vw, 18px);
  --font-size-sm4: clamp(14px, 1vw, 18px);
  --font-size-md: clamp(16px, 1.1vw, 20px);
  --font-size-md2: clamp(18px, 1.1vw, 20px);
  --font-size-lg: clamp(20px, 1.3vw, 24px);
  --font-size-lg2: clamp(24px, 1.6vw, 29px);
  --font-size-xl: clamp(20px, 2.7vw, 50px);
  --font-size-xxl: clamp(50px, 3.2vw, 61px);

  --gap-xxs: clamp(4px, 0.4vw, 6px);
  --gap-xs: clamp(5px, 0.55vw, 10px);
  --gap-sm: clamp(8px, 0.9vw, 18px);
  --gap-md: clamp(10px, 1.1vw, 20px);
  --gap-md-2: clamp(10px, 1.2vw, 25px);
  --gap-lg: clamp(10px, 1.6vw, 30px);
  --gap-xl: clamp(10px, 2.2vw, 40px);

  --gray: #f2f2f2;
  --gray-opacity: #f2f2f258;
  --gray-border: #707070;
  --white: #fff;
  --white-opacity: #ffffffe9;
  --light-blue: #1799fc;
  --light-blue-text: #2999fc;
  --dark-blue: #1d3557;
  --dark-blue-opacity: #1d3557e9;
  --green: #75c74e;
  --red: #f24a51;

  --button-hover: #0389ef;

  --shadow: #00000033;

  --container-heading-lg: clamp(24px, 6vw, 50px);
  --container-paragraph: clamp(16px, 1.5vw, 20px);

  color: #1d3557;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - 92px);
  height: calc(100dvh - 92px);

  @media (width < 1000px) {
    position: relative;
    max-height: 100dvh;

    &:has(.personal-details-drawer-container:is(:not(.is-open))) {
      overflow: hidden;
    }

    &:has(.single-loan-container:is(.is-open)) {
      & > .personal-area-header-container {
        & > h1 {
          font-size: 40px;
          & > span:not(.total-loans) {
            font-size: 20px;
          }

          & > .total-loans {
            & > .shekel-and-star-container {
              & > .shekel-container {
                font-size: 16px;
                line-height: 1.2;
              }
            }
          }
        }

        & > .animation-container {
          & > object {
            max-width: 140px;
          }
        }
      }
    }
  }
}
