.premium-mort-third-section {
  gap: 40px 140px;

  @media (width < 1000px) {
    grid-template-columns: 1fr;
    gap: 40px 0;
  }

  & > h3 {
    grid-column: span 2;

    @media (width < 1000px) {
      grid-column: span 1;
    }
  }

  & > .animation-cta-container {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    padding-block-start: 80px;

    @media (width < 1000px) {
      grid-column: span 1;
      padding-block-start: 0;
    }

    & > .cta-animation-click-container {
      width: 1200px;

      & > .animation-container {
        right: min(410px, 30vw);

        @media (width < 1000px) {
          right: -20px;
        }
      }

      & > .cta-container {
        width: 100%;
        align-items: center;

        & > * {
          min-width: min-content;
          text-align: center;
        }
      }
    }
  }
}
