.save-button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  text-decoration: underline;
  color: var(--light-blue-text);
  width: 100%;
  padding-block-start: clamp(10px, 1.1vw, 20px);
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}
