@import "../variables.scss";

main {
    &>* {
        background-color: $alternate-background-color;
      
        &:nth-child(even) {
            background-color: $main-background-color;
        }

        &.product-pages-contact-form {
            background-color: $alternate-background-color;
        }

    }
}