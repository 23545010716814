.view-options-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--gap-lg);
  text-align: center;
  pointer-events: all;

  & > .buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    min-width: max-content;
    justify-content: space-between;

    & > * {
      flex-direction: column;
      gap: 0;
      text-align: center;
      min-width: max-content;
      flex: 1;
      & > * {
        font-size: var(--font-size-md);
        color: var(--dark-blue);
      }
    }
  }

  & > h4 {
    font-size: var(--font-size-lg);
  }
}
