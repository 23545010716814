.bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 150px;
    @media screen and (max-width: 940px) {
        gap: 40px;
    }
    &>.title-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &>.main-title-container {
            display: flex;
            gap: 6px;

            &>h2 {
                font-size: clamp(17px, 1.8vw, 36px);
            }


            &>img {
                width: min(156px, 7.7vw);
                @media screen and (max-width: 570px) {
                    width: 70px;
                }
            }
        }

        &>.secondary-title-container {
            &>h3 {
                font-size: clamp(34px, 2.8vw, 59px);
                font-weight: 700;
                text-align: center;
            }
        }
    }

    &>.blobs-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 940px) {
            flex-direction: column;
            gap: 50px;
        }

        &>.blob-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 18px;
            transform: translateY(-50%);

            &:not(:first-child, :last-child) {
                transform: none;
            }

            @media screen and (max-width: 940px) {
                transform: none;
            }

            &>h4 {
                text-align: center;
                white-space: pre-wrap;
                min-width: max-content;
                font-size: clamp(17px, 1vw, 20px);

                &::first-line {
                    font-weight: 700;
                }
            }

            &>.image-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                box-shadow: 0 0 20px #0098FF72;
                height: 184px;
                width: 184px;

                @media screen and (max-width: 570px) {
                    height: 128px;
                    width: 128px;
                }

                &>img {
                    width: 70px;
                @media screen and (max-width: 570px) {
                    width: 55px;
                }
                }
            }
        }
    }
}