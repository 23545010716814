.error-message {
    color: #F24A51;
}

.code-input-container {
    width: 40%;

    &>.code-input-separator {
        display: flex;
        direction: ltr;

        &>.single-code-input {
            background-color: #FFFFFF;
            font-size: 24px;
            flex: 1;
            height: 70px;
            text-align: center;
            animation: borderRadius 0.2s ease-in-out forwards;
            font-weight: 600;
            outline: 2px solid #fff;
            transition: outline 0.1s ease-in-out, background-color 0.2s ease-in-out;
            min-width: 0;
            border: none;

            @media (max-width: 500px) {
                font-size: 16px;
                height: 50px;
            }

            &:hover,
            &:focus {
                cursor: pointer;
                outline: 2px solid #e7e7e7 !important;
            }

            &.error {
                outline: 2px solid #F24A51 !important;
            }


        }
    }
}

@keyframes seperate {
    0% {
        gap: 0;

    }

    100% {
        gap: 25px;

    }
}

@keyframes seperateMobile {
    0% {
        gap: 0;

    }

    100% {
        gap: 10px;

    }

}

@keyframes borderRadius {
    0% {
        border-radius: 0;
    }

    100% {
        border-radius: 10px;
    }
}