@import "../../css/all.scss";

$leftBannerPos   : min(40px, 2.8vw);
$bottomBannerPos : 20px;

.PhoneBanner {
  z-index : 10;
  bottom  : $bottomBannerPos;
  left    : $leftBannerPos;
  cursor  : pointer;
  @include respond-to('handhelds') {
	bottom : 12px;
	}

  & > .content-container {
	display          : flex;
	flex-direction   : column;
	gap              : 20px;
	width            : 190px;
	padding          : 12px;
	box-shadow       : 0 3px 6px #00000029;
	border-radius    : 40px;
	overflow         : hidden;
	max-height       : 64px;
	transition       : max-height 0.2s ease, border-radius 0.1s ease;
	background-color : #FFFFFF;
	@include respond-to('handhelds') {
	  width      : auto;
	  max-height : 65px;
	  gap        : 10px;
	  }

	& > a {
	  display         : flex;
	  text-decoration : none;
	  justify-content : space-between;
	  align-items     : center;
	  transition      : opacity 0.2s ease, text-shadow 0.2s ease;
	  color           : #00509E;
      min-width       : max-content;
	  &:nth-child(2) {
		opacity : 0;
		}

	  @media screen and (max-width : 480px) {
		pointer-events : none;
		& > p {
		  display : none;
		  }

		}
	  }

	&.expanded {
	  border-radius : 20px;
	  max-height    : 200px;

	  & > a {
		&:nth-child(2) {
		  opacity : 1;
		  }

		&:hover {
		  text-shadow : 0 0 1px gray;

		  }
		}

	  @include respond-to('handhelds') {
		border-radius : 40px;
		& > a {
		  pointer-events : all;
		  }
		}
	  }
	}

  &.absolute-position {
	position : absolute;
	}

  &.fixed-position {
	position : fixed;
	}
  }