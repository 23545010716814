@import "css/all";

.accessibility_component {
	.btn_accessibility {
		left: 8px;
		top: 94px;

		@media screen and (max-width: 570px) {
			left: 8px;
			top: 110px;
		}
	}
}

.Website {

	.rangeslider {
		background-color: $weCheckAzure;

		.rangeslider__handle {
			background-color: $weCheckBlue;
			border: 1px solid $weCheckBlue;
			border-radius: 2px;
			width: 45px;
		}
	}


	.rangeslider__fill {
		background-color: $darkBlue !important;
	}

	.rangeslider-horizontal {
		border-radius: 10px;
		height: 4px;
	}

	.rangeslider-horizontal .rangeslider__handle:after {
		background-color: white;
		border-radius: 50%;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
			0 -1px 3px rgba(0, 0, 0, 0.4) inset;
		content: " ";
		height: 16px;
		left: 13px;
		position: absolute;
		top: 6px;
		width: 16px;
	}

	.btn {
		align-items: center;
		border: calculateRem(2) solid $newRed;
		border-radius: calculateRem(5);
		color: $newRed;
		cursor: pointer;
		display: flex;
		font-size: 1rem;
		font-weight: 600;
		height: 50px;
		justify-content: center;
		padding: calculateRem(14) calculateRem(30);
		text-decoration: none;
		transition: 0.2s;
		user-select: none;

		&--oldred {
			@extend .btn;
			background-color: $pinkish-red;
			border: calculateRem(2) solid $pinkish-red;
			color: #FFFFFF;

			&:hover {
				background-color: darken($pinkish-red, 5%);
			}
		}

		&--red {
			@extend .btn;
			background-color: $newRed;
			color: #FFFFFF;

			&:hover {
				background-color: darken($newRed, 5%);
			}
		}

		&--darkBlue {
			@extend .btn;
			background-color: $darkerBlue;
			border: calculateRem(2) solid $darkerBlue;
			color: #FFFFFF;

			&:hover {
				background-color: darken($darkerBlue, 5%);
			}
		}

		&--azure {
			@extend .btn;
			background-color: $weCheckAzure;
			border: calculateRem(2) solid $weCheckAzure;
			color: #FFFFFF;

			&:hover {
				background-color: darken($weCheckAzure, 5%);
			}

		}

		&--blue {
			@extend .btn;
			background-color: $weCheckBlue;
			border: calculateRem(2) solid $weCheckBlue;
			color: #FFFFFF;

			&:hover {
				background-color: darken($weCheckBlue, 5%);
			}

		  &--transparent {
			@extend .btn;
			background-color : transparent;
			border           : calculateRem(2) solid $weCheckBlue;
			color            : $weCheckBlue;

			&:hover {
			  background-color : darken($weCheckBlue, 5%);
			  }
			}
		}

	  &--gray {
		@extend .btn;
		background-color: $gray;
		border: calculateRem(2) solid $gray;
		color: #FFFFFF;

		&:hover {
		  background-color: darken($gray, 5%);
		  }
		}

		&--white {
			@extend .btn;
			background-color: white;
			color: red;

			&:hover {
				background-color: darken($pinkish-red, 5%);
				color: white;
			}
		}

		&--whiteBlue {
			@extend .btn;
			background-color: white !important;
			border: calculateRem(2) solid $weCheckBlue;
			color: $weCheckBlue !important;

			&:hover {
				background-color: darken($weCheckBlue, 5%);
			}
		}
	}
}