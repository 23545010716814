@import "../../../../../../css/all";

.VideoStrip {
  background-size : 260px;
  background      : url("#{$s3ImagesLink}/marketing/tenantPage/manwithkeys.svg") no-repeat 100px 100px;

  @include respond-to("medium-handhelds") {
	background : none;
	}

  .textAndVideo {
	width           : 60%;
	display         : flex;
	flex-flow       : row;
	justify-content : space-between;
	padding-bottom  : 100px;

	@include respond-to("medium-handhelds") {
	  width     : 100%;
	  flex-flow : column;
	  }

	.buttonDiv {
	  margin-top : 40px;

	  a {
		min-width: max-content;
		width     : 253px;
		font-size : calculateRem(22);
		@include respond-to("medium-handhelds") {
		  width     : 358px;
		  margin    : 0 auto;
		  font-size : calculateRem(18);
		  height    : 50px;
		  }
		}

	  }

	.textBox {
	  width : 580px;

	  @include respond-to("medium-handhelds") {
		margin     : 0 auto;
		width      : 95%;
		text-align : center;
		}

	  p {
		font-size : calculateRem(20);
		@include respond-to("medium-handhelds") {
		  font-size : calculateRem(16);
		  }
		}

	  }

	.videoGif {
	  width      : 215px;
	  margin-top : -80px;
	  @include respond-to("medium-handhelds") {
		width      : 60%;
		height     : unset;
		margin     : 0 auto;
		margin-top : 180px;
		}
	  }

	}

  }