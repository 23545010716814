@import "../../../../../../../css/all";

.ButtonsHeader {

  display : flex;

  .HeaderButton {
	display          : flex;
	border-radius    : 10px;
	background-color : white;
	height           : 67px;
	padding          : 24px;
	pointer-events   : none;

	@include respond-to("medium-handhelds") {
	  flex-flow : column;
	  max-width : unset;
	  height    : 103px;
	  flex      : 1 1 100%;
	  padding   : 10px;
	  }

	h4 {
	  font-weight : bold;
	  font-size   : calculateRem(30);
	  margin-left : 5px;
	  @include respond-to("medium-handhelds") {
		font-size : calculateRem(20);
		}
	  }

	h5 {
	  font-size   : calculateRem(16);
	  font-weight : 600;
	  @include respond-to("medium-handhelds") {
		font-size  : calculateRem(14);
		text-align : center;
		}
	  }

	}

  img {
	margin-left  : 20px;
	margin-right : 20px;
	@include respond-to("medium-handhelds") {
	  margin-left  : 5px;
	  margin-right : 5px;
	  }
	}


  }

