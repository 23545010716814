.accordion-container {
  padding-block-end: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s linear, padding-block-end 0.2s linear,
    opacity 0.1s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 14px;
  gap: 20px;
  opacity: 0;

  &.is-open {
    max-height: 330px;
    padding-block-end: 20px;
    opacity: 1;

    &:has(.accordion-container) {
      overflow: visible;
    }
  }
}
