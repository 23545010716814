@import "../../css/all";

.CityField {
  position  : relative;
  width     : 400px;
  display   : flex;
  flex-flow : column;
  margin    : 20px auto 0;

  @include respond-to("medium-handhelds") {
	width : unset;
	}

  &__loader {
	position         : absolute;
	display          : none;
	align-items      : center;
	justify-content  : center;
	top              : 0;
	right            : 0;
	background-color : rgba(255, 255, 255, .3);
	height           : 100%;
	z-index          : 1;
	user-select      : none;

	img {
	  width          : calculateRem(46);
	  height         : calculateRem(46);
	  animation      : loaderAnimation 0.7s linear infinite;
	  pointer-events : none;
	  }

	&--show {
	  @extend .CityField__loader;
	  }
	}
  }

@keyframes loaderAnimation {
  from {
	transform : rotate(0deg);
	}
  to {
	transform : rotate(360deg);
	}
  }