@import "../variables.scss";

.main-title-and-par-container {
    &.change-spacing {
        &>.heading-container {
            padding-block-end: 0;
        }

        &>h2 {
            color: $mortgage-color;
            font-size: $header-font-h2;
            font-weight: $header-weight-h2;
            padding-block-end: 20px;
        }
    }

    
}