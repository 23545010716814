@import "web/css/all";

html {
  font-size : unset !important;
  width: 100%;
  }

body {
  background-color : white;
  font-family      : "Assistant", sans-serif;
  margin           : 0;
  overflow-x       : hidden;
  padding          : 0;
  width: 100%;

  .Website {
	.borderRadiusMax {
	  border-radius : 100px;
	  }

	.medium {
	  font-size : 1.5rem;
	  }

	.large {
	  font-size : 2rem;
	  }

	.expand {
	  min-width : 100%;
	  }
	}
  }

#root {
  margin  : 0;
  padding : 0;
  }

* {
  box-sizing  : border-box;
  font-family : "Assistant", sans-serif;
  }

p,
input,
label {
  direction : rtl;
  }

p {
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space   : nowrap;
  }

.email {
  direction : ltr !important;
  width     : 100%;
  }

.roundImage {
  border-radius : 50%;
  }

.noPadding {
  padding : 0;
  }

.in-transition {
  transition : transform 900ms ease;
  }

a:hover {
  text-decoration : none;
  }

a[disabled] {
  pointer-events : none;
  }

label {
  font-size : calculateRem(14);
  }

body {
  min-width : calculateRem(300);
  }

.left {
  float : left;
  }

.right {
  float : right;
  }

select {
  direction : rtl;
  }

.hidden {
  visibility : hidden;
  }

input,
.btn-primary:focus,
button:focus {
  outline-style : none !important;
  }

input:-webkit-autofill {
  -webkit-box-shadow : 0 0 0 calculateRem(30) white inset;
  }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight : normal;
  line-height : 1.2;
  margin      : 0;
  }

h1 {
  font-size : calculateRem(65);
  @include respond-to("handhelds") {
	font-size : calculateRem(36);
	}
  }

h2 {
  font-size : calculateRem(45);
  @include respond-to("handhelds") {
	font-size : calculateRem(32);
	}
  }

h3 {
  font-size : calculateRem(36);
  @include respond-to("handhelds") {
	font-size : calculateRem(30);
	}
  }

h4 {
  font-size : calculateRem(32);
  @include respond-to("handhelds") {
	font-size : calculateRem(24);
	}
  }

h5 {
  font-size : calculateRem(24);
  @include respond-to("handhelds") {
	font-size : calculateRem(20);
	}
  }

h6 {
  font-size : calculateRem(20);
  @include respond-to("handhelds") {
	font-size : calculateRem(18);
	}
  }

.logo {
  vertical-align : middle;
  width          : calculateRem(260);
  @include respond-to("handhelds") {
	width : calculateRem(180);
	}
  }

.maxWidth100vw {
  height    : 100%;
  max-width : 100vw;
  width     : 100%;
  }

.section {
  display        : flex;
  flex-direction : column;
  }

.bold {
  font-weight : bold;
  }

.bgWhite {
  background : white;
  }

.row {
  display   : flex;
  flex-grow : 1;
  position  : relative;
  width     : 100%;
  }

.flexDirectionRow {
  flex-direction : row;
  @include respond-to("handhelds") {
	flex-direction : column;
	}
  }

.flexDirectionRowReverse {
  flex-direction : row-reverse;
  @include respond-to("handhelds") {
	flex-direction : column;
	}
  }

.flexDirectionColumn {
  flex-direction : column;
  }

.col {
  display        : flex;
  flex           : 1;
  flex-direction : column;
  overflow       : hidden;
  position       : relative;
  }

.overflowVisible {
  overflow : visible;
  }

.center {
  align-items     : center;
  justify-content : center;
  }

.text-center {
  text-align : center;
  }

.colInner {
  align-items    : center;
  display        : flex;
  flex-direction : column;
  }

.pointer {
  cursor : pointer;
  }

.light {
  color : white;
  }

.fullWidth {
  max-width : 100%;
  min-width : 100%;
  }

.flex-start {
  align-items : flex-start;
  }

.zIndex99 {
  z-index : 99;
  }

@media (min-width : 801px) {
  .desktop1 {
	max-width : 8.33%;
	min-width : 8.33%;
	}

  .desktop2 {
	max-width : 16.66%;
	min-width : 16.66%;
	}

  .desktop3 {
	max-width : 25%;
	min-width : 25%;
	}

  .desktop4 {
	max-width : 33.33%;
	min-width : 33.33%;
	}

  .desktop5 {
	max-width : 41.66%;
	min-width : 41.66%;
	}

  .desktop6 {
	max-width : 50%;
	min-width : 50%;
	}

  .desktop7 {
	max-width : 58.33%;
	min-width : 58.33%;
	}

  .desktop8 {
	max-width : 66.66%;
	min-width : 66.66%;
	}

  .desktop9 {
	max-width : 75%;
	min-width : 75%;
	}

  .desktop10 {
	max-width : 83.33%;
	min-width : 83.33%;
	}

  .desktop11 {
	max-width : 91.66%;
	min-width : 91.66%;
	}

  .desktop12 {
	max-width : 100%;
	min-width : 100%;
	}
  }

@media (max-width : 800px) {
  .tablet1 {
	max-width : 8.33%;
	min-width : 8.33%;
	}

  .tablet2 {
	max-width : 16.66%;
	min-width : 16.66%;
	}

  .tablet3 {
	max-width : 25%;
	min-width : 25%;
	}

  .tablet4 {
	max-width : 33.33%;
	min-width : 33.33%;
	}

  .tablet5 {
	max-width : 41.66%;
	min-width : 41.66%;
	}

  .tablet6 {
	max-width : 50%;
	min-width : 50%;
	}

  .tablet7 {
	max-width : 58.33%;
	min-width : 58.33%;
	}

  .tablet8 {
	max-width : 66.66%;
	min-width : 66.66%;
	}

  .tablet9 {
	max-width : 75%;
	min-width : 75%;
	}

  .tablet10 {
	max-width : 83.33%;
	min-width : 83.33%;
	}

  .tablet11 {
	max-width : 91.66%;
	min-width : 91.66%;
	}

  .tablet12 {
	max-width : 100%;
	min-width : 100%;
	}
  }

.padding1 {
  padding : 0 calc(3vw + 2px);
  }

.padding2 {
  padding : 0 calc(6px + 4vw);
  }

.padding3 {
  padding : 0 calc(9vw - 20px);
  }

@media (max-width : 600px) {
  .mobileFlexDirectionColumn {
	flex-direction : column;
	}

  .mobile1 {
	max-width : 8.33%;
	min-width : 8.33%;
	}

  .mobile2 {
	max-width : 16.66%;
	min-width : 16.66%;
	}

  .mobile3 {
	max-width : 25%;
	min-width : 25%;
	}

  .mobile4 {
	max-width : 33.33%;
	min-width : 33.33%;
	}

  .mobile5 {
	max-width : 41.66%;
	min-width : 41.66%;
	}

  .mobile6 {
	max-width : 50%;
	min-width : 50%;
	}

  .mobile7 {
	max-width : 58.33%;
	min-width : 58.33%;
	}

  .mobile8 {
	max-width : 66.66%;
	min-width : 66.66%;
	}

  .mobile9 {
	max-width : 75%;
	min-width : 75%;
	}

  .mobile10 {
	max-width : 83.33%;
	min-width : 83.33%;
	}

  .mobile11 {
	max-width : 91.66%;
	min-width : 91.66%;
	}

  .mobile12 {
	max-width : 100%;
	min-width : 100%;
	}
  }
