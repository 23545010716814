$weCheckBlue                : rgb(6, 79, 153);
$weCheckAzure               : #0098FF;
$weCheckBackColor           : #F5F8FB;
$weCheckButtonColor         : dodgerblue;
$mortBlue                   : #1961A7;
$purchaseButtonColor        : rgb(66, 151, 247);
$disablepurchaseButtonColor : rgb(183, 205, 221);
$errorColor                 : #F14950;
$clientBackColor            : #1E60A3;
$linkColor                  : #5083B7;
$light-blue-gray-two        : #DAE5F0;
$light-grey-blue            : #9BB9D6;
$light-blue-grey            : #B4CAE0;
$surveyBackColor            : #1E60A3;
$lightTextColor             : #B4CAE0;
$light-grey-blue-two        : #82A7CC;
$mortBorder                 : #B2D0E5;
$mortGray                   : #F4F4F4;
$unkown-light-blue          : rgba(0, 152, 255, 0.5);
$dark-blue-grey             : #1B3244;
$peacock-blue               : #064F99;
$pinkish-red                : #F9554E;
$black-color                : #4A4A4A;
$cool-blue                  : #5083B7;
$renewColor                 : rgb(239, 242, 245);
$darkBlue                   : #185097;
$lightGreen                 : rgba(83, 177, 93, 1);
$buttonBlue                 : #214F94;
$newRed                     : #E66156;
$darkerBlue                 : #234669;
$extraDarkBlue              : #06325D;
$Blue2022                   : #3D5A80;
$blueblue                   : #004680;
$niceOrange                 : #F9706A;
$redAlert                   : #ED1C24;
$gray                       : #CCCCCC;
$pattensBlue                : #EBF1F7;

.textColorPrimary {
  color : $Blue2022;
  }

.darkBlue {
  color : $darkBlue;
  }

.pinkish-red {
  color : $pinkish-red !important;
  }
