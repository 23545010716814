.graphs-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  animation: fadeIn 0.2s ease-in-out;

  & > * {
    flex: 1;
  }

  @media (width < 1000px) {
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    justify-content: flex-start;
    transform: translateY(min(17px, 2vh));

    & > * {
      min-width: calc(100vw - 40px);
      scroll-snap-align: start;
      -webkit-overflow-scrolling: touch;
    }

    &::-webkit-scrollbar {
      /* For Chrome and Safari */
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */
    }
  }

  & > .single-section-container {
    padding: 0;
    height: min(400px, 25vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    --padding: min(80px, 4.2vw);

    .error-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: space-between;

        & > p {
            font-size: 14px;
        }
    }

    &:not(:last-child) {
      border-left: 1px solid var(--gray-border);
    }

    &:nth-child(1) {
      padding-inline-end: var(--padding);
    }

    &:nth-child(2) {
      padding-inline: var(--padding);
    }

    &:nth-child(3) {
      padding-inline-start: var(--padding);
    }

    & > h3 {
      font-size: var(--font-size-md);
      font-weight: 700;
      margin-block-start: 0;
      margin-block-end: 0;
      min-width: max-content;

      @media (width < 1000px) {
        font-size: 16px;
      }
    }

    @media (width < 1000px) {
      &:nth-child(1) {
        padding-inline-end: 0;
      }

      &:nth-child(2) {
        padding-inline: 0;
      }

      &:nth-child(3) {
        padding-inline-start: 0;
      }

      &:not(:last-child) {
        border-left: none;
      }

      height: 25dvh;
    }
  }
}
