@import "../variables.scss";

.item-row-container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &>.item-row-list-container {
        display: flex;
        justify-content: space-between;
        &>.single-item-container {
            &:not(:first-child) {
                opacity: 0;
            }
        }

        &.item-list-in-viewport {
            &>.single-item-container {
                &:not(:first-child) {
                    animation: slideFromRight 0.5s, changeOpacity 0.5s;
                    animation-fill-mode: forwards;

                    @container (width < 1000px) {
                        animation: none;
                        animation: slideFromTop 0.5s, changeOpacity 0.5s;
                        animation-fill-mode: forwards;

                    }
                }
            }
        }
        &>* {
            flex: 1;
        }

        @container (width < 1000px) {
            flex-direction: column;
            gap: 80px;
        }
    }

}

       
