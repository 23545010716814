@import "../../../../../css/all";

.TenantTopSection {
  padding-right: 125px;
  padding-left: 125px;
  background: #f2f2f2 url("#{$s3ImagesLink}/marketing/tenantPage/couple.png")
    no-repeat center bottom;
  background-size: 258px 430px;

  @include respond-to("medium-screens") {
    background: #f2f2f2;
  }

  @include respond-to("handhelds") {
    background: #f2f2f2;
  }

  @include respond-to("medium-handhelds") {
    padding-right: 25px;
    padding-left: 25px;
  }

  h1 {
    padding-top: 60px;
    margin-bottom: 30px;
    font-size: calculateRem(50);
    font-weight: bold;
    animation: slideFromRight;
    animation-duration: 1s;
    width: auto;

    @include respond-to("medium-handhelds") {
      margin-bottom: 20px;
      padding-top: 27px;
      font-size: calculateRem(25);
    }
  }

  .container {
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    @include respond-to("medium-handhelds") {
      flex-flow: column-reverse;
	  &.yad2 {
		flex-flow: column;
	  }
    }

    .explainPart {
      display: flex;
      flex-flow: column;
      margin-bottom: 100px;
      width: 100%;
      min-width: 400px;

      @include respond-to("medium-handhelds") {
        margin-bottom: 10px;
        min-width: unset;
      }

      h2 {
        font-size: calculateRem(26);
        font-weight: bold;
        @include respond-to("medium-handhelds") {
          font-size: calculateRem(16);
          text-align: right;
        }
      }

      .dropBox {
        display: flex;
        flex-flow: column;

        .dropBoxContainer {
          @keyframes slideDown {
            from {
              height: 0;
            }
            to {
              height: 300px;
            }
          }

          @keyframes slideUp {
            from {
              height: 300px;
            }
            to {
              height: 0;
            }
          }

         @include respond-to("medium-handhelds") {
			&--open {
				animation: slideDown 0.4s;
			  }
	
			  &--close {
				animation-fill-mode: forwards;
				animation: slideUp 0.4s;
			  }
		 }
        }

        @include respond-to("medium-handhelds") {
          margin-bottom: 20px;
        }

        .caption {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: row;
          justify-content: space-between;

          @include respond-to("medium-handhelds") {
            height: 50px;
          }

          @keyframes rotateUpDown {
            100% {
              transform: rotate(180deg);
            }
          }

          @keyframes rotateUpUp {
            100% {
              transform: rotate(90deg);
            }
          }

          .transformImageDown {
            animation: rotateUpDown 0.2s linear;
            animation-fill-mode: forwards;
          }
        }

        @include respond-to("medium-handhelds") {
          padding-left: 10px;
          padding-right: 10px;
          min-height: 50px;

          background-color: white;
          border-radius: 10px;
        }
      }
    }

    p {
      font-size: calculateRem(18);
      white-space: pre-wrap;
      @include respond-to("medium-handhelds") {
        font-size: calculateRem(16);
      }
      max-width: 600px;
    }

    .checksListContainer {
      margin-top: calculateRem(10);

      .checksList {
        display: flex;

        .listItem {
          display: flex;
          flex-flow: row-reverse;
          height: 40px;
          @include respond-to("medium-handhelds") {
            height: unset;
            margin-bottom: 10px;
          }

          img {
            width: 16px;
            height: 12px;
            margin-left: 10px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
